import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import createSelectors from '../createSelectors';

export interface PositionsStoreActions {
	setPositionTab: (tab: number) => void;
	setWatchListPanelSize: (size: number) => void;
	setGridHeight: (size: number) => void;
	setShowPositionsBottomPanel: (show: boolean) => void;
}

export interface PositionsStoreValues {
	panelSize: number;
	positionTab: number;
	gridHeight: number;
	positionPanelHeight: number;
	showPositionsBottomPanel: boolean;
}

export const initialStatePositionsStore: PositionsStoreValues = {
	panelSize: 0,
	positionTab: 0,
	gridHeight: 0,
	positionPanelHeight: 40,
	showPositionsBottomPanel: true,
};
export type PositionsStore = PositionsStoreValues & PositionsStoreActions;

const positionsStore = create<PositionsStore>()(
	persist(
		immer((set: any) => ({
			...initialStatePositionsStore,
			setPositionTab: (tab: number) => {
				set(() => ({
					positionTab: tab,
				}));
			},
			setWatchListPanelSize: (size: number) => {
				set(() => ({
					panelSize: size,
				}));
			},
			setGridHeight: (size: number) => {
				set(() => ({
					gridHeight: size,
				}));
			},

			setShowPositionsBottomPanel: (show: boolean) => {
				set(() => ({
					showPositionsBottomPanel: show,
				}));
			},
		})),
		{
			name: 'positions-store',
		}
	)
);

export default createSelectors(positionsStore);
