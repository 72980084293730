import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useShortTranslation from '../../../../utils/hooks/useShortTranslation';

import { default as AppContext } from '../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../contexts/DashboardContext';
import { default as RfpGatewayContext } from '../../../../contexts/RfpGatewayContext';
import { default as InstrumentContext } from '../../../../contexts/InstrumentContext';
import { AccountMarketType, MarketItem } from '../../../../gateways/RfpGateway/rfp.types';
import { default as Nullable } from '../../../../utils/functions/Nullable';
import { default as usePromiseFactory } from '../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../../utils/hooks/useForceRerender';
import { DEFAULT_FEED_ID, instrumentExistsInDynamicWatchlists } from '../../../../utils/functions/WatchlistUtils';
import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';
import tradingAccountStore from '../../../../store/tradingAccountStore';
import watchListStore from '../../../../store/WatchListStore/watchListStore';
import authStore from '../../../../store/authStore';
import { useDynamicWatchLists } from '../../../../utils/hooks/useDynamicWatchLists';
import useSaveWatchlistToPreferences from '../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import { useUpdateSBWatchlistInstruments } from '../../../../utils/hooks/useUpdateSBWatchlistInstruments';
import Button from '../../../../shared/Button/Button';

import TradeBoardHeader from './TradeBoardHeader';
import TradeBoardItem from './TradeBoardItem';

import styles from './TradeBoard.module.scss';

const TradeBoard = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const instrumentContext = useContext(InstrumentContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();
	const accountType = dashboardContext.accountType;
	const updateSBWatchlistInstruments = useUpdateSBWatchlistInstruments();
	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();
	const tradingMode = authStore.use.tradingMode();
	const storeWatchlists = watchListStore.use.watchlists();

	const isChildWindow = appContext.isChildWindow || false;
	const getCurrentWatchList = watchListStore.use.getCurrentWatchList();
	const currentWatchList = getCurrentWatchList(isChildWindow);

	const removeInstruments = watchListStore.use.removeInstruments();
	const getWatchlist = watchListStore.use.getWatchlist();
	const getWatchlists = watchListStore.use.getWatchlists();
	const addInstruments = watchListStore.use.addInstruments();
	const allWatchlists = getWatchlists(tradingMode);
	const getSelectedWatchlist = watchListStore.use.getSelectedWatchlist();
	const selectedWatchlist = getSelectedWatchlist(tradingMode, isChildWindow);
	const selectedInstrument = dashboardContext.selectedInstrument;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isJapanAccount = appContext.isJapanAccount;

	const dynamicWatchLists = useDynamicWatchLists();
	const [isDynamicWatchlist, setIsDynamicWatchlist] = useState(false);
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');

	const [isWatchlistSearchOpen, setIsWatchlistSearchOpen] = useState(false);

	const activeTradingAccount = useSelectedTradingAccount();

	const marketItems = dashboardContext.marketItems.filter((marketItem) =>
		isSpreadBettingAccount
			? marketItem.accountMarketType === AccountMarketType.SpreadBetting
			: marketItem.accountMarketType !== AccountMarketType.SpreadBetting
	);

	useObservable(appContext.getPropertyChangeStream('appTheme', 'email'), (change) => {
		promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	// TODO: - Double check for what we need to listen here
	useObservable(
		dashboardContext.getPropertyChangeStream(
			'accountType',
			'loadingState',
			'tradingAccount',
			'notification',
			'watchlist',
			'selectedSymbol',
			'tradingPositions',
			'selectedInstrument',
			'defaultWatchLists'
		),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	useObservable(dashboardContext.getPropertyChangeStream('marketItems'), () => {
		forceRerender();
	});

	useEffect(() => {
		if (dashboardContext.defaultWatchLists.length > 0) {
			if (
				instrumentExistsInDynamicWatchlists(
					currentWatchList,
					dashboardContext.defaultWatchLists,
					isSpreadBettingAccount
				)
			) {
				setIsDynamicWatchlist(true);
			} else {
				setIsDynamicWatchlist(false);
			}
		}
	}, [dashboardContext.defaultWatchLists, currentWatchList, isSpreadBettingAccount]);

	useEffect(() => {
		if (currentWatchList && allWatchlists && allWatchlists.length > 0) {
			const watchList = getWatchlist(tradingMode, currentWatchList);
			if (watchList) {
				if (isSpreadBettingAccount) {
					updateSBWatchlistInstruments(marketItems);
				}
				subscribeToNewInstruments(watchList.instruments);
			}
		}
	}, [
		allWatchlists,
		tradingMode,
		currentWatchList,
		accountType,
		dashboardContext.marketItems,
		rfpGatewayContext?.mapMarketItems.size,
	]);

	useEffect(() => {
		if (dashboardContext.defaultWatchLists.length > 0) {
			if (isFundedTrader) {
				dynamicWatchLists(
					dashboardContext.watchlist.slice(dashboardContext.defaultWatchLists.length),
					isFundedTrader,
					isSpreadBettingAccount
				);
			} else {
				dynamicWatchLists(dashboardContext.watchlist, isFundedTrader, isSpreadBettingAccount);
			}
		}
	}, [dashboardContext.defaultWatchLists, isFundedTrader]);

	useEffect(() => {
		if (
			rfpGatewayContext?.mapMarketItems.size !== 0 &&
			(!selectedInstrument ||
				(isSpreadBettingAccount && selectedInstrument.accountMarketType !== AccountMarketType.SpreadBetting) ||
				(!isSpreadBettingAccount && selectedInstrument.accountMarketType === AccountMarketType.SpreadBetting))
		) {
			const initialChartSymbol = isSpreadBettingAccount
				? { code: 'GBPUSD_SB' }
				: { code: isJapanAccount ? 'USDJPY' : 'GBPUSD' };
			const marketItem = rfpGatewayContext?.getMarketItem(initialChartSymbol.code, DEFAULT_FEED_ID);
			if (marketItem) {
				dashboardContext.selectedInstrument = marketItem;
			}
		}
	}, [isSpreadBettingAccount, rfpGatewayContext?.mapMarketItems.size]);

	const instrumentHandlers = useMemo(() => {
		return {
			// TODO: - Check onRemovalFromWatchlist - can we use it for something? Otherwise - remove/refactor.
			onRemovalFromWatchlist: (instruments: string[]) => {
				removeInstruments(tradingMode, currentWatchList, instruments);
			},
			onInstrumentChange: (marketItem: MarketItem) => {
				// TODO: - Refactor when we rewrite the order ticket
				dashboardContext.symbolChanged = marketItem.code;
				dashboardContext.gridChartsChanged = true;
				dashboardContext.selectedPosition = null;
				dashboardContext.selectedInstrument = marketItem;
				dashboardContext.selectedType = 'Instrument';
				dashboardContext.closeAllOtherTabs();
				dashboardContext.isEdit = false;

				//update recently viewed instruments queue
				Nullable.of(instrumentContext.instruments.find((value) => value.code === marketItem.code)).run((instrument) => {
					instrumentContext.recentlyViewedInstruments.delete(instrument);
					instrumentContext.recentlyViewedInstruments.enqueue(instrument);
				});
			},
		};
	}, [dashboardContext.accountType, rfpGatewayContext, activeTradingAccount]);

	// create ref for subscription id and items
	const subIdRef = useRef<string | undefined>(undefined);
	const subIdItemsRef = useRef<string[] | undefined>(undefined);

	const subscribeToNewInstruments = (instruments: string[]) => {
		if (rfpGatewayContext && marketItems.length > 0) {
			if (instruments) {
				// compare symbols to avoid unnecessary subscription
				if (subIdItemsRef.current && subIdItemsRef.current.length === instruments.length) {
					const isSame = subIdItemsRef.current.every((value, index) => value === instruments[index]);
					if (isSame) {
						return;
					}
				}

				// Unsubscribe from previous instruments
				unsubscribeInstruments();

				// subscribe for price quote
				const subId = rfpGatewayContext.subscribePriceQuote(DEFAULT_FEED_ID, instruments, (priceQuote) => {});
				//console.log(`✅︎ Subscribe instruments subId=${subId} symbols=${symbols}`);
				subIdRef.current = subId;
				subIdItemsRef.current = instruments;
			}
		}
	};

	const unsubscribeInstruments = () => {
		if (rfpGatewayContext && subIdRef.current) {
			// console.log(`XXX ❌ Unsubscribe instruments subId=${subIdRef.current}`);
			rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
			subIdRef.current = undefined;
			subIdItemsRef.current = undefined;
		}
	};

	const handleWatchlistSearchToggle = () => {
		setIsWatchlistSearchOpen(!isWatchlistSearchOpen);
	};

	const updateWatchlist = (action: 'add' | 'remove', symbol: string) => {
		const preferenceToUpdate = dashboardContext.watchlist.find((preference) => preference._name === currentWatchList);
		if (preferenceToUpdate) {
			if (action === 'add') {
				if (!preferenceToUpdate.instrument.find((instrument) => instrument._code === symbol)) {
					preferenceToUpdate.instrument.push({ _code: symbol });
				}
				addInstruments(tradingMode, currentWatchList, [symbol]);
			} else {
				preferenceToUpdate.instrument = preferenceToUpdate.instrument.filter(
					(instrument) => instrument._code !== symbol
				);
				removeInstruments(tradingMode, currentWatchList, [symbol]);
			}

			saveWatchlistToPreferences(dashboardContext.watchlist);
		}
	};

	const handleReloadWatchlist = () => {
		forceRerender();
	};

	return (
		<div className={styles.container}>
			<TradeBoardHeader
				isWatchlistSearchOpen={isWatchlistSearchOpen}
				handleWatchlistSearchToggle={handleWatchlistSearchToggle}
				updateWatchlist={updateWatchlist}
				handleReloadWatchlist={handleReloadWatchlist}
				isDynamicWatchlist={isDynamicWatchlist}
			/>
			{currentWatchList && dashboardContext.watchlist && selectedWatchlist && (
				<div className={cn(styles.watchlistContainer, isChildWindow && styles.watchlistContainerDetach)}>
					{selectedWatchlist.instruments.length > 0 && (
						<div
							className={cn(
								styles.marketTickerContainer,
								isChildWindow && styles.windowMarketTickerContainer,
								!((selectedWatchlist.instruments.length > 5) /*&& oneClickTrading*/) && styles.noScrollBar
							)}
						>
							<div className={styles.instrumentsContainer}>
								{selectedWatchlist.instruments.map((code) => {
									const mktItem = rfpGatewayContext?.getMarketItem(code);
									if (mktItem) {
										return (
											<TradeBoardItem
												key={`${mktItem.code}-keep-this-here-and-do-not-use-indices-as-keys-pls-${activeTradingAccount?.id}`}
												marketItem={mktItem}
												onInstrumentChange={() => instrumentHandlers.onInstrumentChange(mktItem)}
												isDynamicWatchlist={isDynamicWatchlist}
												isDetached={isChildWindow}
											/>
										);
									}
									return null;
								})}
							</div>

							{!isDynamicWatchlist && (
								<div className={styles.addInstrumentContainer}>
									<div
										className={allWatchlists && allWatchlists.length === 0 ? styles.centeredText : styles.addInstrument}
										onClick={() => handleWatchlistSearchToggle()}
									>
										<FontAwesomeIcon icon={['far', 'plus-circle']} className={styles.addInstrumentIcon} />
										<span className={styles.addInstrumentTitle}>{tt('ADD_INSTRUMENT')}</span>
									</div>
								</div>
							)}
						</div>
					)}

					{selectedWatchlist.instruments.length === 0 && (
						<div className={styles.emptyContainer}>
							<div className={styles.emptyStar}>
								<FontAwesomeIcon icon={['fal', 'list-ul']} className={styles.iconC} aria-hidden="true" />
							</div>
							<div className={styles.emptyTitle}>{t('wtr:WTR_EMPTY_WATCHLIST')}</div>
							<Button
								label={tt('ADD_INSTRUMENT')}
								onClick={() => handleWatchlistSearchToggle()}
								variant="secondary"
								icon={['fal', 'plus-circle']}
								iconOnhover={['far', 'plus-circle']}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default TradeBoard;
