import React, { useContext, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/types';

import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import SearchResult from '../../Watchlist/SearchResult/SearchResult';
import EmptySearch from '../../Watchlist/EmptySearch/EmptySearch';
import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';
import { forceCloseModal } from '../../../../../utils/hooks/useForceCloseModal';
import Button from '../../../../../shared/Button/Button';
import Modal from '../../../../../shared/Modal/Modal';
import { AccountMarketType, MarketItem } from '../../../../../gateways/RfpGateway/rfp.types';
import usePromiseFactory from '../../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';
import { sortSearchResults } from '../../../../../utils/functions/sortSearchResults';
import { getSearchResults } from '../../../../../utils/functions/getSearchResults';
import usePreferredFeedId from '../../../../../utils/hooks/usePreferredFeedId';
import {
	DEFAULT_FEED_ID,
	MAX_WATCHLISTS_ALLOWED,
	createNewWatchlist,
} from '../../../../../utils/functions/WatchlistUtils';

import useGetTranslatedWLName from '../../../../../utils/hooks/useGetTranslatedWLName';
import WtrPopup from '../../../../components/WtrPopup/WtrPopup';
import useSaveWatchlistToPreferences from '../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';
import DetachIcon from '../../../../components/DetachIcon/DetachIcon';
import { DETACHED_SUB_ROUTES } from '../../../../../setup/routes';
import AppContext from '../../../../../contexts/AppContext';
import { DETACHED_WINDOW_TRADE } from '../../../../../contexts/WindowContext';
import authStore from '../../../../../store/authStore';
import StringUtils from '../../../../../utils/functions/StringUtils';
import WatchlistsDropDown from '../../Watchlist/WatchlistsDropDown/WatchlistsDropDown';

import styles from './TradeBoardHeader.module.scss';

interface TradeBoardHeaderProps {
	isWatchlistSearchOpen: boolean;
	handleWatchlistSearchToggle: () => void;
	updateWatchlist: (action: 'add' | 'remove', symbol: string) => void;
	handleReloadWatchlist: () => void;
	isDynamicWatchlist: boolean;
}

const TradeBoardHeader = ({
	isWatchlistSearchOpen,
	handleWatchlistSearchToggle,
	updateWatchlist,
	handleReloadWatchlist,
	isDynamicWatchlist,
}: TradeBoardHeaderProps) => {
	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);
	const forceRerender = useForceRerender();
	const promiseFactory = usePromiseFactory();
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');
	const getTranslatedWLName = useGetTranslatedWLName();

	const tradingMode = authStore.use.tradingMode();

	const isChildWindow = appContext.isChildWindow || false;
	const getCurrentWatchList = watchListStore.use.getCurrentWatchList();
	const currentWatchList = getCurrentWatchList(isChildWindow);

	const storeWatchlists = watchListStore.use.watchlists();
	const addInstruments = watchListStore.use.addInstruments();
	const removeInstruments = watchListStore.use.removeInstruments();
	const setCurrentWatchList = watchListStore.use.setCurrentWatchList();
	const renameWatchlist = watchListStore.use.renameWatchlist();
	const isWatchlistNameExists = watchListStore.use.isWatchlistNameExists();
	const watchlistsCount = watchListStore.use.watchlistsCount();
	const getWatchlists = watchListStore.use.getWatchlists();
	const removeWatchlist = watchListStore.use.removeWatchlist();
	const getSelectedWatchlist = watchListStore.use.getSelectedWatchlist();
	const selectedWatchlist = getSelectedWatchlist(tradingMode, isChildWindow);
	const allWatchlists = getWatchlists(tradingMode);

	const [openModify, setOpenModify] = useState(false);
	const [openWatchlist, setOpenWatchlist] = useState(false);
	const [createWatchList, setCreateWatchList] = useState(false);
	const [watchlistName, setWatchlistName] = useState(currentWatchList ? currentWatchList : '');
	const [maxWatchlistsError, setMaxWatchlistsError] = useState(false);
	const [typedValue, setTypedValue] = useState('');
	const [nameError, setNameError] = useState('');
	const [searchResults, setSearchResults] = useState<MarketItem[]>([]);
	const [searchValue, setSearchValue] = useState('');
	const [showEmptySearchState, setShowEmptySearchState] = useState(false);
	const [invalidRemoveName, setInvalidRemoveName] = useState('');
	const searchInputRef = useRef<HTMLInputElement | null>(null);
	const watchlistNameRef = useRef<string>(currentWatchList);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const marketItems = dashboardContext.marketItems.filter((marketItem) =>
		isSpreadBettingAccount
			? marketItem.accountMarketType === AccountMarketType.SpreadBetting
			: marketItem.accountMarketType !== AccountMarketType.SpreadBetting
	);
	const selectedTradingAccount = useSelectedTradingAccount();

	const preferredFeedId = usePreferredFeedId(selectedTradingAccount);
	const FILTER_THROTTLE = 150;

	useObservable(dashboardContext.getPropertyChangeStream('marketItems', 'tradingAccount', 'watchlist'), () => {
		promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	const closeWatchlistPopup = (e: any) => {
		if (
			e.target.classList[0] !== undefined ||
			e.target.classList.length === 0 ||
			(Array.isArray(e.target.classList) && !e.target.classList[0].includes('Watchlist'))
		) {
			setOpenWatchlist(false);
		}
	};

	const handleModify = (item: string) => {
		setCurrentItemWatchList(item);
		setOpenModify(true);
	};

	const handleCloseModifyModal = () => {
		setOpenModify(false);
		setNameError('');
		setInvalidRemoveName('');
		setTypedValue('');
		forceCloseModal('fade modal');
	};

	const handleCreateWatchlist = () => {
		if (!maxWatchlistsError) {
			setTypedValue('');
			setCreateWatchList(true);
		}
	};

	const changeValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setTypedValue(value);
		nameError.length > 0 && setNameError('');
	};

	const changeWatchlistName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setWatchlistName(value);
		if (nameError || invalidRemoveName) {
			setNameError('');
			setInvalidRemoveName('');
		}
	};

	const handleCreateWatchListClose = () => {
		setNameError('');
		setTypedValue('');
		setCreateWatchList(false);
	};

	const verifyWatchlist = () => {
		if (storeWatchlists !== null) {
			const nameExists = isWatchlistNameExists(tradingMode, typedValue);
			const wlCount = watchlistsCount(tradingMode);
			const maxWatchlists = wlCount >= MAX_WATCHLISTS_ALLOWED;
			if (!nameExists && !maxWatchlists) {
				setNameError('');
				handleSaveWatchlist();
			} else if (nameExists) {
				setNameError(t('wtr:NAME_IN_USE'));
			}
		}
	};

	const handleSaveWatchlist = () => {
		const newWatchlistName = typedValue.trim();
		const feedId = StringUtils.isNullOrEmpty(preferredFeedId) ? DEFAULT_FEED_ID : preferredFeedId;
		const newWatchlistEntry = createNewWatchlist(newWatchlistName, feedId);
		dashboardContext.watchlist.push(newWatchlistEntry);
		setCurrentWatchList(newWatchlistName, isChildWindow);
		saveWatchlistToPreferences(dashboardContext.watchlist);
		setCreateWatchList(false);
		setOpenWatchlist(false);
	};

	const resetWatchlistSearch = () => {
		handleWatchlistSearchToggle();
		setSearchResults([]);
		setSearchValue('');
		setShowEmptySearchState(false);
	};

	const clearSearchInput = () => {
		setSearchValue('');
		setSearchResults([]);
		setShowEmptySearchState(false);
		searchInputRef.current !== null && searchInputRef.current.focus();
	};

	const handleSearchValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		if (value.length <= 1) {
			setSearchResults([]);
			setShowEmptySearchState(false);
		} else {
			promiseFactory.throttle('searchTermChange', FILTER_THROTTLE).then(() => {
				const results = getSearchResults(marketItems, value);
				if (results.length === 0) {
					setSearchResults([]);
					setShowEmptySearchState(true);
				} else {
					const sorted = sortSearchResults(results, value);
					setSearchResults(sorted);
					setShowEmptySearchState(false);
				}
			});
		}
		setSearchValue(value.trim());
	};

	const removeCurrentWatchlist = () => {
		if (allWatchlists) {
			if (!isWatchlistNameExists(tradingMode, watchlistName.trim())) {
				setInvalidRemoveName('Watchlist does not exist');
			} else {
				removeWatchlist(tradingMode, watchlistName);
				const removed = dashboardContext.watchlist.filter(
					(watchlist) => watchlist._name.toUpperCase() !== watchlistName.toUpperCase()
				);
				dashboardContext.watchlist = removed;

				if (dashboardContext.watchlist.length > 0) {
					setCurrentWatchList(dashboardContext.watchlist[0]._name, isChildWindow);
				}
				saveWatchlistToPreferences(dashboardContext.watchlist);
				setOpenModify(false);
				forceCloseModal('fade modal');
			}
		}
	};

	const renameCurrentWatchlist = () => {
		if (allWatchlists) {
			const name = watchlistName.trim();
			const watchlistNameExists = isWatchlistNameExists(tradingMode, name);
			if (!watchlistNameExists) {
				const originalName = dashboardContext.watchlist.find((watchlist) => watchlist._name === currentWatchList);
				if (originalName) {
					originalName._name = name;
					originalName._id = name;
				}
				renameWatchlist(tradingMode, currentWatchList, name);
				setOpenModify(false);
				setCurrentWatchList(name, isChildWindow);
				saveWatchlistToPreferences(dashboardContext.watchlist);
				forceCloseModal('fade modal');
			} else {
				setNameError(t('wtr:NAME_IN_USE'));
				setOpenModify(true);
			}
		}
	};

	//Reset existing errors when input length is 0
	useEffect(() => {
		typedValue.length === 0 && setNameError('');
		watchlistName.length === 0 && setNameError('');
	}, [typedValue, watchlistName]);

	//Clear existing errors when modify screen is reopened
	useEffect(() => {
		nameError.length > 0 && setNameError('');
	}, [openModify]);

	useEffect(() => {
		if (storeWatchlists !== null) {
			const wlCount = watchlistsCount(tradingMode);
			setMaxWatchlistsError(wlCount >= MAX_WATCHLISTS_ALLOWED);
		}
	}, [handleCreateWatchlist]);

	useEffect(() => {
		setOpenWatchlist(false);
	}, [dashboardContext.selectedInstrument]);

	useEffect(() => {
		watchlistNameRef.current = currentWatchList;
	}, [currentWatchList]);

	const setCurrentItemWatchList = (item: any) => {
		if (storeWatchlists) {
			setCurrentWatchList(item, isChildWindow);
			setWatchlistName(item);
			setOpenWatchlist(false);
			handleReloadWatchlist();
		}
	};

	return (
		<>
			<div className={cn(styles.headerContainer)}>
				<div className={styles.editWatchlistIcon}></div>
				{openModify && (
					<div className={styles.title}>
						{getTranslatedWLName(currentWatchList)} &nbsp;
						<FontAwesomeIcon icon={['fas', 'caret-down']} size="1x" />
					</div>
				)}

				{!openModify && (
					<WatchlistsDropDown
						isOpen={openWatchlist}
						createWatchList={createWatchList}
						maxWatchlistsError={maxWatchlistsError}
						nameError={nameError}
						typedValue={typedValue}
						changeValue={changeValue}
						setIsOpen={setOpenWatchlist}
						handleModify={handleModify}
						verifyWatchlist={verifyWatchlist}
						setCurrentItemWatchList={setCurrentItemWatchList}
						handleCreateWatchlist={handleCreateWatchlist}
						handleCreateWatchListClose={handleCreateWatchListClose}
					/>
				)}
				<div className={styles.headerButtonsWrapper}>
					{!isDynamicWatchlist && (
						<WtrPopup
							className={styles.watchlistSearchContainer}
							open={isWatchlistSearchOpen}
							onClose={resetWatchlistSearch}
							on="click"
							pinned
							position={`${isChildWindow ? 'bottom right' : 'bottom center'}`}
							trigger={
								<OverlayTrigger
									delay={{ show: 750, hide: 0 }}
									key="addIcon"
									placement={`${isChildWindow ? 'bottom' : 'top'}` as Placement}
									overlay={
										<Tooltip className="my-tooltip" id="icon">
											{tt('ADD_INSTRUMENT')}
										</Tooltip>
									}
								>
									<span>
										<FontAwesomeIcon
											icon={['far', 'plus-circle']}
											className={styles.addToWatchlistIcon}
											size="2x"
											onClick={() => handleWatchlistSearchToggle()}
										></FontAwesomeIcon>
									</span>
								</OverlayTrigger>
							}
							content={
								<>
									<div className={styles.searchContainer}>
										<div className={styles.searchTitle}>
											{t('wtr:ADD_INSTRUMENT_TO')} {getTranslatedWLName(currentWatchList)}{' '}
										</div>
										<div className={styles.searchInputContainer}>
											<FontAwesomeIcon icon={['fas', 'search']} className={styles.inputIcon}></FontAwesomeIcon>
											<FontAwesomeIcon
												icon={['fas', 'times']}
												onClick={clearSearchInput}
												className={searchValue.length > 0 ? styles.deleteIcon : styles.hidden}
											></FontAwesomeIcon>
											<input
												className={cn(
													searchResults.length > 0 || showEmptySearchState ? styles.openSearchInput : styles.searchInput
												)}
												type="text"
												placeholder={tt('ADD_INSTRUMENT')}
												autoFocus
												ref={searchInputRef}
												value={searchValue}
												onChange={handleSearchValue}
												name="search"
												spellCheck="false"
												autoComplete="off"
											/>
										</div>
									</div>
									<div
										className={cn(
											styles.searchResults,
											showEmptySearchState && styles.hiddenScroll,
											searchResults.length > 0 && styles.boxSearch
										)}
									>
										{searchResults.length > 0 &&
											allWatchlists &&
											selectedWatchlist &&
											searchResults.map((result) => {
												const existsInWatchlist = selectedWatchlist.instruments.some(
													(instrument) => instrument === result.code
												);
												let watchlistNames: string[] = [];
												if (existsInWatchlist) {
													watchlistNames = allWatchlists
														.filter((watchlist) => watchlist.instruments.includes(result.code))
														.map((watchlist) => watchlist.name);
												}
												return (
													<SearchResult
														key={result.code}
														searchTerm={searchValue}
														result={result}
														existsInWatchlist={existsInWatchlist}
														onUpdateWatchlist={() => updateWatchlist(existsInWatchlist ? 'remove' : 'add', result.code)}
														watchlists={watchlistNames}
													/>
												);
											})}
										{showEmptySearchState && <EmptySearch invalidSearchTerm={searchValue} />}
									</div>
								</>
							}
						/>
					)}

					<DetachIcon url={DETACHED_SUB_ROUTES.Trade} windowType={DETACHED_WINDOW_TRADE} />
					{/*<DetachIcon url={DETACHED_SUB_ROUTES.OneClickTrade} windowType={DETACHED_WINDOW_ONE_CLICK_TRADE} />*/}
				</div>
			</div>

			<Modal show={openModify} centered dialogClassName={styles.modalWatchlist}>
				<Modal.Header className={styles.modalTopHeader}>
					<Modal.Title className={styles.modalTitle}>{t('wtr:MODIFY_WATCHLIST')}</Modal.Title>
				</Modal.Header>
				<Modal.Body className={styles.modifyWatchlistModalBody}>
					<div className={styles.modifyWatchlist}>
						<div className={styles.title}>{t('wtr:WATCHLIST_NAME')}</div>
						<div className={styles.modifyInputContainer}>
							<input
								type="text"
								className={
									nameError.length !== 0 || invalidRemoveName.length !== 0
										? styles.modifyErrorInput
										: styles.modifyInput
								}
								maxLength={20}
								value={watchlistName}
								onChange={changeWatchlistName}
							/>
						</div>
						<div className={styles.inputLimit}>{watchlistName.length}/20</div>
						{(nameError.length !== 0 || invalidRemoveName.length !== 0) && (
							<div className={styles.modifyErrorMessage}>{nameError.length > 0 ? nameError : invalidRemoveName}</div>
						)}
						<div className={cn(styles.buttonDiv, allWatchlists && allWatchlists.length === 1 ? styles.disableBtn : '')}>
							<Button
								label={t('wtr:REMOVE_WATCHLIST')}
								size="lg"
								onClick={removeCurrentWatchlist}
								disabled={watchlistName.trim().length === 0}
								className={cn(
									styles.modifyButton,
									(allWatchlists && allWatchlists.length === 1) || watchlistName.trim().length === 0
										? styles.disableBtn
										: ''
								)}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className={styles.modifyFooter}>
					<div className={styles.actionButtons}>
						<Button
							label={tt('CANCEL')}
							size="lg"
							variant="secondary"
							className={styles.cancelButton}
							onClick={handleCloseModifyModal}
						/>
						<Button
							label={tt('SAVE')}
							size="lg"
							variant="primary"
							disabled={watchlistName.trim().length === 0}
							className={styles.saveButton}
							onClick={renameCurrentWatchlist}
						/>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default TradeBoardHeader;
