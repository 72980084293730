import React, { useContext } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import styles from './ClosePositionTicket.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppContext from '../../../../contexts/AppContext';
import tradingViewStore from '../../../../store/tradingViewStore';
import DashboardContext from '../../../../contexts/DashboardContext';
import { TicketLayout } from '../../../../utils/functions/enums';

interface ClosePositionTicketHeaderProps {
	title: string | JSX.Element;
}

const ClosePositionTicketHeader = ({ title }: ClosePositionTicketHeaderProps) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const ticketLayout = tradingViewStore.use.ticketLayout();
	const setTicketLayout = tradingViewStore.use.setTicketLayout();
	const dashboardContext = useContext(DashboardContext);

	const isArabic = appContext.isArabic;

	const undockTicketLayout = () => {
		setTicketLayout(TicketLayout.Undock);
		dashboardContext.showCloseTicket = false;
		dashboardContext.modalToggle = { closePosition: true, closeOrder: false };
	};

	const dockTicketLayout = () => {
		setTicketLayout(TicketLayout.Dock);
		dashboardContext.showCloseTicket = true;
		dashboardContext.modalToggle = { closePosition: false, closeOrder: false };
	};

	const undock = () => {
		if (ticketLayout === TicketLayout.Undock) {
			dockTicketLayout();
		} else {
			undockTicketLayout();
		}
	};

	const hideClosePosition = () => {
		if (ticketLayout === TicketLayout.Dock) {
			dashboardContext.showCloseTicket = false;
		} else {
			dashboardContext.modalToggle = { closePosition: false, closeOrder: false };
		}
	};

	return (
		<>
			<div className={styles.titleWrapper}>{title}</div>
			<div className={styles.iconContainer}>
				<OverlayTrigger
					key="detachOrderTicket"
					delay={{ show: 750, hide: 0 }}
					placement={isArabic ? 'right' : 'left'}
					overlay={
						<Tooltip className="my-tooltip">
							{ticketLayout === TicketLayout.Undock ? t('en:ATTACH') : t('en:DETACH')}
						</Tooltip>
					}
				>
					<div
						className={cn({
							[styles.icon]: true,
							[styles.iconDetached]: ticketLayout === TicketLayout.Undock,
							[styles.iconAtached]: ticketLayout === TicketLayout.Dock,
						})}
					>
						<FontAwesomeIcon
							icon={
								ticketLayout === TicketLayout.Dock
									? ['fal', 'square-arrow-up-right']
									: ['fal', 'square-arrow-down-left']
							}
							onClick={undock}
						/>
					</div>
				</OverlayTrigger>

				{ticketLayout === TicketLayout.Undock && (
					<div className={cn(styles.icon, styles.iconDetached)}>
						<FontAwesomeIcon icon={['fal', 'xmark-large']} onClick={hideClosePosition} />
					</div>
				)}
			</div>
		</>
	);
};

export default ClosePositionTicketHeader;
