import React, { useState, useEffect, useRef, useContext } from 'react';

import { MarketItem, PriceQuote } from '../../../../../gateways/RfpGateway/rfp.types';

import { Optional } from '../../../../../utils/functions/Nullable';

import RfpGatewayContext from '../../../../../contexts/RfpGatewayContext';

import useIsInViewport from '../../../../../utils/hooks/useIsInViewport';

import OneClickTradeTicket from './OneClickTradeTicket';

interface TradeBoardItemProps {
	marketItem: MarketItem;
	onInstrumentChange: () => void;
	isDynamicWatchlist: boolean;
	isDetached: boolean;
}

const TradeBoardItem = ({ marketItem, onInstrumentChange, isDynamicWatchlist, isDetached }: TradeBoardItemProps) => {
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const subIdRef = useRef<string | undefined>(undefined);

	const item = useRef(null);
	const isInViewport = useIsInViewport(item);

	const [currentPriceQuote, setCurrentPriceQuote] = useState<Optional<PriceQuote>>();

	useEffect(() => {
		if (rfpGatewayContext && marketItem && isInViewport) {
			// unsubscribe previous quotes
			if (subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
			}

			// subscribe for price quote
			const subId = rfpGatewayContext.subscribePriceQuote(marketItem.feedId, [marketItem.code], (priceQuote) => {
				setCurrentPriceQuote(priceQuote);
			});

			subIdRef.current = subId;

			// unsubscribe price quote on unmount
			return () => {
				if (rfpGatewayContext && subIdRef.current) {
					rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
					subIdRef.current = undefined;
				}
			};
		}
	}, [marketItem, rfpGatewayContext, isInViewport]);

	const handleInstrumentChange = () => {
		onInstrumentChange();
	};

	return (
		// 		{/* TODO: - Conditional render OneClickTradeTicket or DefaultButtons
		//   when we refactor the Watchlist. For now return only OneClickTradeTicket */}
		<OneClickTradeTicket
			ref={item}
			marketItem={marketItem}
			instrument={currentPriceQuote}
			handleInstrumentChange={handleInstrumentChange}
			isDynamicWatchlist={isDynamicWatchlist}
			isDetached={isDetached}
		/>
	);
};

export default TradeBoardItem;
