import { TWatchlistInstrument, TWatchlists } from '../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import { MarketItem } from '../../gateways/RfpGateway/rfp.types';
import { DefaultWatchList, WatchListItem } from '../../contexts/DashboardContext';
import { WatchListData } from '../../store/WatchListStore/watchListStore';

const commonFxSymbols: string[] = [];

export const MAX_WATCHLISTS_ALLOWED = 20;
export let MAX_INSTRUMENTS_ALLOWED = 50;
export let MAX_INSTRUMENTS_ALLOWED_JP = 100;

export const DEFAULT_FEED_ID = 'VTFeed';

export const WATCHLIST_SORT_ORDER = {
	NONE: 'None',
	ASCENDING: 'Ascending',
	DESCENDING: 'Descending',
} as const;

export const WATCHLIST_SORT_VALUE = {
	INSTRUMENT: 'Instrument',
	CODE: 'Code',
	CHANGE: 'Change',
	LAST_PRICE: 'LastPrice',
} as const;

export type WatchlistSortOrder = (typeof WATCHLIST_SORT_ORDER)[keyof typeof WATCHLIST_SORT_ORDER];
export type WatchlistSortValue = (typeof WATCHLIST_SORT_VALUE)[keyof typeof WATCHLIST_SORT_VALUE];

export const convertTWatchlistsToWatchListData = (watchlists: TWatchlists[]) => {
	return watchlists.map((watchlist) => {
		const marketItems = watchlist.instrument.map((watchlist) => {
			return watchlist._code;
		});
		return {
			id: watchlist._id,
			name: watchlist._name,
			feedId: watchlist._feedId,
			sortOrder: watchlist._sortOrder,
			sortValue: watchlist._sortValue,
			instruments: marketItems,
		} as WatchListData;
	});
};

export const getDefaultJapanWatchlists = (preferredFeedId: string, marketItems: MarketItem[]): TWatchlists[] => {
	const numTiers = 3;
	const japanWatchlists: Record<string, any> = {};
	for (let i = 1; i <= numTiers; i++) {
		japanWatchlists[`tier${i}`] = [];
	}

	for (const element of marketItems) {
		if (typeof element.minTier !== 'undefined' && element.minTier !== null) {
			// loop from each instrument's minTier to max number of tiers defined
			// to make sure higher tiers include instruments from lower tiers
			for (let tierNum = element.minTier; tierNum <= numTiers; tierNum++) {
				japanWatchlists[`tier${tierNum}`].push({ _code: element.code, _rank: element.watchlistRank });
			}
		}
	}

	Object.keys(japanWatchlists).forEach((tierKey) => {
		// finally, sort instruments inside each watchlist by rank
		japanWatchlists[tierKey].sort((a: TWatchlistInstrument, b: TWatchlistInstrument) => (a._rank < b._rank ? -1 : 1));
	});

	let result: TWatchlists[] = [];

	Object.keys(japanWatchlists).forEach((tierKey) => {
		result.push({
			instrument: japanWatchlists[tierKey],
			_feedId: preferredFeedId,
			_id: `Tier ${tierKey.slice(-1)}`,
			_name: `Tier ${tierKey.slice(-1)}`,
			_sortOrder: 'None',
			_sortValue: 'Instrument',
		});
	});

	return result;
};

export const getDefaultCFDWatchlist = (
	marketItems: MarketItem[],
	isSpreadBettingWatchlist = false,
	isJapanSpreadWatchlist = false
) => {
	let fXSymbols = commonFxSymbols;

	if (isSpreadBettingWatchlist) {
		fXSymbols = spreadBettingFxSymbols;
	} else if (isJapanSpreadWatchlist) {
		fXSymbols = japanSpreadFxSymbols;
	}

	let defaultArray: TWatchlistInstrument[] = [];
	fXSymbols.forEach((symbol: string) => {
		marketItems.forEach((marketItem) => {
			if (marketItem.code === symbol) {
				let newInstrumentEntry = { _code: marketItem.code };
				defaultArray.push(newInstrumentEntry);
			}
		});
	});

	return defaultArray;
};

export const defaultWatchlistPreference = (
	preferredFeedId: string,
	marketItems: MarketItem[],
	translatedName: string,
	isSpreadBettingWatchlist: boolean,
	isJapanSpreadWatchlist: boolean
): TWatchlists => {
	return {
		instrument: getDefaultCFDWatchlist(marketItems, isSpreadBettingWatchlist, isJapanSpreadWatchlist),
		_feedId: preferredFeedId,
		_id: translatedName,
		_name: translatedName,
		_sortOrder: 'None',
		_sortValue: 'Instrument',
	};
};

const defineSortOrder = (order: number): WatchlistSortOrder => {
	switch (order) {
		case 1:
			return 'Ascending';
		case 2:
			return 'Descending';
		default:
			return 'None';
	}
};

export const createNewWatchlist = (name: string, feedId: string, instruments?: TWatchlistInstrument[]): TWatchlists => {
	return {
		instrument: instruments || [],
		_feedId: feedId,
		_id: name,
		_name: name,
		_sortOrder: WATCHLIST_SORT_ORDER.NONE,
		_sortValue: WATCHLIST_SORT_VALUE.INSTRUMENT,
	};
};
export const dynamicWatchlistPreference = (
	preferredFeedId: string,
	translatedName: string,
	instrument: any,
	order: number
): TWatchlists => {
	return {
		instrument: instrument,
		_feedId: preferredFeedId,
		_id: translatedName,
		_name: translatedName,
		_sortOrder: defineSortOrder(order),
		_sortValue: WATCHLIST_SORT_VALUE.INSTRUMENT,
	};
};

export const instrumentExistsInDynamicWatchlists = (
	name: string,
	watchlists: DefaultWatchList[],
	isSpreadBettingAccount: boolean
): boolean => {
	let result = false;
	if (watchlists.length > 0 && !isSpreadBettingAccount) {
		watchlists.forEach((watchList) => {
			if (!!watchList.items.find((item: WatchListItem) => item.title === name)) result = true;
		});
	}
	return result;
};

const spreadBettingFxSymbols = [
	'UK100_SB',
	'GER40_SB',
	'NAS100_SB',
	'US30_SB',
	'SPX500_SB',
	'JPN225_SB',
	'GBPUSD_SB',
	'EURUSD_SB',
	'USDJPY_SB',
	'XAUUSD_SB',
	'WTI_SB',
];

export const japanSpreadWatchlistName = 'デフォルト（全通貨ペア)';
const japanSpreadFxSymbols: string[] = [
	'USDJPY',
	'EURUSD',
	'EURJPY',
	'AUDJPY',
	'GBPJPY',
	'NZDJPY',
	'CADJPY',
	'CHFJPY',
	'GBPUSD',
	'AUDUSD',
	'NZDUSD',
	'USDCAD',
	'USDCHF',
	'EURGBP',
	'EURAUD',
	'EURCAD',
	'EURCHF',
	'EURNZD',
	'GBPAUD',
	'GBPCAD',
	'GBPCHF',
	'GBPNZD',
	'AUDCAD',
	'AUDCHF',
	'AUDNZD',
	'CADCHF',
	'EURHUF',
	'EURSGD',
	'GBPHUF',
	'NZDCAD',
	'NZDCHF',
	'USDHUF',
	'USDMXN',
	'USDPLN',
	'USDSGD',
	'USDZAR',
	'EURMXN',
	'MXNJPY',
	'ZARJPY',
	'USDCNH',
	'AUDCNH',
	'AUDSGD',
	'CNHJPY',
	'EURCNH',
	'GBPCNH',
	'NZDSGD',
	'SGDJPY',
	'EURNOK',
	'EURSEK',
	'USDNOK',
	'USDSEK',
	'CHFSEK',
	'GBPNOK',
	'GBPSEK',
	'GBPSGD',
	'NOKJPY',
	'NZDCNH',
	'EURCZK',
	'EURPLN',
	'EURZAR',
	'GBPZAR',
	'USDHKD',
	'EURDKK',
	'EURHKD',
	'GBPCZK',
	'GBPDKK',
	'GBPPLN',
	'NOKSEK',
	'USDCZK',
	'USDDKK',
];
