import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import AppContext from '../../../../../../contexts/AppContext';
import Modal from '../../../../../../shared/Modal';
import { ChartTimezone } from '../../../../../../utils/functions/enums';

import authStore from '../../../../../../store/authStore';

import { getAccountIconOptions } from '../../../Settings/settingsOptions';

import { getActiveTier } from '../../../../../../utils/functions/subscriptionUtils';

import { tierIcons } from '../../../../../../setup/subscriptionsConfig';

import styles from '../../Header.module.scss';

import PlatformSettings from './PlatformSettings';
import SecuritySettings from './SecuritySettings';
import TradingSettings from './TradingSettings';
import AvatarIcons from './AvatarIcons';

import ProfileSettings from './ProfileSettings';
import PasswordSettings from './PasswordSettings';
import useGetMFAEnabled from '../../../../../../utils/queries/useGetMFAEnabled';
interface settingsModalProps {
	openSettings: boolean;
	setOpenSettings: (openSettings: boolean) => void;
	setResetIdleTimer: Dispatch<SetStateAction<boolean>>;
}

interface passwordSettings {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

interface settings {
	// quantityType: 'Amount' | 'Lots';
	tradeFromChartEnabled: boolean;
	oneClickTrading: boolean;
	notificationSoundMuted: boolean;
	notificationSoundVolume: number;
	language: string;
	theme: string;
	timezone: string;
	inactivityLogout: number;
	accountAvatar?: number;
}

interface tabs {
	platformSettings: { name: string; icon: string };
	tradingSettings: { name: string; icon: string };
	passwordSettings: { name: string; icon: string };
	securitySettings: { name: string; icon: string };
	// uploadDocuments: { name: string; icon: string };
}
const TabsEnum = {
	profileSettings: 'profileSettings',
	platformSettings: 'platformSettings',
	tradingSettings: 'tradingSettings',
	passwordSettings: 'passwordSettings',
	securitySettings: 'securitySettings',
	// uploadDocuments: 'uploadDocuments',
} as const;

// convert the UserPreferences object from the _fuzzy_ format stored in the BE
// to something easy to use and back
const parseSettings = (preferences: any = {}): settings => ({
	// quantityType: preferences.trading.settings.cfd._quantityType.replace('mm', 'm'),
	tradeFromChartEnabled: preferences.trading.settings.cfd._tradeFromChartEnabled,
	oneClickTrading: preferences.trading.settings.cfd._mode === '1-Click',
	notificationSoundMuted: preferences.trading.settings.cfd._notificationSoundMuted,
	notificationSoundVolume: preferences.trading.settings.cfd._notificationSoundVolume,
	language: preferences.platform.lang._code,
	theme: preferences.platform.theme._type,
	// for some reason we've not added a default value for the timezone, it's there now but we
	// should keep this for now; if no value is preset the dashboard uses Local - check fullContextFromPreferences
	timezone: preferences.platform.timezone._name || ChartTimezone.Local,
	inactivityLogout: preferences.platform.inactivity,
	accountAvatar: preferences.platform.accountAvatar,
});

const encodeSettings = (currentSettings: any, parsedSettings: any) => {
	// currentSettings.trading.settings.cfd._quantityType = parsedSettings.quantityType;
	currentSettings.trading.settings.cfd._tradeFromChartEnabled = parsedSettings.tradeFromChartEnabled;
	currentSettings.trading.settings.cfd._notificationSoundMuted = parsedSettings.notificationSoundMuted;
	currentSettings.trading.settings.cfd._notificationSoundVolume = parsedSettings.notificationSoundVolume;
	currentSettings.trading.settings.cfd._mode = parsedSettings.oneClickTrading ? '1-Click' : 'Regular';
	currentSettings.platform.lang._code = parsedSettings.language;
	currentSettings.platform.theme._type = parsedSettings.theme;
	currentSettings.platform.timezone._name = parsedSettings.timezone;
	currentSettings.platform.inactivity = parsedSettings.inactivityLogout;
	currentSettings.platform.accountAvatar = parsedSettings.accountAvatar;

	return currentSettings;
};

const SettingsModal: React.FC<settingsModalProps> = ({ openSettings, setOpenSettings, setResetIdleTimer }) => {
	const { t } = useTranslation();
	const { isLoading, data: isMFAEnabled } = useGetMFAEnabled();

	const appContext = useContext(AppContext);
	const accountAvatar = authStore.use.accountAvatar();
	const userProfile = authStore.use.userProfile();
	const [fullName, setFullname] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [showInfoModalBackground, setShowInfoModalBackground] = useState<boolean>(false);

	useEffect(() => {
		setFullname(userProfile?.fullName);
		setEmail(userProfile?.email || '');
	}, [userProfile, showInfoModalBackground]);

	const [disabled, setDisabled] = useState(true);

	// override stored language with the one from the login form
	const initialSettings: settings = parseSettings(appContext.userPreferences!.user_prefs);
	initialSettings.language = appContext.languageSettings;

	const [settings, setSettings] = useState<settings>(initialSettings);
	const [activeTab, setActiveTab] = useState<string>(TabsEnum.profileSettings);
	const [passwordSettings, setPasswordSettings] = useState<passwordSettings>({
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
	});

	const handleUpdatePasswordSettings = (values: Record<string, string>) => {
		setPasswordSettings({ ...passwordSettings, ...values });
	};

	useEffect(() => {
		for (const item in initialSettings) {
			const key = item as keyof typeof settings;
			if (settings[key] !== initialSettings[key]) {
				setDisabled(false);
				return;
			}
		}
		setDisabled(true);
	}, [settings, userProfile]);

	useEffect(() => {
		for (const item in initialSettings) {
			const key = item as keyof typeof settings;
			if (settings[key] !== initialSettings[key]) {
				setDisabled(false);
				return;
			}
		}
		// setDisabled(true);
	}, [settings]);

	const handleCloseModal = () => {
		setOpenSettings(false);
		appContext.openSettings = false;
		setDisabled(true);
	};

	const tabs: tabs = {
		platformSettings: { name: t('wtr:PLATFORM'), icon: 'desktop' },
		tradingSettings: { name: t('wtr:WTR_TRADING'), icon: 'chart-candlestick' },
		passwordSettings: { name: t('wtr:CHANGE_PASS'), icon: 'key' },
		securitySettings: { name: t('en:SETTINGS_SECURITY'), icon: 'shield-keyhole' },
		// uploadDocuments: { name: t('en:UPLOAD_DOCUMENTS_BUTTON_TYPE_TITLE'), icon: 'file-arrow-up' },
	};
	const isLiveMode = authStore.use.isLiveMode();
	const isJapanAccount = authStore.use.isJapanAccount();
	const activeTier = isJapanAccount && isLiveMode && getActiveTier(appContext.subscriptionInfo);

	const findImage = () => {
		const currentImage = getAccountIconOptions().find((image) => image.value === accountAvatar);

		if (currentImage && !activeTier && accountAvatar !== 0) {
			return currentImage.image.src;
		}

		if (activeTier && !accountAvatar) {
			return tierIcons[activeTier.toLowerCase()];
		}

		return '';
	};

	return (
		<>
			<Modal centered show={openSettings} className={styles.settingsMain}>
				<div className={styles.settingsWrapper}>
					<div className={styles.settingsNavigation}>
						<div className={styles.settingsModalHeader}>
							<div className={styles.optionsContainer}>
								<div
									className={cn(
										styles.profileContainer,
										activeTab === TabsEnum.profileSettings ? styles.profileSettingsBG : ''
									)}
									onClick={() => {
										setSettings(initialSettings);
										setActiveTab(TabsEnum.profileSettings);
									}}
								>
									<div className={styles.profileImage}>
										{findImage() ? (
											<AvatarIcons avatarPath={findImage()} />
										) : (
											<FontAwesomeIcon className={cn(styles['large'], styles.defaultImage)} icon={['fal', 'user']} />
										)}
									</div>
									<div className={styles.profileImageInfo}>
										<div className={styles.profileName}>{fullName}</div>
										<div className={styles.profileEmail}>{email}</div>
									</div>
								</div>
								{Object.entries(tabs).map(
									([tabName, tabDisplayText]: [string, { name: string; icon: any }]) =>
										tabDisplayText && (
											<div
												className={activeTab === tabName ? styles.settingsTabContainer : styles.settingsTabOption}
												key={tabName}
												onClick={() => {
													setSettings(initialSettings);
													setActiveTab(tabName);
												}}
											>
												<div className={styles.iconContainer}>
													<FontAwesomeIcon
														icon={[activeTab === tabName ? 'fas' : 'far', tabDisplayText.icon]}
														size="lg"
														className={styles.settingsTabContainerIcon}
													/>
												</div>
												<label className={styles.settingsTabContainerLabel}>{tabDisplayText.name}</label>
											</div>
										)
								)}
							</div>
						</div>
					</div>
					<div className={styles.tab}>
						{activeTab === TabsEnum.profileSettings && (
							<ProfileSettings
								handleCloseModal={handleCloseModal}
								settings={settings}
								setOpenSettings={setOpenSettings}
							/>
						)}
						{activeTab === TabsEnum.platformSettings && (
							<PlatformSettings
								handleCloseModal={handleCloseModal}
								settings={settings}
								setOpenSettings={setOpenSettings}
							/>
						)}
						{activeTab === TabsEnum.tradingSettings && (
							<TradingSettings
								handleCloseModal={handleCloseModal}
								settings={settings}
								setSettings={setSettings}
								showInfoModalBackground={showInfoModalBackground}
								setShowInfoModalBackground={setShowInfoModalBackground}
							/>
						)}
						{activeTab === TabsEnum.passwordSettings && (
							<PasswordSettings
								handleCloseModal={handleCloseModal}
								setDisabled={setDisabled}
								handleUpdatePasswordSettings={handleUpdatePasswordSettings}
							/>
						)}
						{activeTab === TabsEnum.securitySettings && (
							<SecuritySettings
								handleCloseModal={handleCloseModal}
								setDisabled={setDisabled}
								handleUpdatePasswordSettings={handleUpdatePasswordSettings}
								setOpenSettings={setOpenSettings}
								isMFAEnabled={!!isMFAEnabled}
							/>
						)}
						{/* {activeTab === TabsEnum.uploadDocuments && <DocumentsSettings handleCloseModal={handleCloseModal} />} */}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default SettingsModal;
