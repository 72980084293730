import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MarketItem } from '../../../gateways/RfpGateway/rfp.types';
import authStore from '../../../store/authStore';
import useTimeSessions from '../../../utils/hooks/useTimeSessions';

import styles from './TradingSessionTooltip.module.scss';

interface TradingSessionTooltipProps {
	marketItem: MarketItem;
}

const TradingSessionTooltip: FC<TradingSessionTooltipProps> = ({ marketItem }): JSX.Element | null => {
	const { t } = useTranslation();
	const { getNextOpenDate, isWorkingTime, getClosesTime } = useTimeSessions();
	const isJapanAccount = authStore.use.isJapanAccount();

	const minutesFromCurrentTime = new Date().getMinutes();

	const workingTime = React.useMemo(() => {
		return isJapanAccount ? undefined : isWorkingTime(marketItem, new Date());
	}, [isJapanAccount, marketItem, marketItem.tradingSessions, minutesFromCurrentTime]);

	const workingTimeText = React.useMemo(() => {
		return workingTime ? t('en:MARKET_IS_OPEN') : `${t('en:202')}`;
	}, [marketItem, marketItem.tradingSessions, minutesFromCurrentTime]);

	const nextOpenDateText = React.useMemo(() => {
		return workingTime ? '' : getNextOpenDate(marketItem, new Date());
	}, [marketItem, marketItem.tradingSessions, minutesFromCurrentTime]);

	const nextClosesDateText = React.useMemo(() => {
		return workingTime ? getClosesTime(marketItem, new Date()) : '';
	}, [marketItem, marketItem.tradingSessions, minutesFromCurrentTime]);

	const nextDateText = React.useMemo(() => {
		return workingTime !== undefined ? (workingTime ? nextClosesDateText : nextOpenDateText) : '';
	}, [marketItem, marketItem.tradingSessions, minutesFromCurrentTime]);

	return (
		<OverlayTrigger
			delay={{ show: 0, hide: 1 }}
			key={`tt_session_icon_${marketItem?.code}_${Math.random()}`}
			placement="bottom"
			overlay={
				<Tooltip className="my-tooltip" id={`tt_session_icon_${marketItem?.code}_${Math.random()}`}>
					<div className={cn(styles.ttSessionInfoContainer)}>
						<div
							className={cn({
								[styles.ttSessionMarketStatusInfo]: true,
								[styles.open]: workingTime,
								[styles.closed]: !workingTime,
							})}
						>
							{workingTimeText}
						</div>
						{nextDateText && (
							<div
								className={cn({
									[styles.ttSessionMarketStatusInfo]: true,
									[styles.gray]: true,
								})}
							>
								{nextDateText}
							</div>
						)}
					</div>
				</Tooltip>
			}
		>
			<div>
				{workingTime !== undefined && workingTime && (
					<FontAwesomeIcon className={cn(styles.sessionIcon)} icon={['fas', 'brightness']} />
				)}

				{workingTime !== undefined && !workingTime && (
					<FontAwesomeIcon className={cn(styles.sessionIcon)} icon={['fas', 'moon']} />
				)}
			</div>
		</OverlayTrigger>
	);
};

export default TradingSessionTooltip;
