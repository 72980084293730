import i18n from '../../../../setup/i18n';

const COLUMNS_SETUP_STORAGE_KEY = 'marketGridColumns';
const MARKET_FILTERS_STORAGE_KEY = 'marketFilters';

const CATEGORY_TRANSLATION_KEYS = {
	All: 'en:All',
	CFD: 'wtr:ETFS',
	ETF: 'wtr:ETFS',
	Equities: 'wtr:SHARE_CFDS',
	Commodities: 'wtr:WTR_COMMODITIES',
	Crypto: 'wtr:CRYPTO',
	Energy: 'wtr:ENERGY',
	Forex: 'wtr:WTR_FOREX',
	Indices: 'wtr:WTR_INDICES',
	Metals: 'wtr:METALS',
};

const COUNTRYCODE_TRANSLATION_KEYS = {
	AU: 'wtr:Australia',
	DE: 'wtr:Germany',
	ES: 'wtr:Spain',
	FI: 'wtr:Finland',
	FR: 'wtr:France',
	GB: 'wtr:GreatBritain',
	HK: 'wtr:HongKong',
	IT: 'wtr:Italy',
	NL: 'wtr:Netherlands',
	US: 'wtr:UnitedStates',
	ZA: 'wtr:SouthAfrica',
};

const SECTOR_TRANSLATION_KEYS = {
	'Communication Services': 'wtr:COMMUNICATION_SERVICES',
	'Consumer Discretionary': 'wtr:CONSUMER_DISCRETIONARY',
	'Consumer Staples': 'wtr:CONSUMER_STAPLES',
	Energy: 'wtr:ENERGY',
	Financials: 'wtr:FINANCIALS',
	'Health Care': 'wtr:HEALTH_CARE',
	Industrials: 'wtr:INDUSTRIALS',
	'Information Technology': 'wtr:INFORMATION_TECH',
	Materials: 'wtr:MATERIALS',
	'Real Estate': 'wtr:REAL_ESTATE',
	Technology: 'wtr:TECH',
	Utilities: 'wtr:UTILITIES',
};

const TRANSLATIONS_KEY_MAP: { [key: string]: { [key: string]: string } } = {
	category: CATEGORY_TRANSLATION_KEYS,
	countrycode: COUNTRYCODE_TRANSLATION_KEYS,
	sector: SECTOR_TRANSLATION_KEYS,
};

export const translateMarketTerm = (termType: string, termKey: string) => {
	const translationKey = TRANSLATIONS_KEY_MAP[termType]?.[termKey];
	if (translationKey) {
		return i18n.t(translationKey);
	}

	return termKey;
};

export const getValue = (val1: number | undefined | null, val2: number | undefined | null) => {
	if (val1 && val1 > 0) {
		return val1;
	} else if (val2 && val2 > 0) {
		return val2;
	}
	return 0;
};

// check if column is applicable to market group
export const checkMarketGroupHasThisColumn = (
	marketGroupName: string,
	columnName: string,
	marketGroupColumns: { [key: string]: string[] }
): boolean => {
	const columnsConfigForMarketGroup = marketGroupColumns[marketGroupName];
	if (!columnsConfigForMarketGroup) {
		return false;
	}

	return columnsConfigForMarketGroup.includes(columnName);
};

export const saveColumnsSetup = (e: any) => {
	if (!Array.isArray(e.columns)) {
		return;
	}

	localStorage.setItem(
		COLUMNS_SETUP_STORAGE_KEY,
		JSON.stringify(
			(e.columns as any[]).map((c) => ({
				dataField: c.dataField,
				dataType: c.dataType,
				name: c.name,
				visible: c.visible,
				visibleIndex: c.visibleIndex,
				width: c.width,
			}))
		)
	);
};

export const getSavedColumnsSetup = () => JSON.parse(localStorage.getItem(COLUMNS_SETUP_STORAGE_KEY) ?? '{}');

export const getColumnVisibility = (dataField: string, columns: any) =>
	Object.keys(columns).length > 0
		? (columns as any[]).find((column) => column.dataField === dataField)?.visible
		: undefined;

export const getColumnVisibilityIndex = (dataField: string, columns: any) => {
	if (Object.keys(columns).length > 0) {
		//Chart is initalized on category without Volatility or Change,
		//below allows them to inject in the correct spot even when visibility order for other columns is saved
		if (dataField === 'volatility' || dataField === 'change') {
			const localData = (columns as any[]).find((column) => column.dataField === dataField);
			if (localData) {
				return localData.visibleIndex;
			} else {
				const lowField = (columns as any[]).find((column) => column.dataField === 'low');
				if (!lowField) {
					return;
				}
				return lowField.visibleIndex - (dataField === 'volatility' ? 1.5 : 0.5);
			}
		}
		return (columns as any[]).find((column) => column.dataField === dataField)?.visibleIndex;
	}
	return undefined;
};

export const getColumnWidth = (dataField: string, columns: any) => {
	if (Object.keys(columns).length > 0) {
		let width = (columns as any[]).find((column) => column.dataField === dataField)?.width;
		return width;
	}
	return undefined;
};

export const getSavedMarketFilters = () => JSON.parse(sessionStorage.getItem(MARKET_FILTERS_STORAGE_KEY) ?? '{}');

export const saveMarketFilters = (marketFilters: any) =>
	sessionStorage.setItem('marketFilters', JSON.stringify(marketFilters));
