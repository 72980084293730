import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import InstrumentContext from '../../../../../../contexts/InstrumentContext';
import AppContext from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import useOrderTicketAccess from '../../../../../../utils/hooks/useOrderTicketAccess';
import { useMarketItemsMap } from '../../../../../components/MarketItemFormatter/useMarketItemsMap';
import {
	ColumnFiltersState,
	ColumnResizeDirection,
	ColumnResizeMode,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	Row,
	FilterFn,
	SortingState,
	useReactTable,
	OnChangeFn,
} from '@tanstack/react-table';
import { PriceQuote, TradingPosition, TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import { PositionTableItem } from '../../../../../../utils/functions/marketItems/marketItemGroupMapFormatter';
import cn from 'classnames';
import styles from '../../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useVirtualizer } from '@tanstack/react-virtual';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import DraggableTableHeader from '../../../../Markets/MarketsGridNew/components/DraggableTableHeader';
import DragAlongCell from '../../../../Markets/MarketsGridNew/components/DragAlongCell';
import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';
import SettingGrid from '../components/SettingsGrid';
import { closeAllTickets, positionTableObj } from '../helpers';
import RenderClearFilters from '../components/renderClearFilters';
import usePositionGridColumn from './usePositionGridColumn';
import usePositionTable from './usePositionTable';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import useGridHeight from '../../../../../../utils/hooks/useGridHeight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../shared/Button';

const PositionTable = ({
	columnFilters,
	setColumnFilters,
}: {
	columnFilters: ColumnFiltersState;
	setColumnFilters: OnChangeFn<ColumnFiltersState>;
}) => {
	const instrumentContext = useContext(InstrumentContext);
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const { t } = useTranslation();
	const orderTicketAccess = useOrderTicketAccess();
	const marketsItemMap = useMarketItemsMap();
	const activeTradingAccount = useSelectedTradingAccount();

	const tradingPositions = dashboardContext.getTradingPositions();

	const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
	const [columnResizeMode, _] = useState<ColumnResizeMode>('onChange');
	const [columnVisibility, setColumnVisibility] = useState({});
	const [globalFilter, setGlobalFilter] = React.useState('');
	const [highlightPosition, setHighlightPosition] = useState('');
	const [expanded, setExpanded] = React.useState({});

	const tableContainerRef = React.useRef<HTMLDivElement>(null);
	const columns = usePositionGridColumn(setShowSettingsModal);
	const tableData = usePositionTable();
	const [sorting, setSorting] = useState<SortingState>([]);

	const [columnOrder, setColumnOrder] = React.useState<string[]>([]);

	const isMozilla = navigator.userAgent.includes('Firefox');
	const [havePositions, setHavePositions] = useState<boolean>(true);

	let emptyFilteredStateMessage = t('wtr:NO_OPEN_POSITIONS_FOUND');

	useEffect(() => {
		const localDataOrder = localStorage.getItem('positionTableColumnsOrder');

		if (!columnOrder.length && localDataOrder?.length) {
			const parsedData = JSON.parse(localDataOrder);
			const quantityItem = parsedData.some((item: string) => item === 'Amount' || item === 'Lots');
			if (quantityItem) {
				setColumnOrder(() => columns.map((c) => c.id!));
				localStorage.setItem('positionTableColumnsOrder', JSON.stringify(columns.map((c) => c.id!)));
			} else if (parsedData[parsedData.length - 1] === 'close') {
				setColumnOrder(parsedData);
			}
		} else if (!columnOrder.length && !localDataOrder?.length) {
			setColumnOrder(() => columns.map((c) => c.id!));
		} else {
			localStorage.setItem('positionTableColumnsOrder', JSON.stringify(columnOrder));
		}
	}, [columnOrder]);

	const columnResizeDirection: ColumnResizeDirection = useMemo(() => {
		return appContext.isArabic ? 'rtl' : 'ltr';
	}, [appContext.isArabic]);

	const table = useReactTable({
		data: tableData,
		columns,
		columnResizeMode,
		columnResizeDirection,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		state: {
			sorting,
			columnVisibility,
			columnOrder,
			expanded,
			columnFilters,
			globalFilter,
		},
		onExpandedChange: setExpanded,
		getSubRows: (row) => row.subRows,
		onColumnVisibilityChange: setColumnVisibility,
		getFilteredRowModel: getFilteredRowModel(),
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onColumnOrderChange: setColumnOrder,
		getExpandedRowModel: getExpandedRowModel(),
		debugTable: true,
	});

	useEffect(() => {
		const localData = localStorage.getItem('positionTableColumns');

		const localSortingData = localStorage.getItem('positionSortingTable');

		if (!localData) {
			setColumnVisibility((state) => ({
				...state,
				...positionTableObj,
			}));
			localStorage.setItem('positionTableColumns', JSON.stringify(positionTableObj));
		} else {
			const data = JSON.parse(localData) as object;
			if (data && typeof data === 'object') {
				setColumnVisibility((state) => ({
					...state,
					...data,
				}));
			}
		}

		if (!localSortingData) {
			localStorage.setItem('positionSortingTable', JSON.stringify(sorting));
		} else {
			const data = JSON.parse(localSortingData);
			if (data) {
				setSorting(data);
			}
		}

		const setItemToStorage = () => {
			localStorage.setItem('positionTableColumns', JSON.stringify(table.getState().columnVisibility));
			localStorage.setItem('positionSortingTable', JSON.stringify(table.getState().sorting));
		};

		window.addEventListener('beforeunload', setItemToStorage);

		return () => {
			setItemToStorage();
			window.removeEventListener('beforeunload', setItemToStorage);
		};
	}, []);

	const handleSelectedInstrument = (cell: any) => {
		if (
			cell.column.id === 'settings' ||
			cell.column.id === 'close' ||
			cell.column.id === 'TakeProfit' ||
			cell.column.id === 'StopLoss'
		) {
			return;
		}

		if (!orderTicketAccess()) {
			return;
		}

		const data = cell.row.original;

		const record = marketsItemMap[cell.row.original.code];

		if (!record) {
			return;
		}

		const marketItem = instrumentContext.instruments.find(
			(instrument) => instrument.feedId === record.feedId && instrument.code === record.code
		);

		if (!marketItem) {
			return;
		}

		closeAllTickets(dashboardContext);
		if (record) {
			const positions: any = tradingPositions.filter(
				(t) =>
					t.code === record.code &&
					t.state === TradingPositionState.open &&
					t.aId === activeTradingAccount?.id &&
					(data.isGroupRow || data.posId === t.posId)
			);
			dashboardContext.selectedPosition = positions;
			dashboardContext.gridChartsChanged = true;

			if (record) {
				dashboardContext.selectedType = 'Grid';
				dashboardContext.isEdit = false;
				dashboardContext.showConfirmTicket = false;
				dashboardContext.selectedInstrument = marketItem;
				setHighlightPosition(data.headID);
			}
		}
	};

	// reorder columns after drag & drop
	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event;
		if (active && over && active.id !== over.id) {
			setColumnOrder((columnOrder) => {
				const oldIndex = columnOrder.indexOf(active.id as string);
				const newIndex = columnOrder.indexOf(over.id as string);
				return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
			});
		}
	}

	const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

	const { rows } = table.getRowModel();

	//dynamic row height virtualization - alternatively you could use a simpler fixed row height strategy without the need for `measureElement`
	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: useCallback(() => 40, []), //estimate row height for accurate scrollbar dragging
		getScrollElement: () => tableContainerRef.current,
		//measure dynamic row height, except in firefox because it measures table border height incorrectly
		measureElement:
			typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
				? (element) => element?.getBoundingClientRect().height
				: undefined,
		overscan: 3,
	});

	const virtualRows = rowVirtualizer.getVirtualItems();

	const tableContainerHeight = useGridHeight(rowVirtualizer);

	useEffect(() => {
		if (tableData.length > 0 && virtualRows.length > 0) {
			setHavePositions(true);
		} else if(tableData.length > 0 && virtualRows.length === 0) {
			setHavePositions(false);
		} else {
			setHavePositions(true);
		}
	},[tableData.length, virtualRows.length, havePositions]);

	if (!tableData.length) {
		return (
			<RenderClearFilters
				columnFilters={columnFilters}
				setColumnFilters={setColumnFilters}
				type={TradingPositionState.open}
			/>
		);
	}

	if (havePositions) {
		return (
			<div className={cn(styles.positionPageTableWrapper)}>
				{showSettingsModal && (
					<SettingGrid table={table} setShowSettingsModal={setShowSettingsModal} name={'positionTableColumns'} />
				)}

				<DndContext
					collisionDetection={closestCenter}
					modifiers={[restrictToHorizontalAxis]}
					onDragEnd={handleDragEnd}
					sensors={sensors}
				>
					<div style={{ direction: table.options.columnResizeDirection }}>
						<div ref={tableContainerRef} className={cn(styles.tableContainerVirtualStyles)} style={tableContainerHeight}>
							<table
								{...{
									className: cn(styles.table),
								}}
							>
								<thead className={cn(styles.stickyHeader)}>
									{table.getHeaderGroups().map((headerGroup) => {
										return (
											<tr key={headerGroup.id}>
												<SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
													{headerGroup.headers.map((header) => (
														<DraggableTableHeader key={header.id} header={header} table={table} />
													))}
												</SortableContext>
											</tr>
										);
									})}
								</thead>

								<tbody
									style={{
										height: !isMozilla ? `${rowVirtualizer.getTotalSize() + 1.5}px` : 'inherit', //tells scrollbar how big the table is
										position: 'relative', //needed for absolute positioning of rows
									}}
								>
									{virtualRows.map((virtualRow) => {
										const row = rows[virtualRow.index] as Row<PositionTableItem>;

										const position = row.original.headID;

										let positionClass = position === highlightPosition;
										return (
											<tr
												key={row.id}
												data-index={virtualRow.index} //needed for dynamic row height measurement
												// ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
												className={cn(styles.tableRow, positionClass && styles.tableRowTransparent)}
												style={{
													position: 'absolute',
													transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
													width: '100%',
													height: `${virtualRow.size}px`, //this should always be a `style` as it changes on scroll
													display: 'flex',
												}}
											>
												{row.getVisibleCells().map((cell) => {
													if (
														cell.column.id === 'instrument' ||
														cell.column.id === 'close' ||
														cell.column.id === 'settings'
													) {
														return (
															<td
																{...{
																	key: cell.id,
																	className: cn({
																		[styles.tableData]: true,
																		[styles.td]: true,
																	}),
																	style: {
																		opacity: 1,
																		position: 'relative',
																		width: cell.column.getSize(),
																		zIndex: 0,
																		whiteSpace: 'nowrap',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																		lineHeight: '1.5',
																	},
																	onClick: () => handleSelectedInstrument(cell),
																}}
															>
																{flexRender(cell.column.columnDef.cell, cell.getContext())}
															</td>
														);
													}

													return (
														<SortableContext
															key={cell.id}
															items={columnOrder}
															strategy={horizontalListSortingStrategy}
															disabled={
																cell.column.id === 'instrument' ||
																cell.column.id === 'close' ||
																cell.column.id === 'settings'
															}
														>
															<DragAlongCell
																key={cell.id}
																cell={cell}
																handleSelectedInstrument={handleSelectedInstrument}
															/>
														</SortableContext>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</DndContext>
			</div>
		);
	} else {
		return (
			<div className={styles.emptyGridContainer}>
				<FontAwesomeIcon className={styles.emptyIcon} icon={['fal', 'search']} size="5x" />
				<div className={styles.emptyStateMessage}>{emptyFilteredStateMessage}</div>
				<Button
					type="submit"
					className={styles.startTrading_Active}
					label={t('wtr:CLEAR_FILTERS')}
					variant="secondary"
					onClick={() => setColumnFilters([])}
				/>
			</div>
		)
	}
};

export default PositionTable;
