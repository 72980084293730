import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import styles from './Button.module.scss';

interface ButtonProps {
	className?: string;
	labelClass?: string;
	disabled?: boolean;
	label: any | undefined;
	onClick?: (event: React.MouseEvent) => void;
	icon?: IconProp;
	iconOnhover?: IconProp;
	size?: 'sm' | 'md' | 'lg' | 'fluid';
	type?: 'submit';
	variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'outline';
	smallFont?: boolean;
	isFullWidth?: boolean;
	disabledOnHover?: boolean;
	children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
	className,
	labelClass,
	disabled = false,
	label,
	onClick,
	icon,
	iconOnhover,
	size = 'md',
	type,
	variant = 'primary',
	smallFont = false,
	isFullWidth = false,
	children,
	disabledOnHover = false,
}) => {
	const [labelIcon, setLabelIcon] = useState<IconProp | undefined>(icon);
	useEffect(() => {
		setLabelIcon(icon);
	}, [icon]);

	const _className = cn(
		smallFont ? styles.smallFont : '',
		className,
		styles.button,
		styles[size],
		styles[variant],
		{
			[styles.disabled]: disabled,
			[styles.outlineDisabled]: disabled && variant === 'outline',
		},
		isFullWidth ? styles.fullWidth : ''
	);
	if (!label) {
		return null;
	}
	const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (!disabled && typeof onClick === 'function') {
			onClick(event);
		}
	};

	return (
		<button
			disabled={disabled}
			type={type}
			className={_className}
			onClick={handleButtonClick}
			aria-label={label}
			onMouseEnter={() => {
				if (!disabledOnHover) setLabelIcon(iconOnhover);
			}}
			onMouseLeave={() => setLabelIcon(icon)}
		>
			{labelIcon && <FontAwesomeIcon icon={labelIcon} />}
			<span className={cn(labelClass && labelClass, styles.buttonLabel)}>
				{children}
				{label}
			</span>
		</button>
	);
};

export default Button;
