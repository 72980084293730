import { useContext } from 'react';

import AppContext from '../../../contexts/AppContext';
import { TWatchlists } from '../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import authStore, { AuthStore } from '../../../store/authStore';
import useSaveUserPreferences from '../../mutations/useSaveUserPreferences';
import tradingAccountStore from '../../../store/tradingAccountStore';
import { convertTWatchlistsToWatchListData } from '../../functions/WatchlistUtils';
import watchListStore from '../../../store/WatchListStore/watchListStore';

const useSaveWatchlistToPreferences = () => {
	const appContext = useContext(AppContext);
	const { mutate: savePreferences } = useSaveUserPreferences();

	const setWatchlists = watchListStore.use.setWatchlists();
	const tradingMode = authStore((store: AuthStore) => store.tradingMode);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isJapanSpread = tradingAccountStore.use.isJapanSpread();
	return (preference: TWatchlists[], isSpreadBettingWatchlist?: boolean) => {
		if (appContext.userPreferences) {
			const accountToUpdate = appContext.userPreferences.user_prefs.watchlists.account.filter((accountType) => {
				if (isSpreadBettingAccount || isSpreadBettingWatchlist) {
					return accountType.accountType === 'SpreadBetting';
				}
				if (isJapanSpread) {
					return accountType.accountType === 'JapanSpread';
				} else {
					return accountType.accountType === undefined;
				}
			});

			accountToUpdate.forEach((account) => {
				account.watchlist = preference;
			});

			setWatchlists(tradingMode, convertTWatchlistsToWatchListData(preference));
			savePreferences();
		}
	};
};

export default useSaveWatchlistToPreferences;
