import {
	AccountMarketType,
	Instrument,
	MarketItem,
	PriceQuote,
	TradingAccount,
	TradingInstruments,
	TradingPosition,
	TradingPositionState,
	TradingSessions,
	DayOfWeek,
	TradingSession,
	RFPSessionTimes,
} from '../../gateways/RfpGateway/rfp.types';
import {
	InitialStopLossTakeProfitValues,
	InitialValues,
} from '../../views/features/Dashboard/ChartPanel/NewOrderModals/OrderTicketModal/OrderTicketModal';
import { formatNumberWithCommas } from '../../views/features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';

import RfpGateway from '../../gateways/RfpGateway/RfpGateway';

import { MarginAccountType, TradingPositionLimitType } from './enums';
import { Resolver } from './Ioc';
import { Optional } from './Nullable';

export interface BuySellIncrement {
	minValue: number;
	maxValue: number;
	stepAmount: number;
}

export type TEffectiveSize = {
	val: number;
	majoritySide: string;
};

/* Get formatted Spread Value based on ask and bid price */
export const getFormattedSpreadValue = (
	marketItem: MarketItem,
	askPrice: number,
	bidPrice: number,
	language: string
) => {
	if (marketItem) {
		const spreadValue = calculateSpreadValue(marketItem, askPrice, bidPrice);
		const spreadString = `${spreadValue.toFixed(1)}`;
		const separatorIndex = spreadString.lastIndexOf('.');
		const spreadSplit = spreadString.split(spreadString[separatorIndex]);
		const spreadWithDecimal = spreadSplit[0].toLocaleString();
		return formatNumberWithCommas(
			spreadWithDecimal + spreadString[separatorIndex] + spreadSplit[1],
			spreadSplit[1]?.length ?? 0,
			language
		);
	}
};

/* Calculate the Spread Value based on ask and bid price */
export const calculateSpreadValue = (marketItem: MarketItem, askPrice: number, bidPrice: number) => {
	// This is the only place where we should use decimalPrecision from marketItemInfo if is setup
	const marketItemInfoDecPrecision = marketItem.marketItemInfo?.decimalPrecision;
	const decPrec = marketItemInfoDecPrecision ? +marketItemInfoDecPrecision : marketItem.decPrec;
	const tickSize = getMarketItemTickSize(marketItem);
	let decimal = tickSize >= 1.0 ? 0 : decPrec;
	decimal = decimal > 2 ? decimal - 1 : decimal;
	return (askPrice - bidPrice) * Math.pow(10, decimal);
};

/* Calculate the Initial Limit Price  and Stop Price based on BUY and SELL*/

export const calculateInitialValue = (initialValueObj: InitialValues) => {
	if (
		(initialValueObj.side === 'BUY' && initialValueObj.typeOfOrder === 'limit') ||
		(initialValueObj.side === 'SELL' && initialValueObj.typeOfOrder === 'stop')
	) {
		return (initialValueObj.instrument - initialValueObj.instrument * (initialValueObj.initialValue / 100)).toFixed(
			initialValueObj.decimalPrecision
		);
	}

	if (
		(initialValueObj.side === 'BUY' && initialValueObj.typeOfOrder === 'stop') ||
		(initialValueObj.side === 'SELL' && initialValueObj.typeOfOrder === 'limit')
	) {
		return (initialValueObj.instrument + initialValueObj.instrument * (initialValueObj.initialValue / 100)).toFixed(
			initialValueObj.decimalPrecision
		);
	}
};

/* Calculate the Stop Price usually 10% upside for Sell and 10% downside for Buy */

interface CalcInitialLimitValueProps {
	price: number;
	isBuy: boolean;
	type: TradingPositionLimitType;
	pipSize: number;
	defaultPips: number;
}

export const calcInitialLimitValue = ({ price, isBuy, type, pipSize, defaultPips }: CalcInitialLimitValueProps) => {
	if (!isNaN(price)) {
		const isTakeProfit = type === TradingPositionLimitType.TakeProfit;
		const pipValue = defaultPips * pipSize;
		if (isBuy) {
			return isTakeProfit ? price + pipValue : price - pipValue;
		} else {
			return isTakeProfit ? price - pipValue : price + pipValue;
		}
	}

	return NaN;
};

export const calculateInitialStopLossValue = (initialStopLossValueObj: InitialStopLossTakeProfitValues) => {
	const quote_value =
		initialStopLossValueObj.typeOfOrder === 'limit' || initialStopLossValueObj.typeOfOrder === 'stop'
			? parseFloat(initialStopLossValueObj.limitPrice)
			: initialStopLossValueObj.instrument;
	if (quote_value !== 0) {
		return initialStopLossValueObj.side === 'BUY'
			? (quote_value - quote_value * (initialStopLossValueObj.initialValue / 100)).toFixed(
					initialStopLossValueObj.decimalPrecision
			  )
			: (quote_value + quote_value * (initialStopLossValueObj.initialValue / 100)).toFixed(
					initialStopLossValueObj.decimalPrecision
			  );
	}
	return 0;
};

/* Calculate the Take Profit usually 10% upside for Buy and 10% downside for Sell */

export const calculateInitialTakeProfitValue = (initialStopLossTakeProfitObj: InitialStopLossTakeProfitValues) => {
	let quote_value =
		initialStopLossTakeProfitObj.typeOfOrder === 'limit' || initialStopLossTakeProfitObj.typeOfOrder === 'stop'
			? parseFloat(initialStopLossTakeProfitObj.limitPrice)
			: initialStopLossTakeProfitObj.instrument;
	if (quote_value !== 0) {
		return initialStopLossTakeProfitObj.side === 'BUY'
			? (quote_value + quote_value * (initialStopLossTakeProfitObj.initialValue / 100)).toFixed(
					initialStopLossTakeProfitObj.decimalPrecision
			  )
			: (quote_value - quote_value * (initialStopLossTakeProfitObj.initialValue / 100)).toFixed(
					initialStopLossTakeProfitObj.decimalPrecision
			  );
	}
	return 0;
};

/* Set Initial min, max and step value for the Limit Order, StopLoss and TakeProfit */

export const calculateOrderValueConfig = (
	typeOfOrder: string,
	quote: any,
	stepAmount: number,
	orderType?: string,
	tradeMeasure?: string
): BuySellIncrement => {
	if (tradeMeasure === 'stopLoss') {
		return {
			minValue: orderType === 'SELL' ? (quote && quote.b ? quote.b : quote) : 0,
			maxValue: orderType === 'SELL' ? 10000 : quote && quote.a ? quote.a : quote,
			stepAmount: stepAmount,
		};
	}
	if (tradeMeasure === 'takeProfit') {
		return {
			minValue: orderType === 'SELL' ? 0 : quote && quote.a ? quote.a : quote,
			maxValue: orderType === 'SELL' ? (quote && quote.b ? quote.b : quote) : 10000,
			stepAmount: stepAmount,
		};
	}

	if ((orderType === 'BUY' && typeOfOrder === 'limit') || (orderType === 'SELL' && typeOfOrder === 'stop')) {
		return {
			minValue: 0,
			maxValue: quote && quote.a ? quote.a : quote,
			stepAmount: stepAmount,
		};
	}

	if ((orderType === 'BUY' && typeOfOrder === 'stop') || (orderType === 'SELL' && typeOfOrder === 'limit')) {
		return {
			minValue: quote && quote.b ? quote.b : quote,
			maxValue: 10000,
			stepAmount: stepAmount,
		};
	}

	return {
		minValue: orderType === 'SELL' ? (quote && quote.b ? quote.b : quote) : 0,
		maxValue: orderType === 'SELL' ? 10000 : quote && quote.a ? quote.a : quote,
		stepAmount: stepAmount,
	};
};

/* Get trading Instruments details */

export const getInstrumentDetails = (
	detailedInformation: TradingInstruments[],
	selectedTradingAccount: TradingAccount[] | TradingAccount | undefined,
	symbol: string
) => {
	let tradingAccount: TradingAccount[] = [];

	if (selectedTradingAccount) {
		if (Array.isArray(selectedTradingAccount)) {
			tradingAccount = selectedTradingAccount;
		} else {
			tradingAccount = [selectedTradingAccount];
		}
	}

	const information = detailedInformation.find((value) => value.account === tradingAccount[0]?.id);
	if (information && information.instruments) {
		return information.instruments[symbol];
	}
};

export const getTradingInstrument = (tradingAccount: TradingAccount, symbol: string) => {
	const tradingInstruments = tradingAccount.tradingInstruments;
	if (tradingInstruments && tradingInstruments.instruments) {
		return tradingInstruments.instruments[symbol];
	}
};

export const getMarketItem = (feedId: string, code: string, rfpGateway: Optional<RfpGateway> = undefined) => {
	const _rfpGateway = rfpGateway ?? Resolver.resolve(RfpGateway);
	if (_rfpGateway) {
		return _rfpGateway.getMarketItem(code, feedId);
	}
};

/* Convert Amount to Lot  */
export const convertAmountToLot = (amount: number, roundLot: number) => amount / roundLot;

/* For JPY alone we need to convert the qty */
export const isForexJPY = (isForexJPY: string) => {
	if (isForexJPY === 'JPY') {
		return true;
	}
	return false;
};

export const getMarketItemTickSize = (marketItem: MarketItem) => {
	const tickSize = marketItem.marketItemInfo?.tickSize;
	return tickSize ? +tickSize : marketItem.tickSize;
};

export const getMarketItemPipSize = (marketItem: MarketItem | undefined | null) => {
	if (marketItem) {
		const pipSize = marketItem.marketItemInfo?.pipSize;
		return pipSize ? +pipSize : Math.min(1, getMarketItemTickSize(marketItem) * 10);
	}
	return NaN;
};

export const calcPipCost = (
	amount: number,
	marketItem: MarketItem,
	tradingAccount: TradingAccount,
	rfpGateway: Optional<RfpGateway>
) => {
	const priceQuote = getCurrentPrice(marketItem.feedId, marketItem.code, rfpGateway);
	const lastBid = priceQuote ? priceQuote.b : 0;
	const onePip = getMarketItemPipSize(marketItem);
	const pipCost = preCalcPL(
		marketItem,
		amount,
		lastBid,
		lastBid + onePip,
		tradingAccount.baseCurrency,
		'BUY',
		tradingAccount,
		rfpGateway
	);
	return pipCost;
};

export const calculateMarginImpact = (
	price: number,
	amount: number,
	marketItem: MarketItem,
	tradingAccount: TradingAccount,
	orderType: 'BUY' | 'SELL',
	rfpGateway: Optional<RfpGateway>
) => {
	if (isNaN(price)) {
		return NaN;
	}

	let marginRequirement = 0;
	let marginImpact: number = 0;

	if (tradingAccount.tradingInstruments) {
		const instrument = tradingAccount.tradingInstruments.instruments[marketItem.code];
		marginRequirement = instrument && instrument.marginReq ? instrument.marginReq : 0;
	}

	marginImpact = getMarginImpact(marginRequirement, amount, price);

	return convertToBaseCurrency(
		tradingAccount,
		marginImpact,
		price,
		marketItem.qCcy,
		marketItem.feedId,
		marketItem.code,
		orderType === 'BUY',
		rfpGateway
	);
};

/* Convert Lot to Amount Required when placing orders when Lot is selected */
export const convertLotToAmount = (lot: number, roundLot: number) => (lot ? lot * roundLot : 0);

export enum MarketItemType {
	FOREX,
	SPOT,
	CFD,
	FUTURE,
	INDEX,
	COMMODITY,
	OPTION,
	NDF,
	STOCK,
	CFD_INDEX,
	CRYPTO,
}

let FLAG_TYPE_BASE = 256;

export const isMarketItemType = (marketItem: MarketItem, type: MarketItemType) =>
	(marketItem.flags && FLAG_TYPE_BASE << type) !== 0;

export const findCommonMarketItem = (fSymbol: string, sSymbol: string, rfpGateway: Optional<RfpGateway>) => {
	const _rfpGateway = rfpGateway ?? Resolver.resolve(RfpGateway);
	if (_rfpGateway) {
		let first: any = [];
		let second: any = [];

		_rfpGateway.mapMarketItems.forEach((item: MarketItem) => {
			if (item.grp === 'Forex' || item.grp === 'Crypto') {
				if (item.code.includes(fSymbol)) {
					first.push(item);
				}

				if (item.code.includes(sSymbol)) {
					second.push(item);
				}
			}
		});
		let res: any = null;
		first.forEach((mt1: any) => {
			if (!res) {
				let base = mt1.bCcy;
				let quoteCode = mt1.qCcy;
				let code = fSymbol === base ? quoteCode : base;

				second.forEach((mt2: any) => {
					if (!res) {
						if (mt2.code.includes(code)) {
							res = {
								first: mt1,
								second: mt2,
							};
						}
					}
				});
			}
		});

		return res;
	}
};

export const convertToBaseCurrency = (
	tradingAccount: TradingAccount,
	pl: number,
	closePrice: number,
	quoteCurrency: string,
	feedId: string,
	symbol: string,
	isBuy: boolean,
	rfpGateway: Optional<RfpGateway>
) => {
	if (tradingAccount.accountMarketType === AccountMarketType.SpreadBetting) {
		return pl;
	}

	if (rfpGateway) {
		let mt = rfpGateway.getMarketItem(symbol, feedId);
		if (mt === undefined) {
			return NaN;
		}

		let baseAccountCurrency = tradingAccount.baseCurrency;

		if (quoteCurrency === baseAccountCurrency) {
			return pl;
		}

		let pair = quoteCurrency + baseAccountCurrency;
		let inverse = false;
		let homeCurrencyConversionRate;

		let info = getTradingInstrument(tradingAccount, pair);

		if (!info) {
			pair = baseAccountCurrency + quoteCurrency;
			info = getTradingInstrument(tradingAccount, pair);
			inverse = true;
		}

		let lastQuoteBaseCcy;
		if (info) {
			lastQuoteBaseCcy = rfpGateway.getQuotePrices(feedId, info.code);
		}

		//no luck...keep digging for a path
		if (!lastQuoteBaseCcy) {
			inverse = false;
			pl = pl / closePrice;

			pair = mt.bCcy + baseAccountCurrency;
			info = getTradingInstrument(tradingAccount, pair);

			if (!info) {
				pair = baseAccountCurrency + mt.bCcy;
				info = getTradingInstrument(tradingAccount, pair);
				inverse = true;
			}

			if (!info) {
				let mtPair: any = findCommonMarketItem(quoteCurrency, baseAccountCurrency, rfpGateway);
				if (mtPair) {
					pl = pl * closePrice;
					lastQuoteBaseCcy = rfpGateway.getQuotePrices(feedId, mtPair.first.code);

					if (lastQuoteBaseCcy) {
						inverse = !(mtPair.first.bCcy === quoteCurrency);
						if (inverse) {
							homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.b : lastQuoteBaseCcy.a;
							pl = pl / homeCurrencyConversionRate;
						} else {
							homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.a : lastQuoteBaseCcy.b;
							pl = pl * homeCurrencyConversionRate;
						}
						info = getTradingInstrument(tradingAccount, mtPair.second.code);
						inverse = mtPair.second.bCcy === baseAccountCurrency;
					} else {
						return NaN;
					}
				} else {
					return NaN;
				}
			}

			if (!info) {
				return NaN;
			}

			lastQuoteBaseCcy = rfpGateway.getQuotePrices(feedId, info.code);

			if (lastQuoteBaseCcy) {
				if (inverse) {
					homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.b : lastQuoteBaseCcy.a;
					pl = pl / homeCurrencyConversionRate;
				} else {
					homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.a : lastQuoteBaseCcy.b;
					pl = pl * homeCurrencyConversionRate;
				}
			} else {
				return NaN;
			}

			return pl;
		}

		if (inverse) {
			homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.b : lastQuoteBaseCcy.a;
			pl = pl / homeCurrencyConversionRate;
		} else {
			homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.a : lastQuoteBaseCcy.b;
			pl = pl * homeCurrencyConversionRate;
		}

		return pl;
	}
	return NaN;
};

const getTimezoneOffset = (timeZone: string, date: Date) => {
	try {
		const str = date.toLocaleString('en', { timeZone: timeZone, timeZoneName: 'longOffset' } as any);
		const [, h, m] = str.match(/([+-]\d+):(\d+)$/) || ['+00', '00'];
		const hour = +h;
		const min = +m;
		const res = hour * 60 + (hour > 0 ? min : -min);
		return isNaN(res) ? -4 : res;
	} catch (e) {
		console.warn(e);
		return -4;
	}
};

// Get NY timezone offset in hours
function getNYTimezoneOffset() {
	let executed = false;
	const offset = getTimezoneOffset('America/New_York', new Date()) / 60;
	return function () {
		if (!executed) {
			executed = true;
		}
		return offset;
	};
}

const getEstOffset = getNYTimezoneOffset();

const addZero = (val: number) => {
	return val < 10 ? '0' + val : val;
};

const getLastSunday = () => {
	const currentDate = new Date();
	const currentDay = currentDate.getDay();
	const offsetToLastSunday = (currentDay + 7) % 7;
	const lastSundayDate = new Date(currentDate);
	lastSundayDate.setDate(currentDate.getDate() - offsetToLastSunday);
	return lastSundayDate;
};

const updateDateWithHoursAndMinutes = (
	date: Date,
	hours: number,
	minutes: number,
	utcTimeOffset: number,
	daysOffset: number
) => {
	const newDate = new Date(date);
	newDate.setDate(newDate.getDate() + daysOffset);
	newDate.setHours(hours - (utcTimeOffset + date.getTimezoneOffset() / 60));
	newDate.setMinutes(minutes);
	newDate.setSeconds(0);
	return newDate;
};

export const convertTradingSessions = (info: RFPSessionTimes) => {
	//TradingSessions
	//'17:03-24:00,00:00-24:00,00:00-24:00,00:00-24:00,00:00-24:00,00:00-16:55,0'
	const sessions = info.trading.split(',').map((session) => {
		if (session === '0') {
			return [
				{
					start: '',
					end: '',
				},
			];
		}

		const times = session.split(' ');
		return times.map((time) => {
			const [start, end] = time.split('-');
			return {
				start,
				end,
			};
		});
	});

	if (sessions.length === 7) {
		const tradeSessions = {} as TradingSessions;
		tradeSessions.timezone = info.serverTimeZone;
		tradeSessions.sessions = [[], [], [], [], [], [], []] as TradingSession[][];

		tradeSessions.utcTimeOffset = getEstOffset();

		const lastSunday = getLastSunday();

		//day: number]: TradingSession[]; // 0 - Sunday, 1 - Monday, 2 - Tuesday, 3 - Wednesday, 4 - Thursday, 5 - Friday, 6 - Saturday
		sessions.forEach((session, index) => {
			if (session) {
				session.forEach((time) => {
					const { start, end } = time;
					if (!(start.length === 0 || end.length === 0)) {
						const [startHour, startMinute] = start.split(':').map((val) => parseInt(val));
						const [endHour, endMinute] = end.split(':').map((val) => parseInt(val));

						const openDate = updateDateWithHoursAndMinutes(
							lastSunday,
							startHour,
							startMinute,
							tradeSessions.utcTimeOffset,
							index
						);
						const closeDate = updateDateWithHoursAndMinutes(
							lastSunday,
							endHour,
							endMinute,
							tradeSessions.utcTimeOffset,
							index
						);

						const openDayOfWeek = openDate.getDay() as DayOfWeek;
						const closeDayOfWeek = closeDate.getDay() as DayOfWeek;
						if (openDayOfWeek !== closeDayOfWeek) {
							let start = getHourAndMinutes(openDate);
							const ts1 = {
								isOpen: true,
								day: openDayOfWeek,
								openDate: openDate,
								closeDate: new Date(openDate.getFullYear(), openDate.getMonth(), openDate.getDate() + 1, 0, 0, 0),
								schedule: { start: start, end: '24:00' },
							} as TradingSession;
							pushTradingSession(tradeSessions, openDayOfWeek, ts1);

							if (!(closeDate.getHours() === 0 && closeDate.getMinutes() === 0)) {
								const ts2 = {
									isOpen: true,
									day: closeDayOfWeek,
									openDate: ts1.closeDate,
									closeDate: closeDate,
									schedule: {
										start: getHourAndMinutes(ts1.closeDate),
										end: getHourAndMinutes(closeDate),
									},
								} as TradingSession;
								pushTradingSession(tradeSessions, closeDayOfWeek, ts2);
							}
						} else {
							let start = getHourAndMinutes(openDate);
							let end = getHourAndMinutes(closeDate);
							if (end === '00:00') {
								end = '24:00';
							}
							const ts = {
								isOpen: true,
								day: openDayOfWeek,
								openDate: openDate,
								closeDate: closeDate,
								schedule: { start, end },
							} as TradingSession;

							pushTradingSession(tradeSessions, openDayOfWeek, ts);
						}
					}
				});
			}
		});

		applyEmptySessions(tradeSessions);
		return tradeSessions;
	}
	return undefined;
};

const getHourAndMinutes = (date: Date) => {
	return `${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
};

const applyEmptySessions = (tradeSessions: TradingSessions) => {
	for (let i = 0; i < tradeSessions.sessions.length; i++) {
		if (tradeSessions.sessions[i] && tradeSessions.sessions[i].length === 0) {
			addEmptySession(tradeSessions, i as DayOfWeek);
		}
	}
};

const addEmptySession = (tradeSessions: TradingSessions, dayOfWeek: DayOfWeek) => {
	const emptyTimeSession = {
		isOpen: false,
		day: dayOfWeek,
		schedule: { start: '', end: '' },
	} as TradingSession;

	tradeSessions.sessions[dayOfWeek].push(emptyTimeSession);
};

const pushTradingSession = (tradeSessions: TradingSessions, dayOfWeek: DayOfWeek, tradingSession: TradingSession) => {
	if (tradeSessions.sessions[dayOfWeek]) {
		// check if previous session close time is equal to current session open time
		if (tradeSessions.sessions[dayOfWeek].length > 0) {
			const prevSession = tradeSessions.sessions[dayOfWeek][tradeSessions.sessions[dayOfWeek].length - 1];
			if (prevSession.closeDate && tradingSession.openDate) {
				if (prevSession.schedule.start === tradingSession.schedule.end) {
					// Check if close date is older than open date
					if (prevSession.closeDate.getTime() < tradingSession.openDate.getTime()) {
						// set only hours and minutes
						prevSession.openDate.setHours(tradingSession.openDate.getHours());
						prevSession.openDate.setMinutes(tradingSession.openDate.getMinutes());
					} else {
						prevSession.openDate = tradingSession.openDate;
					}
					prevSession.schedule.start = tradingSession.schedule.start;
					return;
				}

				if (
					prevSession.closeDate.getHours() === tradingSession.openDate.getHours() &&
					prevSession.closeDate.getMinutes() === tradingSession.openDate.getMinutes()
				) {
					prevSession.closeDate = tradingSession.closeDate;
					prevSession.schedule.end = tradingSession.schedule.end;
					return;
				}
			}
		}
		tradeSessions.sessions[dayOfWeek].push(tradingSession);
	} else {
		tradeSessions.sessions[dayOfWeek] = [tradingSession];
	}
};

const getCurrentPriceKey = (feedId: string, code: string) => {
	return `${feedId}-${code}`;
};

export const getCurrentPrice = (feedId: string, code: string, rfpGateway: Optional<RfpGateway>) => {
	const _rfpGateway = rfpGateway ?? Resolver.resolve(RfpGateway);
	if (_rfpGateway) {
		const key = getCurrentPriceKey(feedId, code);
		return _rfpGateway.mapQuotesPrices.get(key);
	}

	return undefined;
};

const calcPipsAtPrice = (marketItem: MarketItem, price: number) => price * (1 / getMarketItemPipSize(marketItem));

export const getPositionPipsChange = (position: TradingPosition) => {
	const marketItem = position.marketItem;
	if (marketItem) {
		let openPrice = position.oP || 0;
		let closePrice: number | undefined = position.cP ?? 0;
		if (closePrice === 0) {
			closePrice = position.currentPrice;
		}

		if (closePrice) {
			if (closePrice === 0) {
				return 0;
			}

			const isBuy = position.side === 'BUY';
			const mul = isBuy ? 1 : -1;
			return calcPipsAtPrice(marketItem, closePrice - openPrice) * mul;
		}
	}
	return 0;
};

export const updatePosition = (
	account: TradingAccount,
	position: TradingPosition,
	rfpGateway: Optional<RfpGateway>
) => {
	if (position.state === TradingPositionState.open || position.state === TradingPositionState.pending) {
		let profit = calcProfit(account, position, rfpGateway);
		position.grossProfit = account.isJapanAccount ? Math.floor(profit) : profit;
		position.netProfit = position.grossProfit + (position.comm || 0) + (position.swap || 0);

		if (isNaN(profit)) {
			return;
		}
	}

	let netPL = 0.0;
	let grPL = 0.0;

	for (const posId in account.activePositions) {
		const pos = account.activePositions[posId];
		if (pos.grossProfit && !isNaN(pos.grossProfit)) {
			grPL += pos.grossProfit;
		}

		if (pos.netProfit && !isNaN(pos.netProfit)) {
			netPL += pos.netProfit;
		}
	}

	account.grossProfit = grPL;
	account.netProfit = netPL;
};

export const calcProfit = (account: TradingAccount, position: TradingPosition, rfpGateway: Optional<RfpGateway>) => {
	if (position.state !== 'OPEN') {
		return 0;
	}

	const tradingInstrument = getTradingInstrument(account, position.code);
	const marketItem = position.marketItem;
	const quantity = position.qty;

	if (!marketItem || !quantity || !tradingInstrument) {
		return NaN;
	}

	let openPrice = position.oP;
	if (!openPrice || openPrice === 0) {
		return NaN;
	}

	let closePrice = position.cP;
	if (!closePrice || closePrice === 0) {
		closePrice = position.currentPrice;
	}

	if (!closePrice || closePrice === 0) {
		return NaN;
	}

	const decimal = position.dec ?? -1;
	const isBuy = position.side === 'BUY';
	const mul = isBuy ? 1 : -1;
	let pips = 0.0;
	if (decimal !== -1) {
		const price = closePrice - openPrice;
		pips = calcPipsAtPrice(marketItem, price) * mul;
	}

	position.pips = pips;

	let basePositionCurrency = position.marketItem?.qCcy;
	if (!basePositionCurrency) {
		return NaN;
	}

	let baseAccountCurrency = account.baseCurrency;
	if (!baseAccountCurrency) {
		return NaN;
	}

	let pl: number | null = quantity * (closePrice - openPrice) * mul;

	if (
		isMarketItemType(marketItem, MarketItemType.FUTURE) &&
		tradingInstrument.tickValue &&
		tradingInstrument.tickSize
	) {
		pl *= tradingInstrument.tickValue / tradingInstrument.tickSize;
	}

	if (basePositionCurrency !== baseAccountCurrency) {
		pl = convertToBaseCurrency(
			account,
			pl,
			closePrice,
			basePositionCurrency,
			marketItem.feedId,
			marketItem.code,
			isBuy,
			rfpGateway
		);
	}

	return pl;
};

/* Precalculate the Take Profit and Stop Loss values when order modal opens */

export const preCalcPL = (
	marketItem: MarketItem | undefined,
	quantity: number | undefined,
	lastBid: any,
	currentProfitOrStopPrice: any,
	baseAccountCurrency: string,
	orderType: any,
	tradingAccount: TradingAccount | undefined,
	rfpGateway: Optional<RfpGateway>
) => {
	if (!marketItem || !quantity) {
		return NaN;
	}

	if (quantity === 0) {
		return 1;
	}
	let pl = 0;
	pl = quantity * (currentProfitOrStopPrice - lastBid) * (orderType === 'BUY' ? 1 : -1);
	const quoteCurrency = marketItem.qCcy;

	if (tradingAccount) {
		if (quoteCurrency !== baseAccountCurrency) {
			pl = convertToBaseCurrency(
				tradingAccount,
				pl,
				lastBid,
				quoteCurrency,
				marketItem.feedId,
				marketItem.code,
				orderType === 'BUY',
				rfpGateway
			);
		}

		if (tradingAccount.isJapanAccount) {
			return pl ? Math.floor(pl).toFixed(0) : 0;
		}
	}
	return pl ? pl.toFixed(2) : 0;
};

/* Increment factor for the lot or amount input field */
const progression = [1, 2, 3.0, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 100000];

/* Set increment values for the Type Amount */
export const fillQuantity = (
	marketItem: MarketItem,
	tradingAccount: Optional<TradingAccount>,
	detailedInformation?: TradingInstruments[]
) => {
	const collection = [];
	let instrument;
	if (tradingAccount) {
		instrument = tradingAccount.tradingInstruments?.instruments[marketItem.code];
	} else if (!tradingAccount && detailedInformation) {
		instrument = detailedInformation[0]?.instruments[marketItem.code];
	}

	if (instrument) {
		let min = -1;
		let max = 1.7976931348623157e308 / 2;

		const decPrec = marketItem.decPrec;

		min = convertLotToAmount(instrument.minL, instrument.rndLot);
		max = convertLotToAmount(instrument.maxL, instrument.rndLot);

		if (min < 0) {
			min = getDefaultAmount(instrument);
		}

		for (let i = 0; i < progression.length; i++) {
			let minimumLot = min;
			let qty = progression[i] * minimumLot;
			if (qty > max + 0.000001) break;
			if (qty === 0.3) {
				qty = parseFloat((0.3).toFixed(10));
			}
			const decLength = qty.toString().split('.')[1]?.length;
			collection.push(decLength > decPrec ? Number(qty.toFixed(decPrec)) : qty);
		}
	}
	return collection;
};

export const getDefaultAmount = (instrument: Instrument) => {
	return instrument.grp === 'Forex' ? 1000 : convertLotToAmount(instrument.minL, instrument.rndLot);
};

/* Set increment values for the Type LOT */
export const getAllLotsAtAccount = (
	marketItem: MarketItem,
	tradingAccount: Optional<TradingAccount>,
	detailedInformation?: TradingInstruments[]
) => {
	const amounts = fillQuantity(marketItem, tradingAccount, detailedInformation);
	const collection: number[] = [];

	let instrument: Instrument | undefined;
	if (tradingAccount) {
		instrument = tradingAccount.tradingInstruments?.instruments[marketItem.code];
	} else if (!tradingAccount && detailedInformation) {
		instrument = detailedInformation[0]?.instruments[marketItem.code];
	}

	if (instrument) {
		amounts.forEach((value) => {
			collection.push(convertAmountToLot(value, instrument!.rndLot));
		});
	}

	return collection;
};

const currenciesMap: { [key: string]: string } = {
	EUR: '€',
	USD: '$',
	JPY: '¥',
	NZD: '$',
	AUD: '$',
	ZAR: 'R',
	CHF: '₣',
	GBP: '£',
};

export const defineBaseCurrencySymbol = (tradingAccount: TradingAccount | null | undefined) =>
	!tradingAccount?.baseCurrency ? '' : currenciesMap[tradingAccount?.baseCurrency] || tradingAccount?.baseCurrency;

const formatter = ({
	language,
	isJapanAccount,
	value,
	symbol,
}: {
	language: string;
	isJapanAccount: boolean;
	value: number;
	symbol: string;
}) => {
	const isArabic = language === 'ar-SA';
	const decPrec = isJapanAccount ? 0 : 2;

	if (isArabic) {
		return value < 0
			? `${formatNumberWithCommas(Math.abs(value), decPrec, language)} ${symbol} -`
			: `${formatNumberWithCommas(value, decPrec, language)} ${symbol}`;
	} else {
		return value < 0
			? `- ${symbol} ${formatNumberWithCommas(Math.abs(value), decPrec, language)}`
			: `${symbol} ${formatNumberWithCommas(value, decPrec, language)}`;
	}
};

export function formatNumberAsMoney(
	currency: string,
	num: string | number | undefined,
	language: string,
	isJapanAccount: boolean = false
) {
	num = parseFloat(num!.toString());
	const value = num ?? 0;
	if (!isNaN(value)) {
		const symbol = currenciesMap[currency];
		return formatter({ language, isJapanAccount, value, symbol });
	}
	return '';
}

export function formatAsMoney(
	tradingAccounts: TradingAccount[],
	selectedTradingAccountNumber: any,
	num: string | number | undefined,
	language: string
) {
	num = parseFloat(num!.toString());
	const value = num ?? 0;
	if (!isNaN(value)) {
		const selectedTradingAccount = tradingAccounts.find((item: any) => item.id === selectedTradingAccountNumber);
		const symbol = defineBaseCurrencySymbol(selectedTradingAccount);
		const isJapanAccount = !!selectedTradingAccount?.isJapanAccount;

		return formatter({ language, isJapanAccount, value, symbol });
	}
	return '';
}

export const getMarginCalculationType = (tradingAccount: TradingAccount | undefined): number => {
	if (tradingAccount && tradingAccount.marginCalculationType) {
		return tradingAccount.marginCalculationType > 0 ? tradingAccount.marginCalculationType - 1 : 0;
	}
	return 0;
};

/**
 * Calculates the effective (net) size of a instrument within the open positions based on margin calculation type.
 * @param {number} marginAccountType The flag which describes the account level setting for margin calculations (0: Hedged, 1: unhedged, 2: Japan)
 * @param {number} code The instrument for which effective size is to be calculated
 * @param {number} accountId ID of account currently in use
 * @param {number} openPositions An array of open trading positions for a given account
 * @return {TEffectiveSize} Object containing net value and majority side for given instrument positions
 */
export const getEffectiveSize = (
	marginAccountType: MarginAccountType,
	code: string,
	accountId: number,
	openPositions: TradingPosition[]
): TEffectiveSize => {
	let majoritySide = '';
	let totalBuy = 0;
	let totalSell = 0;

	openPositions
		.filter((position) => {
			return position.code === code && position.aId === accountId;
		})
		.forEach((position) => {
			if (position.side === 'BUY') {
				totalBuy += position.qty!;
			} else if (position.side === 'SELL') {
				totalSell += position.qty!;
			}
		});

	if (totalBuy > totalSell) {
		majoritySide = 'BUY';
	} else if (totalSell > totalBuy) {
		majoritySide = 'SELL';
	} else {
		majoritySide = 'SQUARE';
	}

	switch (marginAccountType) {
		case MarginAccountType.Hedged:
			return { val: Math.abs(totalBuy - Math.abs(totalSell)), majoritySide: majoritySide };

		case MarginAccountType.Unhedged:
			return { val: totalBuy + Math.abs(totalSell), majoritySide: majoritySide };

		case MarginAccountType.Japan:
			return { val: totalBuy > totalSell ? totalBuy : Math.abs(totalSell), majoritySide: majoritySide }; //To Do: Account for pending orders when id === 2 (Japan)

		default:
			return { val: 0, majoritySide: '' };
	}
};

/**
 * Calculates the size of the position after hedging it against other opened positions of the same instrument
 * @param {number} orderType The type of order (BUY or SELL)
 * @param {TEffectiveSize} effectiveSize Effective size of the instrument based on open positions
 * @param {number} numberInputQuantity Value in the number input for trade size
 * @return {number}  Returns hedged trade quantity to be used in margin impact calculation
 */
export const getHedgedTradeQuantity = (
	effectiveSize: TEffectiveSize,
	numberInputQuantity: number | string,
	orderType?: string
): { value: number; isSquare: boolean } => {
	if (effectiveSize.majoritySide === 'SQUARE') {
		return { value: +numberInputQuantity, isSquare: true };
	} else if (orderType !== effectiveSize.majoritySide && +numberInputQuantity <= +effectiveSize.val) {
		return { value: 0, isSquare: false };
	} else if (orderType !== effectiveSize.majoritySide && +numberInputQuantity > effectiveSize.val) {
		return { value: Math.abs(effectiveSize.val - +numberInputQuantity), isSquare: false };
	} else if (
		(effectiveSize.majoritySide === 'BUY' && orderType === 'BUY') ||
		(effectiveSize.majoritySide === 'SELL' && orderType === 'SELL')
	) {
		return { value: +numberInputQuantity, isSquare: false };
	} else if (
		(effectiveSize.majoritySide === 'BUY' && orderType === 'SELL') ||
		(effectiveSize.majoritySide === 'SELL' && orderType === 'BUY')
	) {
		return { value: +numberInputQuantity, isSquare: false };
	} else {
		return { value: 0, isSquare: false };
	}
};

/**
 * Calculates the margin impact of a particular trade based on size, instrument level setting and current price
 * @param {number} instrumentMarginReq The margin requirement found on the instrument market item details
 * @param {number} sizeOfPosition Size of the position
 * @param {number} currentPrice current price of the instrument
 * @return {number}  Returns calculated margin impact, not converted to base currency
 */
export const getMarginImpact = (instrumentMarginReq: number, sizeOfPosition: number, currentPrice: any): number =>
	((instrumentMarginReq * sizeOfPosition) / 100) * currentPrice;

export const calcPercentChange = (position: TradingPosition) =>
	calcPercentChangeAtParams(position.oP, position.currentPrice, position.side === 'BUY');

export const calcPercentChangeAtParams = (
	openPrice: number | undefined,
	closePrice: number | undefined,
	isBuy: boolean
) => {
	const op = openPrice ?? NaN;
	const currentPrice = closePrice ?? NaN;
	return ((currentPrice - op) / op) * 100 * (isBuy ? 1 : -1);
};

export const updateCurrentPrice = (position: TradingPosition, priceQuote: PriceQuote) => {
	if (position.code === priceQuote.c) {
		const isBuy = position.side === 'BUY';
		const isPending = position.state === TradingPositionState.pending;
		if (isBuy) {
			position.currentPrice = isPending ? priceQuote.a : priceQuote.b;
		} else {
			position.currentPrice = isPending ? priceQuote.b : priceQuote.a;
		}
	}
};

export const getAdditionalSubscriptionPairs = (
	tradingAccount: TradingAccount,
	marketItem: MarketItem,
	rfpGateway: Optional<RfpGateway>
) => {
	let additionalSubscriptionPair: Set<string> = new Set<string>();
	const baseCcyPos = marketItem.qCcy;
	if (!baseCcyPos) {
		return additionalSubscriptionPair;
	}

	let baseCcyAcct = tradingAccount.baseCurrency;
	if (!baseCcyAcct) {
		return additionalSubscriptionPair;
	}

	if (baseCcyPos !== baseCcyAcct) {
		let pair = baseCcyPos + baseCcyAcct;

		let info = getTradingInstrument(tradingAccount, pair);
		if (!info) {
			pair = baseCcyAcct + baseCcyPos;
			info = getTradingInstrument(tradingAccount, pair);
		}

		if (!info) {
			pair = baseCcyAcct + marketItem.bCcy;

			info = getTradingInstrument(tradingAccount, pair);
			if (!info) {
				pair = marketItem.bCcy + baseCcyAcct;
				info = getTradingInstrument(tradingAccount, pair);
			}
		}

		if (!info) {
			// Find cross symbol
			let mtPair: any = findCommonMarketItem(baseCcyPos, baseCcyAcct, rfpGateway);
			if (mtPair) {
				pair = mtPair.first.code;

				if (pair) {
					additionalSubscriptionPair.add(pair);
				}
				info = getTradingInstrument(tradingAccount, mtPair.second.code);
			} else {
				return;
			}
		}

		if (info) {
			pair = info.code;

			additionalSubscriptionPair.add(pair);
		}
	}
	return additionalSubscriptionPair;
};

// Calculations migrated from AccountPanel
export const calculateEquity = ({ balance, profitLoss }: { balance: number; profitLoss: number }): number =>
	+balance + profitLoss;
export const calculateFreeMargin = ({
	equity,
	accountMcLevel,
	usedMargin,
}: {
	equity: number;
	accountMcLevel: number;
	usedMargin: number;
}): number => Math.max(0, equity / accountMcLevel - usedMargin);
export const calculateMarginLevel = ({ equity, usedMargin }: { equity: number; usedMargin: number }): number =>
	usedMargin === 0 ? 0 : (equity / usedMargin) * 100;

export const SBCLeaner = (name: string) => {
	let result = name ?? '';
	if (result.endsWith('_SB')) {
		result = result.replace('_SB', '');
	}
	if (result.endsWith('SB')) {
		result = result.replace('SB', '');
	}

	return result;
};

export const getLanguageToUse = (lang: string) => {
	if (lang) {
		return lang === 'zh-Hans' || lang === 'zh-Hant' ? 'zh-cn' : lang === 'ar-SA' ? 'en' : lang.toLowerCase();
	}

	// if no language is provided, default to english
	return 'en';
};
