import React, { useContext, useEffect, useRef, useState, useMemo, FC } from 'react';

import cn from 'classnames';
import { Radio } from 'semantic-ui-react';
import { Container, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placement } from 'react-bootstrap/esm/types';

import Loading from '../../../components/Loading/Loading';
import { default as Nullable } from '../../../../utils/functions/Nullable';
import uniqueId from '../../../../utils/functions/uniqueId';
import { MarketItem, SelectedSymbol } from '../../../../gateways/RfpGateway/rfp.types';
import isWindowStyles from '../../../../utils/functions/isWindowStyles';
import Routes, { DETACHED_SUB_ROUTES } from '../../../../setup/routes';
import Button from '../../../../shared/Button/Button';
import Modal from '../../../../shared/Modal/Modal';
import { default as InstrumentContext } from '../../../../contexts/InstrumentContext';
import useShortTranslation from '../../../../utils/hooks/useShortTranslation';

import { default as usePromiseFactory } from '../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../../utils/hooks/useForceRerender';
import { AccountMarketType } from '../../../../gateways/RfpGateway/rfp.types';
import { default as AppContext } from '../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../contexts/DashboardContext';
import { default as RfpGatewayContext } from '../../../../contexts/RfpGatewayContext';
import { default as ChartContext } from '../../../../contexts/ChartContext';
import {
	createNewWatchlist,
	DEFAULT_FEED_ID,
	instrumentExistsInDynamicWatchlists,
	MAX_INSTRUMENTS_ALLOWED,
	MAX_INSTRUMENTS_ALLOWED_JP,
	MAX_WATCHLISTS_ALLOWED,
	WATCHLIST_SORT_ORDER,
	WatchlistSortOrder,
} from '../../../../utils/functions/WatchlistUtils';
import { forceCloseModal } from '../../../../utils/hooks/useForceCloseModal';
import usePreferredFeedId from '../../../../utils/hooks/usePreferredFeedId';
import { sortSearchResults } from '../../../../utils/functions/sortSearchResults';
import { getSearchResults } from '../../../../utils/functions/getSearchResults';
import useGetTranslatedWLName from '../../../../utils/hooks/useGetTranslatedWLName';
import WtrPopup from '../../../components/WtrPopup/WtrPopup';
import useSaveWatchlistToPreferences from '../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';
import tradingAccountStore from '../../../../store/tradingAccountStore';
import authStore from '../../../../store/authStore';

import { DETACHED_WINDOW_TRADE } from '../../../../contexts/WindowContext';
import DetachIcon from '../../../components/DetachIcon/DetachIcon';
import watchListStore, { WatchListData } from '../../../../store/WatchListStore/watchListStore';
import { useDynamicWatchLists } from '../../../../utils/hooks/useDynamicWatchLists';
import useSaveUserPreferences from '../../../../utils/mutations/useSaveUserPreferences';
import StringUtils from '../../../../utils/functions/StringUtils';

import Instrument from './Instrument/Instrument';
import EmptySearch from './EmptySearch/EmptySearch';
import SearchResult from './SearchResult/SearchResult';
import WatchlistHeader from './WatchlistHeader/WatchlistHeader';

import styles from './WatchList.module.scss';
import DetachWatchList from './DetachWatchList/DetachWatchList';
import WatchlistsDropDown from './WatchlistsDropDown/WatchlistsDropDown';
import WatchlistEdit from './Lists/WatchlistEdit';
import { useUpdateSBWatchlistInstruments } from '../../../../utils/hooks/useUpdateSBWatchlistInstruments';

interface WatchlistProps {
	window1?: boolean;
	name?: string | null | undefined;
}

const Watchlist: FC<WatchlistProps> = ({ window1, name }) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const isChildWindow = appContext.isChildWindow || false;
	const getCurrentWatchList = watchListStore.use.getCurrentWatchList();
	const currentWatchList = getCurrentWatchList(isChildWindow);
	const setCurrentWatchList = watchListStore.use.setCurrentWatchList();

	const isFundedTrader = tradingAccountStore.use.isFundedTrader();

	const tradingMode = authStore.use.tradingMode();
	const chartContext = useContext(ChartContext);
	const instrumentContext = useContext(InstrumentContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);

	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();
	const getTranslatedWLName = useGetTranslatedWLName();
	const dynamicWatchLists = useDynamicWatchLists();

	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
	const updateSBWatchlistInstruments = useUpdateSBWatchlistInstruments();
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const theme = appContext.appTheme;
	const isArabic = appContext.isArabic;
    const isJapanAccount = appContext.isJapanAccount;

	const activeTradingAccount = useSelectedTradingAccount();
	const marketItems = dashboardContext.marketItems.filter((marketItem) =>
		isSpreadBettingAccount
			? marketItem.accountMarketType === AccountMarketType.SpreadBetting
			: marketItem.accountMarketType !== AccountMarketType.SpreadBetting
	);
	const accountType = dashboardContext.accountType;

	const [sortedInstruments, setSortedInstruments] = useState<string[]>([]);
	const [availableWatchlistsForMoveOperation, setAvailableWatchlistsForMoveOperation] = useState<WatchListData[]>([]);
	const storeWatchlists = watchListStore.use.watchlists();
	const getWatchlist = watchListStore.use.getWatchlist();
	const getWatchlists = watchListStore.use.getWatchlists();
	const watchlistsCount = watchListStore.use.watchlistsCount();
	const addInstruments = watchListStore.use.addInstruments();
	const removeInstruments = watchListStore.use.removeInstruments();
	const getWatchlistInstruments = watchListStore.use.getWatchlistInstruments();
	const renameWatchlist = watchListStore.use.renameWatchlist();
	const isWatchlistNameExists = watchListStore.use.isWatchlistNameExists();
	const moveInstruments = watchListStore.use.moveInstruments();
	const removeWatchlist = watchListStore.use.removeWatchlist();
	const removeAllInstruments = watchListStore.use.removeAllInstruments();
	const isInstrumentExistsInWatchlist = watchListStore.use.isInstrumentExistsInWatchlist();
	const allWatchlists = getWatchlists(tradingMode);
	const selectedInstrument = dashboardContext.selectedInstrument;
	const allWatchlistsCount = watchlistsCount(tradingMode);

	useObservable(appContext.getPropertyChangeStream('appTheme', 'userPreferences', 'email', 'isChildWindow'), () => {
		promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'accountType',
			'loadingState',
			'tradingAccount',
			'notification',
			'watchlist',
			'selectedSymbol',
			'tradingPositions',
			'showOrderInformation',
			'selectedInstrument',
			'defaultWatchLists',
			'showOrderTicket'
		),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	useObservable(dashboardContext.getPropertyChangeStream('marketItems'), () => {
		forceRerender();
	});

	name = name || 'default';

	const [isWatchlistSearchOpen, setIsWatchlistSearchOpen] = useState(false);
	const [showEmptySearchState, setShowEmptySearchState] = useState(false);
	const [searchResults, setSearchResults] = useState<MarketItem[]>([]);
	const [searchValue, setSearchValue] = useState('');
	const searchInputRef = useRef<HTMLInputElement | null>(null);
	const [createWatchList, setCreateWatchList] = useState(false);
	const [typedValue, setTypedValue] = useState('');
	const [editLayer, setEditLayer] = useState(false);
	const [removeItem, setRemoveItem] = useState<any>([]);
	const [removeAll, setRemoveAll] = useState(false);
	const [openModify, setOpenModify] = useState(false);
	const [watchlistName, setWatchlistName] = useState(currentWatchList ? currentWatchList : '');
	const [openMove, setOpenMove] = useState(false);
	const [selectedWatchlist, setSelectedWatchlist] = useState('');
	const [openRemoveModal, setOpenRemoveModal] = useState(false);
	const [openWatchlist, setOpenWatchlist] = useState(false);
	const [isDynamicWatchlist, setIsDynamicWatchlist] = useState(false);
	const [nameError, setNameError] = useState('');
	const [invalidRemoveName, setInvalidRemoveName] = useState('');
	const [maxWatchlistsError, setMaxWatchlistsError] = useState(false);
	const preferredFeedId = usePreferredFeedId(activeTradingAccount);

	const { t } = useTranslation();
	const tt = useShortTranslation('en:');
	const history = useHistory();
	const { mutate: saveUserPreferences } = useSaveUserPreferences();

	const FILTER_THROTTLE = 150;

	//Reset existing errors when input length is 0
	useEffect(() => {
		typedValue.length === 0 && setNameError('');
		watchlistName.length === 0 && setNameError('');
	}, [typedValue, watchlistName]);

	//Clear existing errors when modify screen is reopened
	useEffect(() => {
		nameError.length > 0 && setNameError('');
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openModify]);

	useEffect(() => {
		if (dashboardContext.defaultWatchLists.length > 0) {
			if (
				instrumentExistsInDynamicWatchlists(
					currentWatchList,
					dashboardContext.defaultWatchLists,
					isSpreadBettingAccount
				)
			) {
				setIsDynamicWatchlist(true);
			} else {
				setIsDynamicWatchlist(false);
			}
		}
	}, [dashboardContext.defaultWatchLists, currentWatchList, isSpreadBettingAccount]);

	//Reset select all option if not in watchlist editing mode
	useEffect(() => {
		!editLayer && setRemoveAll(false);
	}, [editLayer]);

	useEffect(() => {
		if (dashboardContext.defaultWatchLists.length > 0) {
			dynamicWatchLists(dashboardContext.watchlist, isFundedTrader, isSpreadBettingAccount);
		}
	}, [dashboardContext.defaultWatchLists, isFundedTrader]);

	useEffect(() => {
		// console.log(
		// 	`XXX allWatchlists ${
		// 		allWatchlists ? allWatchlists.length > 0 : null
		// 	}, currentWatchList ${currentWatchList} dashboardContext.marketItems ${dashboardContext.marketItems.length}`
		// );

		if (currentWatchList && allWatchlists && allWatchlists.length > 0) {
			const watchList = getWatchlist(tradingMode, currentWatchList);
			if (watchList) {
				if (isSpreadBettingAccount) {
					updateSBWatchlistInstruments(marketItems);
				}
				subscribeToNewInstruments(watchList.instruments);
				setSortedInstruments(sortWatchlistInstruments(watchList.instruments, watchList.sortOrder));
			}
		}
	}, [
		allWatchlists,
		tradingMode,
		currentWatchList,
		accountType,
		dashboardContext?.marketItems,
		rfpGatewayContext?.mapMarketItems.size,
	]);

	useEffect(() => {
		setOpenWatchlist(false);
	}, [selectedInstrument]);

	useEffect(() => {
		if (!currentWatchList) return;

		saveUserPreferences();
	}, [currentWatchList]);

	//added rfpGatewayContext?.mapMarketItems.size because in some cases we are checking for market item before we have any
	//elements in rfpGatewayContext?.mapMarketItems this is happening for cfd accounts.
	useEffect(() => {
		if (
			rfpGatewayContext?.mapMarketItems.size !== 0 &&
			(!selectedInstrument ||
				(isSpreadBettingAccount && selectedInstrument.accountMarketType !== AccountMarketType.SpreadBetting) ||
				(!isSpreadBettingAccount && selectedInstrument.accountMarketType === AccountMarketType.SpreadBetting))
		) {
			if (selectedInstrument) {
				let code = selectedInstrument.code;
				if (isSpreadBettingAccount && !code.endsWith('_SB')) {
					code = `${code}_SB`;
				}
				if (!isSpreadBettingAccount && code.endsWith('_SB')) {
					code = code.replace('_SB', '');
				}

				const marketItem = rfpGatewayContext?.getMarketItem(code);

				if (marketItem) {
					dashboardContext.selectedInstrument = marketItem;
					return;
				}
			}

                const initialChartSymbol = isSpreadBettingAccount
                    ? { code: 'GBPUSD_SB' }
                    : { code: isJapanAccount ? 'USDJPY' : 'GBPUSD' };
				const marketItem = rfpGatewayContext?.getMarketItem(initialChartSymbol.code, DEFAULT_FEED_ID);
				if (marketItem) {
					dashboardContext.selectedInstrument = marketItem;
				}
			}
		}, [isSpreadBettingAccount, dashboardContext?.marketItems, rfpGatewayContext?.mapMarketItems.size]);

	//Filter out invalid watchlist move options on selection of symbols during edit mode
	useEffect(() => {
		if (allWatchlists && removeItem.length > 0) {
			const maxInstruments = appContext.isJapanAccount ? MAX_INSTRUMENTS_ALLOWED_JP : MAX_INSTRUMENTS_ALLOWED;
			const availableWatchlistsForMoveOperation = allWatchlists.filter((watchlist) => {
				if (
					watchlist.name === currentWatchList ||
					instrumentExistsInDynamicWatchlists(
						watchlist.name,
						dashboardContext.defaultWatchLists,
						isSpreadBettingAccount
					)
				) {
					return false;
				}

				const isSomeOfTheElementsExistsInWatchlist = removeItem.some((element: any) => {
					return isInstrumentExistsInWatchlist(tradingMode, watchlist.name, element);
				});

				if (isSomeOfTheElementsExistsInWatchlist) return false;

				return watchlist.instruments.length + removeItem.length <= maxInstruments;
			});
			setAvailableWatchlistsForMoveOperation(availableWatchlistsForMoveOperation);
		}
	}, [removeItem]);

	const instrumentHandlers = useMemo(() => {
		return {
			onRemovalFromWatchlist: (instruments: string[]) => {
				removeInstruments(tradingMode, currentWatchList, instruments);
			},
			onChartClick: (marketItem: MarketItem, gridViewCharts?: boolean) => {
				if (!gridViewCharts) {
					if (isChildWindow) {
						return;
					}
					if (window.innerWidth < 1024) {
						history.push(Routes.trader.charts);
					}

					dashboardContext.historicalData = [];
					dashboardContext.selectedInstrument = marketItem;
					dashboardContext.selectedType = 'Instrument';
					const newChartId = uniqueId();
					const selectedSymbols: SelectedSymbol = { [newChartId]: marketItem };
					dashboardContext.selectedSymbol = selectedSymbols;
					dashboardContext.newChart = true;
					chartContext.setSingleActiveChart(newChartId);
				} else {
					dashboardContext.symbolChanged = marketItem.code;
					dashboardContext.gridChartsChanged = true;
					dashboardContext.selectedPosition = null;
					dashboardContext.selectedInstrument = marketItem;
					dashboardContext.selectedType = 'Instrument';
				}

				//update recently viewed instruments queue
				Nullable.of(instrumentContext.instruments.find((value) => value.code === marketItem.code)).run((instrument) => {
					instrumentContext.recentlyViewedInstruments.delete(instrument);
					instrumentContext.recentlyViewedInstruments.enqueue(instrument);
				});
			},
		};
	}, [accountType, rfpGatewayContext, activeTradingAccount, currentWatchList]);

	const subIdRef = useRef<string | undefined>(undefined);
	const subIdItemsRef = useRef<string[] | undefined>(undefined);

	const subscribeToNewInstruments = (instruments: string[]) => {
		if (rfpGatewayContext && marketItems.length > 0) {
			if (instruments) {
				// compare symbols to avoid unnecessary subscription
				if (subIdItemsRef.current && subIdItemsRef.current.length === instruments.length) {
					const isSame = subIdItemsRef.current.every((value, index) => value === instruments[index]);
					if (isSame) {
						return;
					}
				}

				// Unsubscribe from previous instruments
				unsubscribeInstruments();

				// subscribe for price quote
				const subId = rfpGatewayContext.subscribePriceQuote(DEFAULT_FEED_ID, instruments, (priceQuote) => {});
				// console.log(`XXX ✅︎ Subscribe instruments subId=${subId} symbols=${instruments}`);
				subIdRef.current = subId;
				subIdItemsRef.current = instruments;
			}
		}
	};

	const unsubscribeInstruments = () => {
		if (rfpGatewayContext && subIdRef.current) {
			// console.log(`XXX ❌ Unsubscribe instruments subId=${subIdRef.current}`);
			rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
			subIdRef.current = undefined;
			subIdItemsRef.current = undefined;
		}
	};

	const setCurrentItemWatchList = (item: any) => {
		if (storeWatchlists !== null) {
			setCurrentWatchList(item, isChildWindow);
			setWatchlistName(item);
			setOpenWatchlist(false);
		}
	};

	const changeValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setTypedValue(value);
		nameError.length > 0 && setNameError('');
	};

	const changeWatchlistName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setWatchlistName(value);
		if (nameError || invalidRemoveName) {
			setNameError('');
			setInvalidRemoveName('');
		}
	};

	const handleCreateWatchlist = () => {
		if (!maxWatchlistsError) {
			setTypedValue('');
			setCreateWatchList(true);
		}
	};

	const handleCreateWatchListClose = () => {
		setNameError('');
		setTypedValue('');
		setCreateWatchList(false);
	};

	const setEditWatchlist = () => {
		setOpenWatchlist(false);
		setEditLayer(true);
	};

	const resetEditLayer = () => {
		setEditLayer(false);
		if (sortedInstruments !== null) {
			sortedInstruments.forEach((_, index) => {
				const element = document.getElementById(`instrumentChecked_${index}`);
				if (element) {
					element.removeAttribute('checked');
				}
			});
		}
		setRemoveAll(false);
		setRemoveItem([]);
	};

	const verifyWatchlist = () => {
		if (storeWatchlists !== null) {
			const nameExists = isWatchlistNameExists(tradingMode, typedValue);
			const wlCount = watchlistsCount(tradingMode);
			const maxWatchlists = wlCount >= MAX_WATCHLISTS_ALLOWED;
			if (!nameExists && !maxWatchlists) {
				setNameError('');
				handleSaveWatchlist();
			} else if (nameExists) {
				setNameError(t('wtr:NAME_IN_USE'));
			}
		}
	};
	const handleSaveWatchlist = () => {
		const newWatchlistName = typedValue.trim();
		const feedId = StringUtils.isNullOrEmpty(preferredFeedId) ? DEFAULT_FEED_ID : preferredFeedId;
		const newWatchlistEntry = createNewWatchlist(newWatchlistName, feedId);
		dashboardContext.watchlist.push(newWatchlistEntry);
		setCurrentWatchList(newWatchlistName, isChildWindow);
		saveWatchlistToPreferences(dashboardContext.watchlist);
		setCreateWatchList(false);
		setOpenWatchlist(false);
	};

	const handleWatchListSelection = (event: any, instrument: string) => {
		const index = getWatchlistInstruments(tradingMode, currentWatchList).indexOf(instrument);
		if (index > -1) {
			const element = document.getElementById(`instrumentChecked_${index}`);
			if (element) {
				if ((element as any).checked) {
					if (Array.isArray(removeItem) && !removeItem.some((item) => item === instrument)) {
						setRemoveItem([...removeItem, instrument]);
					}
				} else if (!(element as any).checked && (event === null || event.target === null || !event.target.checked)) {
					const updatedRemoveItem = removeItem.filter((item: string) => item !== instrument);
					setRemoveAll(false);
					setRemoveItem(updatedRemoveItem);
					setRemoveItem(updatedRemoveItem);
					setRemoveItem(updatedRemoveItem);
				}
			}
		}
	};

	const handleSelectAllWatchListElements = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (sortedInstruments) {
			const isChecked = event.target.checked;
			sortedInstruments.forEach((_, index) => {
				const instrumentChecked = document.getElementById(`instrumentChecked_${index}`) as HTMLInputElement;
				if (instrumentChecked) {
					instrumentChecked.checked = isChecked;
				}
			});
			setRemoveAll(isChecked);
			setRemoveItem(isChecked ? [...sortedInstruments] : []);
		}
	};

	const handleRemoveItem = () => {
		setOpenRemoveModal(false);
		if (currentWatchList) {
			if (removeAll) {
				const originalList = dashboardContext.watchlist.find((watchlist) => watchlist._name === currentWatchList);
				if (originalList) {
					originalList.instrument = [];
				}
				removeAllInstruments(tradingMode, currentWatchList);
			} else {
				const watchlist = dashboardContext.watchlist.find((watchlist) => watchlist._name === currentWatchList);
				if (watchlist) {
					watchlist.instrument = watchlist.instrument.filter(
						(instrument) => !removeItem.some((item: any) => item === instrument._code)
					);
				}
				instrumentHandlers.onRemovalFromWatchlist(removeItem);
			}
			saveWatchlistToPreferences(dashboardContext.watchlist);
		}
		setRemoveItem([]);
		setRemoveAll(false);
		setEditLayer(false);
		forceCloseModal('fade modal');
	};

	const handleCancelRemove = () => {
		setOpenRemoveModal(false);
		forceCloseModal('fade modal');
	};

	useEffect(() => {
		const watchlist = getWatchlist(tradingMode, currentWatchList);
		if (watchlist) {
			setSortedInstruments(sortWatchlistInstruments(watchlist.instruments, watchlist.sortOrder));
		}
	}, [currentWatchList]);

	// useEffect(() => {
	// 	const watchlist = getSelectedWatchlist(tradingMode);
	// 	if (watchlist) {
	// 		setSortedInstruments(sortWatchlistInstruments(watchlist.instruments, watchlist.sortOrder));
	// 	}
	// }, [storeWatchlists]);

	const renameCurrentWatchlist = () => {
		if (allWatchlists) {
			const name = watchlistName.trim();
			const watchlistNameExists = isWatchlistNameExists(tradingMode, name);
			if (!watchlistNameExists) {
				const originalName = dashboardContext.watchlist.find((watchlist) => watchlist._name === currentWatchList);
				if (originalName) {
					originalName._name = name;
					originalName._id = name;
				}
				renameWatchlist(tradingMode, currentWatchList, name);
				setOpenModify(false);
				setCurrentWatchList(name, isChildWindow);
				saveWatchlistToPreferences(dashboardContext.watchlist);
				forceCloseModal('fade modal');
			} else {
				setNameError(t('wtr:NAME_IN_USE'));
				setOpenModify(true);
			}
		}
	};

	const removeCurrentWatchlist = () => {
		if (allWatchlists) {
			if (!isWatchlistNameExists(tradingMode, watchlistName.trim())) {
				setInvalidRemoveName('Watchlist does not exist');
			} else {
				removeWatchlist(tradingMode, watchlistName);
				const removed = dashboardContext.watchlist.filter(
					(watchlist) => watchlist._name.toUpperCase() !== watchlistName.toUpperCase()
				);
				dashboardContext.watchlist = removed;

				if (dashboardContext.watchlist.length > 0) {
					setCurrentWatchList(dashboardContext.watchlist[0]._name, isChildWindow);
				}
				saveWatchlistToPreferences(dashboardContext.watchlist);
				setOpenModify(false);
				forceCloseModal('fade modal');
			}
		}
	};

	const handleMoveClose = () => {
		setOpenMove(false);
		setSelectedWatchlist('');
		forceCloseModal('fade modal');
	};

	const moveToWatchlist = () => {
		// move removeItem to selectedWatchlist
		if (allWatchlists) {
			// update the store
			moveInstruments(tradingMode, currentWatchList, selectedWatchlist, removeItem);

			// update the preferences
			const fromWatchlistInstruments = getWatchlistInstruments(tradingMode, selectedWatchlist);
			if (fromWatchlistInstruments) {
				const fromWatchlist = dashboardContext.watchlist.find((watchlist) => watchlist._name === selectedWatchlist);

				if (fromWatchlist) {
					fromWatchlist.instrument = fromWatchlistInstruments.map((code) => ({ _code: code }));
				}
			}

			const toWatchlistInstruments = getWatchlistInstruments(tradingMode, currentWatchList);
			if (toWatchlistInstruments) {
				const toWatchlist = dashboardContext.watchlist.find((watchlist) => watchlist._name === currentWatchList);

				if (toWatchlist) {
					toWatchlist.instrument = toWatchlistInstruments.map((code) => ({ _code: code }));
				}
			}

			setOpenMove(false);
			saveWatchlistToPreferences(dashboardContext.watchlist);
		}
		setRemoveItem([]);
		setRemoveAll(false);
		setEditLayer(false);
		forceCloseModal('fade modal');
	};

	useEffect(() => {
		if (storeWatchlists !== null) {
			const wlCount = watchlistsCount(tradingMode);
			setMaxWatchlistsError(wlCount >= MAX_WATCHLISTS_ALLOWED);
		}
	}, [handleCreateWatchlist]);

	const sortWatchlistInstruments = (instruments: string[], sortOrder: WatchlistSortOrder) => {
		let sortedInstruments = [...instruments];
		if (instruments.length > 0) {
			switch (sortOrder) {
				case WATCHLIST_SORT_ORDER.ASCENDING:
					return sortedInstruments.sort();
				case WATCHLIST_SORT_ORDER.DESCENDING:
					return sortedInstruments.sort().reverse();
				default:
					return sortedInstruments;
			}
		}
		return sortedInstruments;
	};

	const handleSortWatchlist = (e: any) => {
		const selectedWatchlist = getWatchlist(tradingMode, currentWatchList);
		if (selectedWatchlist) {
			setSortedInstruments(sortWatchlistInstruments(selectedWatchlist.instruments, selectedWatchlist.sortOrder));
		}
	};

	const handleCloseModifyModal = () => {
		setOpenModify(false);
		setNameError('');
		setInvalidRemoveName('');
		setTypedValue('');
		forceCloseModal('fade modal');
	};

	const handleModify = (item: string) => {
		setCurrentItemWatchList(item);
		setOpenModify(true);
	};

	const handleSearchValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		if (value.length <= 1) {
			setSearchResults([]);
			setShowEmptySearchState(false);
		} else {
			promiseFactory.throttle('searchTermChange', FILTER_THROTTLE).then(() => {
				const results = getSearchResults(marketItems, value);
				if (results.length === 0) {
					setSearchResults([]);
					setShowEmptySearchState(true);
				} else {
					const sorted = sortSearchResults(results, value);
					setSearchResults(sorted);
					setShowEmptySearchState(false);
				}
			});
		}
		setSearchValue(value.trim());
	};

	const clearSearchInput = () => {
		setSearchValue('');
		setSearchResults([]);
		setShowEmptySearchState(false);
		searchInputRef.current !== null && searchInputRef.current.focus();
	};

	const resetWatchlistSearch = () => {
		setIsWatchlistSearchOpen(false);
		setSearchResults([]);
		setSearchValue('');
		setShowEmptySearchState(false);
	};

	const updateWatchlist = (action: 'add' | 'remove', symbol: string) => {
		const preferenceToUpdate = dashboardContext.watchlist.find((preference) => preference._name === currentWatchList);
		if (preferenceToUpdate) {
			if (action === 'add') {
				if (!preferenceToUpdate.instrument.find((instrument) => instrument._code === symbol)) {
					preferenceToUpdate.instrument.push({ _code: symbol });
				}
				addInstruments(tradingMode, currentWatchList, [symbol]);
			} else {
				preferenceToUpdate.instrument = preferenceToUpdate.instrument.filter(
					(instrument) => instrument._code !== symbol
				);
				removeInstruments(tradingMode, currentWatchList, [symbol]);
			}

			saveWatchlistToPreferences(dashboardContext.watchlist);
		}
	};

	const editWatchlistButton = (
		<OverlayTrigger
			delay={{ show: 750, hide: 0 }}
			key={'editWatchlist'}
			placement={isArabic ? 'left' : 'right'}
			overlay={
				<Tooltip className="my-tooltip" id={'editWatchlist'}>
					{t('en:EDIT_WATCHLIST')}
				</Tooltip>
			}
		>
			<div
				className={cn(
					isWindowStyles(styles.editIcon, styles.windowHeader, window1),
					sortedInstruments.length === 0 ? styles.disableBtn : '',
					styles.iconWrapper
				)}
				onClick={() => setEditWatchlist()}
			>
				<FontAwesomeIcon icon={['fal', 'pen']} size="1x" className={cn(styles.icon, styles[`${theme}PenIcon`])} />
			</div>
		</OverlayTrigger>
	);

	return (
		<>
			{!editLayer && allWatchlists && (
				<div className={styles.container}>
					<div className={isWindowStyles(cn(styles.header), styles.windowHeader, window1)}>
						{!isDynamicWatchlist && editWatchlistButton}
						{openModify && (
							<div className={cn(styles.openModalTitle, styles[`${theme}Title`])}>
								{getTranslatedWLName(currentWatchList)} &nbsp;
								<FontAwesomeIcon icon={['fas', 'caret-down']} size="1x" />
							</div>
						)}
						<div className={isDynamicWatchlist && styles.headerDynamic}>
							{!openModify && (
								<WatchlistsDropDown
									isOpen={openWatchlist}
									createWatchList={createWatchList}
									maxWatchlistsError={maxWatchlistsError}
									nameError={nameError}
									typedValue={typedValue}
									changeValue={changeValue}
									setIsOpen={setOpenWatchlist}
									handleModify={handleModify}
									verifyWatchlist={verifyWatchlist}
									setCurrentItemWatchList={setCurrentItemWatchList}
									handleCreateWatchlist={handleCreateWatchlist}
									handleCreateWatchListClose={handleCreateWatchListClose}
								/>
							)}
						</div>
						<div className={styles.headerButtonsWrapper}>
							{!isDynamicWatchlist && (
								<WtrPopup
									className={styles.watchlistSearchContainer}
									open={isWatchlistSearchOpen}
									onClose={resetWatchlistSearch}
									on="click"
									pinned
									position={`${isChildWindow ? 'bottom right' : 'bottom center'}`}
									trigger={
										<OverlayTrigger
											delay={{ show: 750, hide: 0 }}
											key="addIcon"
											placement={`${isChildWindow ? 'bottom' : 'top'}` as Placement}
											overlay={
												<Tooltip className="my-tooltip" id="icon">
													{tt('ADD_INSTRUMENT')}
												</Tooltip>
											}
										>
											<span className={cn(styles.addInstrumentContainer, styles.iconWrapper)}>
												<FontAwesomeIcon
													icon={['fal', 'plus-circle']}
													className={styles.addToWatchlistIcon}
													size="2x"
													onClick={() => setIsWatchlistSearchOpen(!isWatchlistSearchOpen)}
												></FontAwesomeIcon>
											</span>
										</OverlayTrigger>
									}
									content={
										<>
											<div className={styles.searchContainer}>
												<div className={styles.searchTitle}>
													{t('wtr:ADD_INSTRUMENT_TO')} {getTranslatedWLName(currentWatchList)}
												</div>
												<div className={styles.searchInputContainer}>
													<FontAwesomeIcon icon={['fas', 'search']} className={styles.inputIcon}></FontAwesomeIcon>
													<FontAwesomeIcon
														icon={['fas', 'times']}
														onClick={clearSearchInput}
														className={searchValue.length > 0 ? styles.deleteIcon : styles.hidden}
													></FontAwesomeIcon>
													<input
														className={cn(
															searchResults.length > 0 || showEmptySearchState
																? styles.openSearchInput
																: styles.searchInput
														)}
														type="text"
														placeholder={tt('ADD_INSTRUMENT')}
														autoFocus
														ref={searchInputRef}
														value={searchValue}
														onChange={handleSearchValue}
														name="search"
														spellCheck="false"
														autoComplete="off"
													/>
												</div>
											</div>
											<div
												className={cn(
													styles.searchResults,
													showEmptySearchState && styles.hiddenScroll,
													searchResults.length > 0 && styles.boxSearch
												)}
											>
												{searchResults.length > 0 &&
													sortedInstruments &&
													allWatchlists &&
													searchResults.map((result) => {
														// check if is contains in sortedInstruments
														const existsInWatchlist =
															sortedInstruments.filter((instrument) => instrument === result.code).length > 0;
														let watchlistNames: string[] = [];
														if (existsInWatchlist) {
															watchlistNames = allWatchlists
																.filter((watchlist) => watchlist.instruments.includes(result.code))
																.map((watchlist) => watchlist.name);
														}
														return (
															<SearchResult
																key={result.code}
																searchTerm={searchValue}
																result={result}
																existsInWatchlist={existsInWatchlist}
																onUpdateWatchlist={() =>
																	updateWatchlist(existsInWatchlist ? 'remove' : 'add', result.code)
																}
																watchlists={watchlistNames}
															/>
														);
													})}
												{showEmptySearchState && <EmptySearch invalidSearchTerm={searchValue} />}
											</div>
										</>
									}
								/>
							)}
							<DetachIcon url={DETACHED_SUB_ROUTES.Trade} windowType={DETACHED_WINDOW_TRADE} />
						</div>
					</div>

					{/* Watchlist Container */}
					{!isChildWindow ? (
						currentWatchList &&
						dashboardContext.watchlist && (
							<Container fluid className={styles.containerFluid}>
								{sortedInstruments ? (
									sortedInstruments.length > 0 && (
										<>
											<div className={styles.instrumentBox} onClick={handleSortWatchlist}>
												<WatchlistHeader window={window1} editLayer={editLayer} />
											</div>
											<div
												className={
													sortedInstruments.length < 13
														? cn(styles.MarketTickerContainer, styles.noScrollBar)
														: cn(!window1 ? styles.MarketTickerContainer : styles.windowMarketTickerContainer)
												}
											>
												<div className={styles.instrumentsContainer}>
													{sortedInstruments.map((instrument, i) => {
														const mktItem = rfpGatewayContext?.getMarketItem(instrument, DEFAULT_FEED_ID);
														return (
															mktItem && (
																<Instrument
																	showInstrumentName={true}
																	showPriceStats={true}
																	resizeButton={false}
																	spreadPosition={false}
																	index={i}
																	key={i}
																	marketItem={mktItem}
																	windows={window1}
																	parent="watchlist"
																	onRemovalFromWatchlist={() => instrumentHandlers.onRemovalFromWatchlist([instrument])}
																	onChartClick={() => instrumentHandlers.onChartClick(mktItem, true)}
																/>
															)
														);
													})}
												</div>

												<div className={styles.notSticky}>
													{!isDynamicWatchlist && (
														<div
															className={sortedInstruments.length === 0 ? styles.centeredText : styles.addInstrument}
															onClick={() => setIsWatchlistSearchOpen(true)}
														>
															{!window1 && (
																<>
																	<FontAwesomeIcon icon={['far', 'plus-circle']} className={styles.addInstrumentIcon} />
																	<span className={cn(styles.Title, styles[`${theme}Title`], styles.instrumentTitle)}>
																		{tt('ADD_INSTRUMENT')}
																	</span>
																</>
															)}
														</div>
													)}
												</div>
											</div>
										</>
									)
								) : (
									<div></div>
								)}
								{sortedInstruments ? (
									currentWatchList &&
									sortedInstruments.length === 0 && (
										<div className={cn(isWindowStyles(styles.emptyContainer, styles.emptyWindowContainer, window1))}>
											<div className={styles.emptyStar}>
												<FontAwesomeIcon icon={['fal', 'list-ul']} className={cn(styles.iconC)} aria-hidden="true" />
											</div>
											<div className={styles.emptyTitle}>{t('wtr:WTR_EMPTY_WATCHLIST')}</div>
											<Button
												label={tt('ADD_INSTRUMENT')}
												onClick={() => setIsWatchlistSearchOpen(true)}
												variant="secondary"
												icon={['fal', 'plus-circle']}
												iconOnhover={['far', 'plus-circle']}
											/>
										</div>
									)
								) : (
									<></>
								)}
							</Container>
						)
					) : (
						// Watchlist Child Window Container
						<>
							{sortedInstruments && currentWatchList && sortedInstruments.length === 0 ? (
								<div className={cn(isWindowStyles(styles.emptyContainer, styles.emptyWindowContainer, window1))}>
									<div className={styles.emptyStar}>
										<FontAwesomeIcon icon={['fal', 'list-ul']} className={cn(styles.iconC)} aria-hidden="true" />
									</div>
									<div className={styles.emptyTitle}>{t('wtr:WTR_EMPTY_WATCHLIST')}</div>
									<Button
										label={tt('ADD_INSTRUMENT')}
										onClick={() => setIsWatchlistSearchOpen(true)}
										variant="secondary"
										icon={['fal', 'plus-circle']}
										iconOnhover={['far', 'plus-circle']}
									/>
								</div>
							) : (
								<>
									<DetachWatchList isDynamicWatchlist={isDynamicWatchlist} />
									<div className={styles.notSticky}>
										{!isDynamicWatchlist && (
											<div
												className={
													sortedInstruments && sortedInstruments.length === 0
														? styles.centeredText
														: styles.addInstrument
												}
												onClick={() => setIsWatchlistSearchOpen(true)}
											>
												{!window1 && (
													<>
														<FontAwesomeIcon icon={['far', 'plus-circle']} className={styles.addInstrumentIcon} />
														<span className={cn(styles.Title, styles[`${theme}Title`], styles.instrumentTitle)}>
															{tt('ADD_INSTRUMENT')}
														</span>
													</>
												)}
											</div>
										)}
									</div>
								</>
							)}
							{}
						</>
					)}
				</div>
			)}
			{editLayer && (
				<WatchlistEdit
					removeAll={removeAll}
					window1={window1}
					openRemoveModal={openRemoveModal}
					removeItem={removeItem}
					editLayer={editLayer}
					availableWatchlistsForMoveOperation={availableWatchlistsForMoveOperation}
					setOpenMove={setOpenMove}
					handleSelectAllWatchListElements={handleSelectAllWatchListElements}
					handleWatchListSelection={handleWatchListSelection}
					resetEditLayer={resetEditLayer}
					setOpenRemoveModal={setOpenRemoveModal}
				/>
			)}
			{openModify && (
				<Modal show={openModify} centered dialogClassName={styles.modalWatchlist}>
					<Modal.Header className={styles.modalTopHeader}>
						<Modal.Title className={styles.modalTitle}>{t('wtr:MODIFY_WATCHLIST')}</Modal.Title>
					</Modal.Header>
					<Modal.Body className={styles.modifyWatchlistModalBody}>
						<div className={styles.modifyWatchlist}>
							<div className={styles.title}>{t('wtr:WATCHLIST_NAME')}</div>
							<div className={styles.modifyWatchlistInput}>
								<div className={styles.modifyInputContainer}>
									<input
										type="text"
										className={
											nameError.length !== 0 || invalidRemoveName.length !== 0
												? styles.modifyErrorInput
												: styles.modifyInput
										}
										maxLength={20}
										value={getTranslatedWLName(watchlistName)}
										onChange={changeWatchlistName}
									/>
								</div>
								<div className={styles.inputLimit}>{watchlistName.length}/20</div>
								{(nameError.length !== 0 || invalidRemoveName.length !== 0) && (
									<div className={styles.modifyErrorMessage}>
										{nameError.length > 0 ? nameError : invalidRemoveName}
									</div>
								)}
								<div className={cn(styles.buttonDiv, allWatchlistsCount === 1 ? styles.disableBtn : '')}>
									<Button
										label={t('wtr:REMOVE_WATCHLIST')}
										size="lg"
										onClick={removeCurrentWatchlist}
										disabled={watchlistName.trim().length === 0}
										className={cn(
											styles.modifyButton,
											allWatchlistsCount === 1 || watchlistName.trim().length === 0 ? styles.disableBtn : ''
										)}
									/>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer className={styles.modifyFooter}>
						<div className={styles.actionButtons}>
							<Button
								label={tt('CANCEL')}
								size="lg"
								variant="outline"
								className={styles.cancelButton}
								onClick={handleCloseModifyModal}
							/>
							<Button
								label={tt('SAVE')}
								size="lg"
								variant="primary"
								disabled={watchlistName.trim().length === 0}
								className={styles.saveButton}
								onClick={renameCurrentWatchlist}
							/>
						</div>
					</Modal.Footer>
				</Modal>
			)}
			{openMove && (
				<Modal show={openMove} dialogClassName={styles.modalWatchlist} centered scrollable={true}>
					<Modal.Header onHide={handleMoveClose} className={styles.moveModalHeader}>
						<Modal.Title className={styles.moveModalTitle}>{tt('QUOTES_EDIT_MOVE_TO')}</Modal.Title>
						<FontAwesomeIcon icon={['fas', 'times']} className={styles.closeIcon} onClick={handleMoveClose} />
					</Modal.Header>
					<Modal.Body>
						{allWatchlists &&
							allWatchlists.map((watchlist, index) => {
								if (
									watchlist.name !== currentWatchList &&
									!instrumentExistsInDynamicWatchlists(
										watchlist.name,
										dashboardContext.defaultWatchLists,
										isSpreadBettingAccount
									)
								) {
									let isSomeOfTheElementsExistsInWatchlist = removeItem.some((item: any) =>
										watchlist.instruments.includes(item)
									);

									const maxInstruments = appContext.isJapanAccount
										? MAX_INSTRUMENTS_ALLOWED_JP
										: MAX_INSTRUMENTS_ALLOWED;
									const isMaxInstruments = watchlist.instruments.length + removeItem.length > maxInstruments;

									if (!isMaxInstruments && !isSomeOfTheElementsExistsInWatchlist) {
										return (
											<div key={index} className={styles.moveWatchlist}>
												<Radio
													label={getTranslatedWLName(watchlist.name)}
													checked={selectedWatchlist === watchlist.name}
													onChange={() => setSelectedWatchlist(watchlist.name)}
												/>
											</div>
										);
									}
								}
								return null;
							})}
					</Modal.Body>
					<Modal.Footer className={styles.modalFooter}>
						<div className={styles.actionButtons}>
							<Button
								label={tt('CANCEL')}
								variant="outline"
								size="lg"
								className={styles.cancelButton}
								onClick={handleMoveClose}
							/>
							<Button
								variant="primary"
								label={tt('WATCHLIST_MOVE_BTN_TITLE')}
								size="lg"
								className={
									selectedWatchlist === '' || allWatchlistsCount === 1 ? styles.disableButton : styles.saveButton
								}
								onClick={moveToWatchlist}
							/>
						</div>
					</Modal.Footer>
				</Modal>
			)}
			{openRemoveModal && (
				<Modal show={openRemoveModal} centered dialogClassName={styles.modalRemoveWatchlist}>
					<Modal.Header className={styles.modalTopHeader}>
						<Modal.Title className={styles.modalTitle}>{tt('REMOVE')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className={styles.removeMessage}>{t('wtr:CONFIRM_REMOVE')}</div>
					</Modal.Body>
					<Modal.Footer className={styles.modifyFooter}>
						<div className={styles.actionButtons}>
							<Button
								variant="secondary"
								size="lg"
								label={tt('CANCEL')}
								className={styles.cancelButton}
								onClick={handleCancelRemove}
							/>
							<Button
								variant="danger"
								size="lg"
								label={tt('REMOVE')}
								className={styles.saveButton}
								onClick={handleRemoveItem}
							/>
						</div>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
};

export default Watchlist;
