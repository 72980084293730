import React, { useContext, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { MarketItem, PriceQuote } from '../../../../../../../gateways/RfpGateway/rfp.types';
import AppContext from '../../../../../../../contexts/AppContext';

import DashboardContext from '../../../../../../../contexts/DashboardContext';
import RfpGatewayContext from '../../../../../../../contexts/RfpGatewayContext';
import usePromiseFactory from '../../../../../../../utils/hooks/usePromiseFactory';
import useForceRerender from '../../../../../../../utils/hooks/useForceRerender';
import useObservable from '../../../../../../../utils/hooks/useObservable';
import { Optional } from '../../../../../../../utils/functions/Nullable';
import { getFormattedSpreadValue, getInstrumentDetails } from '../../../../../../../utils/functions/calculations';
import useShortTranslation from '../../../../../../../utils/hooks/useShortTranslation';

import { formatNumberWithCommas } from '../../../../Watchlist/Instrument/formattedQuoteNumber';

import { getTierDisplayName } from '../../../../../../../utils/functions/subscriptionUtils';

import { QUANTITY_TYPE, TicketLayout } from '../../../../../../../utils/functions/enums';
import { TradersGymContext, TradersGymContextType } from '../../../../../../../pages/TradersGym/TradersGymContext';

import useSelectedTradingAccount from '../../../../../../../utils/hooks/useSelectedTradingAccount';

import tradingAccountStore from '../../../../../../../store/tradingAccountStore';
import authStore from '../../../../../../../store/authStore';
import tradingViewStore from '../../../../../../../store/tradingViewStore';
import useTimeSessions from '../../../../../../../utils/hooks/useTimeSessions';

import MarginRequirements from './MarginRequirements/MarginRequirements';
import InfoElement from './InfoElement';

import WeekInformation from './SessionInformation/WeekInformation';

import styles from './OrderInformation.module.scss';

interface ModalOrderInformationProps {
	redirectFromDashboard: boolean;
}

const ModalOrderInformation = ({ redirectFromDashboard }: ModalOrderInformationProps) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');

	const languageSettings = appContext.languageSettings;

	const subIdRef = useRef<string | undefined>(undefined);

	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;

	const selectedMarketItem = dashboardContext.selectedInstrument;
	const selectedPosition = dashboardContext.selectedPosition;
	const detailedInfo = dashboardContext.detailedInformation;
	const activeTradingAccount = useSelectedTradingAccount();
	const marketItems = dashboardContext.marketItems;
	const quantityType = dashboardContext.quantityType;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isJapanAccount = authStore.use.isJapanAccount();
	const ticketLayout = tradingViewStore.use.ticketLayout();
	const setDockStylesTicket = tradingViewStore.use.setDockStylesTicket();
	const dockStylesTicket = tradingViewStore.use.dockStylesTicket();

	const { isWorkingTime } = useTimeSessions();

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'selectedInstrument',
			'selectedPosition',
			'detailedInformation',
			'marketItems',
			'quantityType',
			'tradingAccount'
		),
		async (change) => {
			await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
			forceRerender();
		}
	);

	const [max, setMax] = useState<number | any>(0);
	const [min, setMin] = useState<number | any>(0);
	const [swapL, setSwapL] = useState<number | any>(0);
	const [swapS, setSwapS] = useState<number | any>(0);
	const [rndLot, setRndLot] = useState<number>(0);
	const [spread, setSpread] = useState<string | any>('');
	const [symbolDigits, setSymbolDigits] = useState<number>(0);
	const [tickSize, setTickSize] = useState<string | undefined>(undefined);
	const [tickValue, setTickValue] = useState<string | undefined>(undefined);
	const [currency, setCurrency] = useState<string | any>('');
	const [threeDaySwap, setThreeDaySwap] = useState<string | any>('');
	const [marginReq, setMarginReq] = useState('0');
	const [currentPriceQuote, setCurrentPriceQuote] = useState<Optional<PriceQuote>>();

	useEffect(() => {
		setDockStylesTicket(redirectFromDashboard);
	}, []);

	useEffect(() => {
		fetchInfoParams();
	}, [selectedMarketItem, selectedPosition, quantityType, detailedInfo]);

	const calculateSpread = (priceQuote: any) => {
		const item = marketItems.filter((item: MarketItem) => selectedMarketItem && item.code === selectedMarketItem.code);
		if (priceQuote) {
			let spreadVal = getFormattedSpreadValue(item[0], priceQuote.a, priceQuote.b, languageSettings);
			setSpread(spreadVal);
		}
	};

	useEffect(() => {
		if (rfpGatewayContext && selectedMarketItem) {
			// unsubscribe previous quotes
			if (subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
			}

			// subscribe for price quote
			subIdRef.current = rfpGatewayContext.subscribePriceQuote(
				selectedMarketItem.feedId,
				[selectedMarketItem.code],
				(priceQuote) => {
					if (!tradersGymContext.isActive) {
						setCurrentPriceQuote(priceQuote);
					}
				}
			);
		}

		// unsubscribe price quote on unmount
		return () => {
			if (rfpGatewayContext && subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
				subIdRef.current = undefined;
			}
		};
	}, [selectedPosition, selectedMarketItem, rfpGatewayContext]);

	useEffect(() => {
		if (currentPriceQuote) {
			calculateSpread(currentPriceQuote);
		}
	}, [currentPriceQuote]);

	useEffect(() => {
		if (tradersGymContext.priceQuote) {
			setCurrentPriceQuote(tradersGymContext.priceQuote);
		}
	}, [tradersGymContext.priceQuote]);

	//Fetch information related to the selected position from order grid
	const fetchInfoParams = () => {
		if (selectedMarketItem && Object.keys(selectedMarketItem).length > 0) {
			let instrumentCode = '';
			if (Array.isArray(selectedPosition) && selectedPosition.length > 0) {
				instrumentCode = selectedPosition[0].code;
			} else {
				instrumentCode = selectedMarketItem.code;
			}

			if (detailedInfo && Object.keys(detailedInfo).length > 0) {
				const info = getInstrumentDetails(detailedInfo, [activeTradingAccount!], instrumentCode);
				const rnd = info?.rndLot || 0;
				setRndLot(rnd);

				if (quantityType === QUANTITY_TYPE.LOTS && detailedInfo) {
					setMax(formatNumberWithCommas(info?.maxL || 0, 0, languageSettings));
					setMin(formatNumberWithCommas(info?.minL || 0, 2, languageSettings));
				} else {
					const maxValue = info?.maxL || 0;
					const minValue = info?.minL || 0;
					let maxAmount = maxValue * rnd;
					let minAmount = minValue * rnd;
					setMax(formatNumberWithCommas(maxAmount, 0, languageSettings));
					setMin(formatNumberWithCommas(minAmount, 2, languageSettings));
				}
				const plDecPrec = info?.swpL.toString().includes('.') ? info?.swpL.toString().split('.')[1]?.length ?? 0 : 0;
				const psDecPrec = info?.swpS.toString().includes('.') ? info?.swpS.toString().split('.')[1]?.length ?? 0 : 0;
				setSwapL(formatNumberWithCommas(info?.swpL || 0, plDecPrec, languageSettings));
				setSwapS(formatNumberWithCommas(info?.swpS || 0, psDecPrec, languageSettings));

				setThreeDaySwap(info?.threeDaySwap || '');
				const sDig = info?.sDig ?? 0;
				setSymbolDigits(sDig);
				setTickSize(info?.tickSize?.toFixed(sDig));
				setTickValue(info?.tickValue?.toFixed(sDig));
				setCurrency(info?.ccy ?? '');
				setMarginReq(formatNumberWithCommas(info?.marginReq ?? 0, 2, languageSettings));
			}
		}
	};

	const translateSector = (sector: string) => {
		switch (sector) {
			case 'Communication Services':
				return t('wtr:COMMUNICATION_SERVICES');
			case 'Consumer Discretionary':
				return t('wtr:CONSUMER_DISCRETIONARY');
			case 'Consumer Staples':
				return t('wtr:CONSUMER_STAPLES');
			case 'Energy':
				return t('wtr:ENERGY');
			case 'Financials':
				return t('wtr:FINANCIALS');
			case 'Health Care':
				return t('wtr:HEALTH_CARE');
			case 'Industrials':
				return t('wtr:INDUSTRIALS');
			case 'Information Technology':
				return t('wtr:INFORMATION_TECH');
			case 'Materials':
				return t('wtr:MATERIALS');
			case 'Real Estate':
				return t('wtr:REAL_ESTATE');
			case 'Technology':
				return t('wtr:TECH');
			case 'Utilities':
				return t('wtr:UTILITIES');
		}
		return sector;
	};

	const translateCountryCode = (code: string) => {
		switch (code) {
			case 'AU':
				return t('wtr:Australia');
			case 'DE':
				return t('wtr:Germany');
			case 'ES':
				return t('wtr:Spain');
			case 'FI':
				return t('wtr:Finland');
			case 'FR':
				return t('wtr:France');
			case 'GB':
				return t('wtr:GreatBritain');
			case 'HK':
				return t('wtr:HongKong');
			case 'IT':
				return t('wtr:Italy');
			case 'NL':
				return t('wtr:Netherlands');
			case 'US':
				return t('wtr:UnitedStates');
			case 'ZA':
				return t('wtr:SouthAfrica');
		}
		return code;
	};

	const translateThreeDaysSwapValue = (value: string) => {
		switch (value.toLowerCase()) {
			case 'monday':
				return t('en:MONDAY');
			case 'tuesday':
				return t('en:TUESDAY');
			case 'wednesday':
				return t('en:WEDNESDAY');
			case 'thursday':
				return t('en:THURSDAY');
			case 'friday':
				return t('en:FRIDAY');
			case 'saturday':
				return t('en:SATURDAY');
			case 'sunday':
				return t('en:SUNDAY');
		}
		return value;
	};

	return (
		<div className={dockStylesTicket ? styles.undockedContainer : null}>
			{appContext.isJapanAccount && (
				<InfoElement
					title={t('wtr:SUBSCRIPTION_PLAN')}
					value={appContext.subscriptionInfo?.tier ? getTierDisplayName(appContext.subscriptionInfo.tier) : ''}
				/>
			)}
			{isSpreadBettingAccount && <InfoElement title={t('wtr:PRODUCT_TYPE')} value="Spread Betting" />}
			{!isSpreadBettingAccount && (
				<InfoElement title={tt('CONTRACT_SIZE')} value={formatNumberWithCommas(rndLot, 0, languageSettings)} />
			)}
			{!isSpreadBettingAccount && <InfoElement title={tt('CURRENCY')} value={currency} />}
			{selectedMarketItem &&
				selectedMarketItem.exchangeTicker !== '' &&
				(selectedMarketItem.grp === 'CFD' || selectedMarketItem.grp === 'Equities') && (
					<InfoElement title={t('wtr:PRODUCT_TYPE')} value={t('wtr:CFD')} />
				)}
			<InfoElement title={tt('SPREAD')} value={spread} />

			<MarginRequirements>
				<InfoElement title={t('wtr:MARGIN_REQ')} value={`${marginReq}%`} />
			</MarginRequirements>

			{!isJapanAccount && (
				<>
					<InfoElement title={tt('SWAP_LONG')} value={swapL} className={styles.formattedSwapInfo} />
					<InfoElement title={tt('SWAP_SHORT')} value={swapS} className={styles.formattedSwapInfo} />
					<InfoElement
						title={tt('SWAP_THREE_DAYS')}
						value={translateThreeDaysSwapValue(threeDaySwap)}
						className={styles.formattedSwapInfo}
					/>
				</>
			)}
			<InfoElement title={tt('MIN_AMOUNT_VALUE')} value={min} />
			<InfoElement title={tt('MAX_AMOUNT_VALUE')} value={max} />

			<InfoElement title={tt('DIGITS')} value={symbolDigits} />
			{tickSize && <InfoElement title={tt('TICK_SIZE')} value={tickSize} />}
			{tickValue && <InfoElement title={tt('TICK_VALUE')} value={tickValue} />}
			{selectedMarketItem?.sector && (
				<InfoElement title={t('wtr:SECTOR')} value={translateSector(selectedMarketItem.sector)} />
			)}
			{selectedMarketItem?.exchangeCountryCode && (
				<InfoElement
					title={t('wtr:EXCHANGE_COUNTRY')}
					value={translateCountryCode(selectedMarketItem.exchangeCountryCode)}
				/>
			)}

			{!isJapanAccount && selectedMarketItem && isWorkingTime(selectedMarketItem, new Date()) !== undefined && (
				<InfoElement title={t('en:Session')} value={<WeekInformation marketItem={selectedMarketItem} />} />
			)}
		</div>
	);
};

export default ModalOrderInformation;
