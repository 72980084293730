import React, { useContext } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from '../../../../../components/Tooltip/Tooltip';
import AppContext from '../../../../../../contexts/AppContext';
import { GridType, TicketLayout } from '../../../../../../utils/functions/enums';
import { TradingPosition, TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';

import styles from '../../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import { TradersGymContext, TradersGymContextType } from '../../../../../../pages/TradersGym/TradersGymContext';
import tradingViewStore from '../../../../../../store/tradingViewStore';

interface CloseIconProps {
	info: any;
	header?: boolean;
	gridType: GridType;
	gym?: boolean;
}
const CloseIcon = ({ info, header = false, gridType, gym = false }: CloseIconProps) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);

	const tradingPositions = dashboardContext.getTradingPositions();
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { getPositions } = gymContext;
	const isArabic = appContext.isArabic;
	const ticketLayout = tradingViewStore.use.ticketLayout();

	const activeTradingAccount = useSelectedTradingAccount();

	const { t } = useTranslation();

	let tooltip = header ? t('en:TRADES_CLOSE_ALL_POSITIONS') : t('en:TRADES_CLOSE_POSITION');

	if (gridType === GridType.Order) {
		tooltip = header ? t('en:TRADES_CANCEL_ALL_ORDERS') : t('en:TRADES_CANCEL_ORDER');
	}

	const orderIconsSize = '20px';
	const record = info?.row?.original;

	const iconStyle = isArabic
		? {
				fontSize: orderIconsSize,
				marginRight: '0',
		  }
		: {
				fontSize: orderIconsSize,
				marginLeft: '0',
		  };

	const showClose = () => {
		if (ticketLayout === TicketLayout.Dock) {
			if (header || (record && record.current > 0)) {
				dashboardContext.closeAllOtherTabs();
				if (gridType === GridType.Position) {
					dashboardContext.showCloseTicket = true;
				} else if (gridType === GridType.Order) {
					dashboardContext.showCancelTicket = true;
				}
			}
		} else {
			if (gridType === GridType.Position) {
				dashboardContext.modalToggle = { closePosition: true, closeOrder: false };
			} else if (gridType === GridType.Order) {
				dashboardContext.modalToggle = { closePosition: false, closeOrder: true };
			}
		}

		let tradingPositionState = TradingPositionState.open;

		if (gridType === GridType.Order) {
			tradingPositionState = TradingPositionState.pending;
		}

		if (header) {
			if (gym) {
				const tradingPositions = getPositions(tradingPositionState);
				dashboardContext.selectedPosition = tradingPositions;
			} else {
				const allPositions = tradingPositions.filter(
					(open: TradingPosition) => open.state === tradingPositionState && open.aId === activeTradingAccount?.id
				);
				dashboardContext.selectedPosition = allPositions;
			}

			dashboardContext.headerSelected = true;
		} else {
			dashboardContext.headerSelected = false;
			if (!gym) {
				const positions: any = tradingPositions.filter(
					(t) =>
						t.code === record.code &&
						t.state === tradingPositionState &&
						t.aId === activeTradingAccount?.id &&
						(record.isGroupRow || record.posId === t.posId)
				);

				dashboardContext.selectedPosition = positions;
			} else {
				const tradingPositions = getPositions(tradingPositionState);
				const positions: any = tradingPositions.filter(
					(t) =>
						t.code === record.code &&
						t.state === tradingPositionState &&
						(record.isGroupRow || record.posId === t.posId)
				);
				dashboardContext.selectedPosition = positions;
			}
		}
	};

	return (
		<OverlayTrigger
			delay={{ show: 750, hide: 0 }}
			key={header ? 'closeAllPositions' : `close${record.code}`}
			placement="bottom-end"
			overlay={
				<Tooltip className="my-tooltip" id={header ? 'closeAllPositions' : `close${record.code}`}>
					{tooltip}
				</Tooltip>
			}
		>
			<div className={styles.triggerContainer} onClick={() => showClose()}>
				<FontAwesomeIcon icon={['far', 'times-circle']} style={iconStyle} className={styles.icon} />
			</div>
		</OverlayTrigger>
	);
};

export default CloseIcon;
