import React, { useContext, useMemo, useState } from 'react';

import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { Progress } from 'semantic-ui-react';

import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';
import AppContext from '../../../../../contexts/AppContext';
import DashboardContext from '../../../../../contexts/DashboardContext';
import { AppComponentType } from '../../../../../utils/functions/enums';

import Routes from '../../../../../setup/routes';

import WtrPopup from '../../../../components/WtrPopup/WtrPopup';

import AccountStatusCTA from '../../../../../shared/AccountStatusCTA/AccountStatusCTA';
import statusStore, { StatusStore } from '../../../../../store/accountStatusStore';

import { IStatusConfigEnum } from '../../../../components/Permissions/config.types';

import { useSwitchToLive } from '../../../../../utils/hooks/system/useSwitchMode';

import styles from './MarginLevel.module.scss';

interface MarginLevelProps {
	marginLevel: number;
	equity: number;
	margin: number;
	isGymPresenter: boolean;
	formattedValues: {
		marginPercent: string;
		margin: string;
		availableMargin: string;
	};
}

const MarginLevel: React.FC<MarginLevelProps> = ({ formattedValues, equity, margin, marginLevel, isGymPresenter }) => {
	const tt = useShortTranslation('en:');
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { isJapanAccount } = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const permissions = statusStore((state: StatusStore) => state.permissions);
	const setShowAccountStatus = statusStore((state: any) => state.setShowAccountStatus);
	const marginLevelAction = permissions?.marginLevelAction;

	const switchToLive = useSwitchToLive();
	const marginLevelLabel = permissions?.marginLevelLabel;
	const history = useHistory();

	const iconButton: IconName = isOpen ? 'caret-up' : 'caret-down';

	const handleTpRedirect = (_: any) => {
		document.body.click();
		if (marginLevelAction === IStatusConfigEnum.switchToRealMoney) {
			switchToLive();
		} else if (marginLevelAction === IStatusConfigEnum.fund) {
			dashboardContext.presentComponentType = AppComponentType.Deposits;
			history.push(Routes.trader.funds, { from: window.location.pathname });
		} else if (marginLevelAction === IStatusConfigEnum.accountStatus) {
			setShowAccountStatus();
		}
	};

	const progressBarMargin = useMemo(() => {
		if (margin === 0) {
			return 0;
		}
		if (margin < equity) {
			return (equity / (equity + margin)) * 100;
		} else {
			return (equity / margin) * 50;
		}
	}, [margin, equity]);

	const progressIndicator = useMemo(() => {
		if (!marginLevel) return null;

		if (isJapanAccount) {
			if (marginLevel >= 120) return styles.green;
			if (marginLevel >= 100 && marginLevel < 120) return styles.orange;
			if (marginLevel < 100) return styles.red;
		} else {
			if (marginLevel >= 100) return styles.green;
			if (marginLevel >= 50 && marginLevel < 100) return styles.orange;
			if (marginLevel < 50) return styles.red;
		}
	}, [marginLevel]);

	return (
		<WtrPopup
			on={['click']}
			position="bottom left"
			basic
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			trigger={
				<div
					className={cn(
						styles.wrapper,
						progressBarMargin === 0 ? styles.disabled : progressIndicator,
						isGymPresenter && styles.gymPresenter,
						isOpen && styles.isOpen
					)}
					onClick={() => setIsOpen(!isOpen)}
				>
					{!isGymPresenter ? <label className={styles.statsLabel}>{`${tt('MARGIN_LEVEL')}:`}</label> : null}
					<div className={styles.valueContainer}>
						<div className={styles.statusIndicator}></div>
						<div className={styles.marginPercent}>{formattedValues.marginPercent}</div>
						<FontAwesomeIcon icon={['fas', iconButton]} className={styles.toggleIcon} />
					</div>
				</div>
			}
			content={
				<div className={styles.popup}>
					<div className={styles.contentWrapper}>
						<div
							className={cn(styles.progressBarContainer, progressBarMargin === 0 ? styles.disabled : progressIndicator)}
						>
							<span className={styles.progressPercent}>{t('wtr:MARGIN_STATUS')}</span>

							<div className={styles.progressWrapper}>
								<Progress percent={progressBarMargin} size="tiny" className={styles.progressBar} />

								<div className={cn(styles.dividerShort, isJapanAccount && styles.japanPositioningShortDiv)}></div>

								<div className={cn(styles.dividerLong, isJapanAccount && styles.japanPositioningLongDiv)}></div>
							</div>
						</div>
						<div className={styles.marginCallStopOut}>
							<div className={cn(isJapanAccount ? styles.marginCallJapan : styles.marginCall)}>
								<span className={cn(styles.text, isJapanAccount && styles.japanText)}>
									{t('wtr:ESTIMATED_STOP_OUT')}
								</span>
							</div>
							<div className={styles.stopOut}>
								<span className={styles.text}>{t('wtr:ESTIMATED_MARGIN_CALL')}</span>
							</div>
						</div>
					</div>

					<div className={styles.contentContainer}>
						<span>{tt('MARGIN')}:</span>
						<span className={styles.values}>{formattedValues.margin}</span>
					</div>
					<div className={styles.contentContainer}>
						<span>{tt('AVAILABLE_MARGIN')}:</span>
						<span className={styles.values}>{formattedValues.availableMargin}</span>
					</div>
					{marginLevelAction && !isGymPresenter && (
						<AccountStatusCTA
							onClick={handleTpRedirect}
							size={'fluid'}
							variant="primary"
							isFullWidth
							label={marginLevelLabel}
						/>
					)}
				</div>
			}
		/>
	);
};

export default MarginLevel;
