import React, {useState} from 'react';
import cn from 'classnames';
import styles from '../../PositionsPanel.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnFiltersState, OnChangeFn } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

const PositionSearch = ({
	columnFilters,
	setColumnFilters,
}: {
	columnFilters: ColumnFiltersState;
	setColumnFilters: OnChangeFn<ColumnFiltersState>;
}) => {
	const { t } = useTranslation();
	const [haveX, setHaveX] = useState<boolean>(false);

	const instrumentName = (columnFilters.find((f) => f.id === 'instrument')?.value || '') as ColumnFiltersState;

	const onFilterChange = (id: string, value: string) => {
		setColumnFilters((prev) => prev.filter((f) => f.id !== id).concat({ id, value }));
		value !== "" ? setHaveX(true) : setHaveX(false);
	};

	return (
		<div className={cn(styles.searchBox, columnFilters.length > 0 && styles.notEmpty)}>
			<FontAwesomeIcon className={styles.searchIcon} icon={['fas', 'search']} />
			<input
				placeholder={t('wtr:SEARCH_FOR_POSITION')}
				onChange={({ target }) => onFilterChange('instrument', target.value)}
				value={instrumentName as unknown as string}
				width={200}
			/>
			{haveX && <FontAwesomeIcon
				className={styles.clearSearchIcon}
				icon={['fas', 'times-circle']}
				onClick={() => setColumnFilters([])}
			/>}
		</div>
	);
};

export default PositionSearch;
