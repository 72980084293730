import { createContext } from 'react';

import { default as Nullable, Optional } from '../utils/functions/Nullable';
import { ObservableMap, ObservableSet, ObservableQueue, IObservableMapEntry } from '../utils/functions/Observables';
import { Intersection, ExcludeProperties, Comparator } from '../utils/functions/TypeUtils';
import { MarketItem } from '../gateways/RfpGateway/rfp.types';

type TWatchlistMarketItem = Intersection<MarketItem, { code: string }>;
type TReadonlyObservableSet<T> = ExcludeProperties<ObservableSet<T>, ['add', 'clear', 'delete']>;
type TReadonlyObservableMap<K, V> = ExcludeProperties<ObservableMap<K, V>, ['set', 'clear', 'delete']>;

interface IInstrumentContextConfig {
	instruments?: Optional<Iterable<MarketItem> | ArrayLike<MarketItem>>;
	watchlists?: Optional<Map<string, Iterable<TWatchlistMarketItem>>>;
	instrumentComparator?: Optional<Comparator<TWatchlistMarketItem>>;
	watchlistComparator?: Optional<Comparator<string>>;
}

interface IInstrumentContext {
	instruments: MarketItem[]; //ObservableSet<MarketItem>;
	groups: Set<string>;
	watchlists: TReadonlyObservableMap<string, TReadonlyObservableSet<TWatchlistMarketItem>>;
	recentlyViewedInstruments: ObservableQueue<MarketItem>;
	instrumentSearchInputFocusDelegate: Optional<() => any>;

	addWatchlist(name: string): boolean;
	addWatchlist(name: string, instruments: Iterable<TWatchlistMarketItem> | ArrayLike<TWatchlistMarketItem>): boolean;
	removeWatchlist(name: string): boolean;
	addInstrumentToWatchlist(instrument: TWatchlistMarketItem, watchlistName: string): boolean;
	removeInstrumentFromWatchlist(instrument: TWatchlistMarketItem, watchlistName: string): boolean;
	focusInstrumentSearchInput(): void;
	addInstruments(instruments: MarketItem[], groupingProperty: 'grp' | 'minTier'): void;
	updateGroups(groupingProperty: 'grp' | 'minTier'): void;
}

class WatchlistMap extends ObservableMap<string, ObservableSet<TWatchlistMarketItem>> {
	private m_instrumentComparator: Optional<Comparator<TWatchlistMarketItem>> = null;

	public get instrumentComparator(): Optional<Comparator<TWatchlistMarketItem>> {
		return this.m_instrumentComparator;
	}

	public set instrumentComparator(value: Optional<Comparator<TWatchlistMarketItem>>) {
		this.m_instrumentComparator = value;
	}

	public set(key: string, value: ObservableSet<TWatchlistMarketItem>): this;
	public set(values: IObservableMapEntry<string, ObservableSet<TWatchlistMarketItem>>[]): this;
	public set(
		key: string | IObservableMapEntry<string, ObservableSet<TWatchlistMarketItem>>[],
		value?: ObservableSet<TWatchlistMarketItem>
	): this {
		let values: IObservableMapEntry<string, ObservableSet<TWatchlistMarketItem>>[] = Array.isArray(key)
			? key
			: [{ key: key, value: value as ObservableSet<TWatchlistMarketItem> }];
		values = values.map((entry) => {
			return { key: entry.key, value: new ObservableSet(entry.value, this.instrumentComparator) };
		});
		return super.set(values);
	}
}

export class InstrumentContextProvider implements IInstrumentContext {
	private readonly m_instrumentComparator: Comparator<TWatchlistMarketItem>;
	private readonly m_watchlistComparator: Comparator<string>;
	private m_instruments: MarketItem[];
	private readonly m_groups: any;
	private readonly m_watchlists: WatchlistMap;
	private readonly m_recentlyViewedInstruments: ObservableQueue<MarketItem>;
	private m_instrumentSearchInputFocusDelegate: Optional<() => any> = null;

	public set instruments(instruments: MarketItem[]) {
		this.m_instruments = instruments;
	}

	public get instruments(): MarketItem[] {
		return this.m_instruments;
	}

	public get watchlists(): TReadonlyObservableMap<string, TReadonlyObservableSet<TWatchlistMarketItem>> {
		return this.m_watchlists;
	}

	public get groups(): Set<string> {
		return this.m_groups;
	}

	public get recentlyViewedInstruments(): ObservableQueue<MarketItem> {
		return this.m_recentlyViewedInstruments;
	}

	public get instrumentSearchInputFocusDelegate(): Optional<() => any> {
		return this.m_instrumentSearchInputFocusDelegate;
	}

	public set instrumentSearchInputFocusDelegate(value: Optional<() => any>) {
		this.m_instrumentSearchInputFocusDelegate = value;
	}

	public constructor(config?: Optional<IInstrumentContextConfig>) {
		this.m_instrumentComparator = Nullable.of(config)
			.map((config) => config.instrumentComparator as Comparator<TWatchlistMarketItem>)
			.orElseGet(() => (val1, val2) => (val1.code || '').toLowerCase() === (val2.code || '').toLowerCase());

		this.m_watchlistComparator = Nullable.of(config)
			.map((config) => config.watchlistComparator as Comparator<string>)
			.orElseGet(() => (val1, val2) => (val1 || '').trim().toLowerCase() === (val2 || '').trim().toLowerCase());

		this.m_instruments = Nullable.of(config)
			.map((config) => config.instruments)
			.map((instruments) => {
				return Array.from(instruments);
				// const set = new ObservableSet<MarketItem>(null, this.m_instrumentComparator);
				// set.add(...Array.from(instruments));
				// return set;
			})
			.orElseGet(() => []);

		this.m_watchlists = Nullable.of(config)
			.map((config) => config.watchlists)
			.map((watchlists) => {
				return Array.from(watchlists).map(([key, values]) => {
					return { key: key, value: ObservableSet.from(values, this.m_instrumentComparator) };
				});
			})
			.map((entries) => {
				const map = new WatchlistMap(null, this.m_watchlistComparator);
				map.instrumentComparator = this.m_instrumentComparator;
				map.set(entries);
				return map;
			})
			.orElseGet(() => {
				const map = new WatchlistMap(null, this.m_watchlistComparator);
				map.instrumentComparator = this.m_instrumentComparator;
				return map;
			});

		this.m_groups = new Set<string>();

		this.m_recentlyViewedInstruments = new ObservableQueue<MarketItem>(null, this.m_instrumentComparator, 5);
	}

	public watchlistExists(name: string): boolean {
		return Nullable.of(name)
			.map((name) => this.watchlists.has(name))
			.orElse(false);
	}

	public addWatchlist(name: string): boolean;
	public addWatchlist(
		name: string,
		instruments: Iterable<TWatchlistMarketItem> | ArrayLike<TWatchlistMarketItem>
	): boolean;
	public addWatchlist(
		name: string,
		instruments?: Iterable<TWatchlistMarketItem> | ArrayLike<TWatchlistMarketItem>
	): boolean {
		return Nullable.of(name)
			.filter((name) => !this.watchlistExists(name))
			.map((name) => {
				const marketItems = Array.from(instruments || [])
					.map((instrument) => this.getInstrument(instrument))
					.filter((instrument) => instrument);
				this.m_watchlists.set(
					name,
					ObservableSet.from(marketItems as TWatchlistMarketItem[], this.m_instrumentComparator)
				);
				return true;
			})
			.orElse(false);
	}

	public removeWatchlist(name: string): boolean {
		return Nullable.of(name)
			.map((name) => this.m_watchlists.delete(name))
			.orElse(false);
	}

	public addInstrumentToWatchlist(instrument: TWatchlistMarketItem, watchlistName: string): boolean {
		return Nullable.of(instrument)
			.map((instrument) => this.getInstrument(instrument))
			.map((instrument) => {
				return Nullable.of(this.getWatchlistInstruments(watchlistName))
					.filter((instruments) => !instruments.has(instrument))
					.map((instruments) => {
						instruments.add(instrument);
						return true;
					})
					.orElse(false);
			})
			.orElse(false);
	}

	public removeInstrumentFromWatchlist(instrument: TWatchlistMarketItem, watchlistName: string): boolean {
		return Nullable.of(instrument)
			.map((instrument) => this.getInstrument(instrument))
			.map((instrument) => {
				return Nullable.of(this.getWatchlistInstruments(watchlistName))
					.map((instruments) => instruments.delete(instrument))
					.orElse(false);
			})
			.orElse(false);
	}

	public focusInstrumentSearchInput(): void {
		Nullable.of(this.instrumentSearchInputFocusDelegate)
			.filter((fnc) => typeof fnc === 'function')
			.run((fnc) => fnc());
	}

	private getInstrument(instrument: TWatchlistMarketItem): Optional<TWatchlistMarketItem> {
		return Nullable.of(instrument)
			.map((instrument) => Array.from(this.instruments).find((value) => this.m_instrumentComparator(value, instrument)))
			.get();
	}

	private getWatchlistInstruments(watchlistName: string): Optional<ObservableSet<TWatchlistMarketItem>> {
		return this.m_watchlists.get(watchlistName);
	}

	public addInstruments(instruments: MarketItem[], groupingProperty: 'grp' | 'minTier'): void {
		for (const instrument of instruments) {
			instrument[groupingProperty] && this.m_groups.add(instrument[groupingProperty]?.toString());
		}

		this.instruments = instruments;
	}

	public updateGroups(groupingProperty: 'grp' | 'minTier'): void {
		for (const instrument of this.instruments) {
			instrument[groupingProperty] && this.m_groups.add(instrument[groupingProperty]?.toString());
		}
	}
}

const instrumentContext = createContext<IInstrumentContext>(new InstrumentContextProvider());

export default instrumentContext;
