import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CreateWatchlistButton.module.scss';

interface CreateWatchlistButtonProps {
	maxWatchlistsError: boolean;
	handleCreateWatchlist: () => void;
}

const CreateWatchlistButton: FC<CreateWatchlistButtonProps> = ({ maxWatchlistsError, handleCreateWatchlist }) => {
	const { t } = useTranslation();

	return (
		<div
			className={maxWatchlistsError ? styles.errorCreateWatchlist : styles.createWatchList}
			onClick={() => handleCreateWatchlist()}
		>
			<div className={styles.iconContainer}>
				{!maxWatchlistsError && <FontAwesomeIcon icon={['fal', 'plus-circle']} className={styles.plusIcon} />}

				<div className={styles.createFooter}>
					<span className={maxWatchlistsError ? styles.errorFooter : styles.bottomFooterTitle}>
						{t('en:CREATE_WATCHLIST')}
					</span>
					{maxWatchlistsError && <div className={styles.createErrorMessage}>{t('wtr:MAX_WATCHLIST_ERROR')}</div>}
				</div>
			</div>
		</div>
	);
};

export default CreateWatchlistButton;
