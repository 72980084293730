
const getTimeFrameTranslation = (closeTrade: any, t: any) => {

    switch (closeTrade) {
        case '1 Month':
            return t('wtr:1_MONTH');
        case '3 Months':
            return t('wtr:3_MONTHS');
        case 'YTD':
            return t('wtr:WTR_YTD');
        case '1 Year':
            return t('wtr:1_YEAR');
        case '1 Week':
            return t('wtr:1_WEEK');
        case 'Today':
            return t('wtr:WTR_TODAY');
        case 'All':
            return t('All');
    }
};

export default getTimeFrameTranslation;