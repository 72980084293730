import React, { useContext } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { default as AppContext } from '../../../contexts/AppContext';

import styles from './CloseButton.module.scss';

interface CloseButtonProps {
	onClick?: () => void;
	tooltipID?: string;
	isItClosed?: boolean;
	buttonPositioning?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, tooltipID, isItClosed=true, buttonPositioning="bottom" }) => {
	const appContext = useContext(AppContext);
	const { t } = useTranslation();

	const isArabic = appContext.isArabic;

	const handleButtonClick = () => {
		if (typeof onClick === 'function') {
			onClick();
		}
	};

	return (
		<OverlayTrigger
			key={tooltipID ? tooltipID : 'closeButtonToooltip'}
			placement={buttonPositioning === "bottom" ? 'bottom' : 'top'}
			delay={{ show: 750, hide: 0 }}
			overlay={
				<Tooltip className={`my-tooltip`} id={tooltipID ? tooltipID : 'closeButtonToooltip'}>
					{isItClosed ? t('en:CHART_COLLAPSE') : t('en:CHART_EXPAND')}
				</Tooltip>
			}
		>
			<div className={styles.rectangle} onClick={() => handleButtonClick()}>
				<FontAwesomeIcon className={styles.icon} icon={['fas', 'chevron-left']} size="1x" />
			</div>
		</OverlayTrigger>
	);
};

export default CloseButton;
