import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import AppContext from '../../../../../contexts/AppContext';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';
import authStore from '../../../../../store/authStore';
import useGetTranslatedWLName from '../../../../../utils/hooks/useGetTranslatedWLName';
import {
	instrumentExistsInDynamicWatchlists,
	MAX_INSTRUMENTS_ALLOWED,
	MAX_INSTRUMENTS_ALLOWED_JP,
} from '../../../../../utils/functions/WatchlistUtils';
import useSaveWatchlistToPreferences from '../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';

import styles from './NewWatchlistsList.module.scss';

interface WatchlistsListProps {
	instrumentExistsInWatchlist: boolean;
	marketItemCode: string;
}

const NewWatchlistsList: FC<WatchlistsListProps> = ({ instrumentExistsInWatchlist, marketItemCode }) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const getTranslatedWLName = useGetTranslatedWLName();
	const { t } = useTranslation();

	const tradingMode = authStore.use.tradingMode();
	const getWatchlists = watchListStore.use.getWatchlists();
	const addInstruments = watchListStore.use.addInstruments();
	const removeInstruments = watchListStore.use.removeInstruments();
	const allWatchlists = getWatchlists(tradingMode);

	const selectedTradingAccountWatchlist = dashboardContext.watchlist;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();

	const handleAddToWatchlist = (maxReached: boolean, watchlistName: string) => {
		if (maxReached) return;

		const updatePreferenceObject = selectedTradingAccountWatchlist.find(
			(watchlist) => watchlist._name === watchlistName
		);

		if (updatePreferenceObject) {
			updatePreferenceObject.instrument.push({ _code: marketItemCode });
			addInstruments(tradingMode, watchlistName, [marketItemCode]);
		}

		saveWatchlistToPreferences(selectedTradingAccountWatchlist, isSpreadBettingAccount);
	};

	const handleRemoveFromWatchlist = (watchlistName: string) => {
		if (allWatchlists) {
			const updatePreferenceObject = selectedTradingAccountWatchlist.find(
				(watchlist) => watchlist._name === watchlistName
			);

			if (updatePreferenceObject) {
				const removed = updatePreferenceObject.instrument.filter((item) => item._code !== marketItemCode);
				updatePreferenceObject.instrument = removed;
				removeInstruments(tradingMode, watchlistName, [marketItemCode]);
			}
		}

		saveWatchlistToPreferences(selectedTradingAccountWatchlist, isSpreadBettingAccount);
	};

	return (
		<div>
			<div className={styles.titleContainer}>
				<div className={styles.titleWatchlist}>{t('wtr:SELECT_WATCHLIST')}</div>
			</div>
			<div className={styles.watchlistContainer}>
				{allWatchlists &&
					allWatchlists.map((watchlistData) => {
						const watchlistName = watchlistData.name;
						if (
							instrumentExistsInDynamicWatchlists(
								watchlistName,
								dashboardContext.defaultWatchLists,
								isSpreadBettingAccount
							)
						) {
							return null;
						}

						const maxInstruments = appContext.isJapanAccount ? MAX_INSTRUMENTS_ALLOWED_JP : MAX_INSTRUMENTS_ALLOWED;
						const maxReached = watchlistData.instruments.length >= maxInstruments;
						const exists = watchlistData.instruments.includes(marketItemCode);

						return (
							<div
								key={`${watchlistName}-${marketItemCode}`}
								className={
									maxReached && dashboardContext.selectedInstrument && allWatchlists && instrumentExistsInWatchlist
										? styles.maxItemContainer
										: styles.itemContainer
								}
								onClick={() => {
									if (exists) {
										handleRemoveFromWatchlist(watchlistName);
									} else {
										handleAddToWatchlist(maxReached, watchlistName);
									}
								}}
							>
								<div className={styles.watchlistItem}>
									<OverlayTrigger
										delay={{ show: 750, hide: 0 }}
										key={watchlistName}
										placement="bottom-end"
										overlay={<Tooltip id={watchlistName}>{getTranslatedWLName(watchlistName)}</Tooltip>}
									>
										<>
											<span>{getTranslatedWLName(watchlistName)}</span>
											{maxReached && <div className={styles.maxErrorMessage}>{t('wtr:MAX_INSTRUMENT_ERROR')}</div>}
										</>
									</OverlayTrigger>
								</div>

								<div>
									{exists && (
										<FontAwesomeIcon icon={['fas', 'check-circle']} className={styles.existsInWatchlistIcon} />
									)}
									{!exists && <FontAwesomeIcon icon={['far', 'plus-circle']} className={styles.addToWatchlistIcon} />}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default NewWatchlistsList;
