import React, { FC, useContext } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import WtrPopup from '../../../../components/WtrPopup/WtrPopup';
import CreateWatchlistButton from '../CreateWatchlistButton';
import Button from '../../../../../shared/Button/Button';
import useGetTranslatedWLName from '../../../../../utils/hooks/useGetTranslatedWLName';
import DashboardContext from '../../../../../contexts/DashboardContext';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';
import { instrumentExistsInDynamicWatchlists } from '../../../../../utils/functions/WatchlistUtils';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';
import AppContext from '../../../../../contexts/AppContext';
import authStore from '../../../../../store/authStore';
import StringUtils from '../../../../../utils/functions/StringUtils';
import { TicketLayout } from '../../../../../utils/functions/enums';
import tradingViewStore from '../../../../../store/tradingViewStore';

import styles from './WatchlistsDropDown.module.scss';

interface WatchlistsDropDownProps {
	isOpen: boolean;
	createWatchList: boolean;
	maxWatchlistsError: boolean;
	nameError: string;
	typedValue: string;
	changeValue: (e: any) => void;
	setIsOpen: (a: boolean) => void;
	handleModify: (item: string) => void;
	verifyWatchlist: () => void;
	setCurrentItemWatchList: (item: any) => void;
	handleCreateWatchlist: () => void;
	handleCreateWatchListClose: () => void;
}

const WatchlistsDropDown: FC<WatchlistsDropDownProps> = ({
	isOpen,
	createWatchList,
	maxWatchlistsError,
	nameError,
	typedValue,
	changeValue,
	setIsOpen,
	handleModify,
	verifyWatchlist,
	setCurrentItemWatchList,
	handleCreateWatchlist,
	handleCreateWatchListClose,
}) => {
	const appContext = useContext(AppContext);
	const isChildWindow = appContext.isChildWindow || false;
	const dashboardContext = useContext(DashboardContext);
	const getTranslatedWLName = useGetTranslatedWLName();
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const getCurrentWatchList = watchListStore.use.getCurrentWatchList();
	const currentWatchList = getCurrentWatchList(isChildWindow);
	const getWatchlists = watchListStore.use.getWatchlists();
	const tradingMode = authStore.use.tradingMode();
	const allWatchlists = getWatchlists(tradingMode);
	const theme = appContext.appTheme;
	const ticketLayout = tradingViewStore.use.ticketLayout();

	const inputStyleSelector = () => {
		return !StringUtils.isNullOrEmpty(nameError)
			? styles.lightErrorInput
			: ticketLayout === TicketLayout.Dock
			? styles.costInput
			: styles.costInputModal;
	};

	const closeWatchlistPopup = (e: any) => {
		if (
			e.target.classList[0] !== undefined ||
			e.target.classList.length === 0 ||
			(Array.isArray(e.target.classList) && !e.target.classList[0].includes('Watchlist'))
		) {
			setIsOpen(false);
		}
	};

	return (
		<>
			<WtrPopup
				className={styles.popup}
				open={isOpen}
				onClose={closeWatchlistPopup}
				content={
					!createWatchList ? (
						<>
							<div className={styles.watchlists}>
								{allWatchlists &&
									allWatchlists.map((item, index: number) => {
										const tierName = getTranslatedWLName(item.name);

										return (
											<div
												key={index}
												className={cn(
													styles.listContainer,
													item.name === currentWatchList && styles.listContainerActive
												)}
											>
												<div
													className={styles.itemContainer}
													onClick={() => {
														setCurrentItemWatchList(item.name);
													}}
												>
													<div className={styles.watchlistItem}>
														<FontAwesomeIcon
															className={cn(styles.icon, item.name === currentWatchList && styles.active)}
															icon={['fal', 'list']}
														/>
														<span className={styles.watchlistName} id={`${item}_${index}`}>
															{tierName}
														</span>
													</div>
													{!instrumentExistsInDynamicWatchlists(
														item.name,
														dashboardContext.defaultWatchLists,
														isSpreadBettingAccount
													) && (
														<FontAwesomeIcon
															icon={['fal', 'pen']}
															size="1x"
															className={cn(styles.icon, styles.iconPen)}
															onClick={() => handleModify(item.name)}
														/>
													)}
												</div>
											</div>
										);
									})}
							</div>
							<CreateWatchlistButton
								maxWatchlistsError={maxWatchlistsError}
								handleCreateWatchlist={handleCreateWatchlist}
							/>
						</>
					) : (
						<>
							<div className={styles.createWLContainer}>
								<div className={styles.createWatchlistItems}>
									<span className={styles.createWatchlistTitle}> {tt('CREATE_WATCHLIST')}</span>
								</div>
								<div className={styles.inputContainer}>
									<div className={styles.inputTitle}>{t('wtr:WATCHLIST_NAME')}</div>
									<div className={styles.inputBox}>
										<input
											type="text"
											className={inputStyleSelector()}
											maxLength={20}
											value={typedValue}
											onChange={changeValue}
											autoFocus
											disabled={false}
										/>
									</div>
									<div className={styles.messageRow}>
										{nameError.length !== 0 && <div className={styles.errorMessage}>{nameError}</div>}
										{<div className={styles.inputContainerText}> {typedValue.trim().length}/20</div>}
									</div>
								</div>
								<div className={styles.popupFooter}>
									<Button variant="secondary" size="sm" label={tt('CANCEL')} onClick={handleCreateWatchListClose} />
									<Button
										variant="primary"
										size="sm"
										label={tt('CREATE')}
										disabled={nameError.length > 0 || typedValue.trim().length === 0}
										onClick={verifyWatchlist}
									/>
								</div>
							</div>
						</>
					)
				}
				on={['click']}
				pinned
				position="bottom center"
				trigger={
					currentWatchList && (
						<div className={cn(styles.Title, styles[`${theme}Title`])} onClick={() => setIsOpen(true)}>
							{getTranslatedWLName(currentWatchList)} &nbsp;
							<FontAwesomeIcon icon={['fas', 'caret-down']} size="1x" />
						</div>
					)
				}
			/>
		</>
	);
};

export default WatchlistsDropDown;
