import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import AppContext from '../../../../../contexts/AppContext';
import statusStore from '../../../../../store/accountStatusStore';
import useObservable from '../../../../../utils/hooks/useObservable';
import useForceRerender from '../../../../../utils/hooks/useForceRerender';
import authStore, { AuthStore } from '../../../../../store/authStore';
import { useSwitchToDemo, useSwitchToLive } from '../../../../../utils/hooks/system/useSwitchMode';

import styles from './AccountMenuPopupSection.module.scss';

interface SettingsOptionsProps {
	setAccountPopupIsOpen: (a: boolean) => void;
	setOpenSettings: (a: boolean) => void;
	handleTpRedirectReports: (a: string) => void;
	handleTpRedirectManageFunds: (a: string) => void;
}

const SettingsOptions = ({
	setAccountPopupIsOpen,
	setOpenSettings,
	handleTpRedirectReports,
	handleTpRedirectManageFunds,
}: SettingsOptionsProps) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const permissions = statusStore.use.permissions();

	const reports = permissions?.reports;
	const manageFunds = permissions?.manageFunds;
	const { isLiveMode, isDemoMode } = authStore((store: AuthStore) => ({
		isLiveMode: store.isLiveMode,
		isDemoMode: store.isDemoMode,
	}));

	const switchToLive = useSwitchToLive();
	const switchToDemo = useSwitchToDemo();

	const forceRerender = useForceRerender();
	useObservable(appContext.getPropertyChangeStream('subscriptionInfo'), () => forceRerender());

	const logoutAccount = () => {
		appContext.modalId = 'logout';
		setAccountPopupIsOpen(false);
	};

	const handleOnClickSettings = () => {
		setAccountPopupIsOpen(false);
		setOpenSettings(true);
		appContext.openSettings = true;
	};

	return (
		<div className={cn(styles.section, styles.last)}>
			{isDemoMode && (
				<div className={styles.item} onClick={switchToLive}>
					<div className={styles.iconContainerWrapper}>
						<div className={styles.iconContainer}>
							<FontAwesomeIcon className={styles.settingsIcon} icon={['far', 'money-bill-wave']} />
						</div>
						<span className={styles.text}>{t('en:SWITCH_TO_REAL')}</span>
					</div>
				</div>
			)}
			{isLiveMode && (
				<div className={styles.item} onClick={switchToDemo}>
					<div className={styles.iconContainerWrapper}>
						<div className={styles.iconContainer}>
							<FontAwesomeIcon className={styles.settingsIcon} icon={['far', 'gamepad']} />
						</div>
						<span className={styles.text}>{t('en:MORE_SWITCH_TO_DEMO')}</span>
					</div>
				</div>
			)}

			<>
				{reports && (
					<div className={styles.item} onClick={() => handleTpRedirectReports(reports as string)}>
						<div className={styles.iconContainerWrapper}>
							<div className={styles.iconContainer}>
								<FontAwesomeIcon className={styles.settingsIcon} icon={['far', 'clipboard-list']} />
							</div>
							<span className={styles.text}>{t('en:REPORTS')}</span>
						</div>
					</div>
				)}
				{manageFunds && (
					<div className={styles.item} onClick={() => handleTpRedirectManageFunds(manageFunds as string)}>
						<div className={styles.iconContainerWrapper}>
							<div className={styles.iconContainer}>
								<FontAwesomeIcon className={styles.settingsIcon} icon={['far', 'credit-card']} />
							</div>
							<span className={styles.text}>{t('wtr:WTR_MANAGE_FUNDS')}</span>
						</div>
					</div>
				)}
			</>

			<div className={styles.item} onClick={handleOnClickSettings}>
				<div className={styles.iconContainerWrapper}>
					<div className={styles.iconContainer}>
						<FontAwesomeIcon className={styles.settingsIcon} icon={['far', 'cog']} />
					</div>
					<span className={styles.text}>{t('en:SETTINGS')}</span>
				</div>
			</div>
			<div className={styles.item} onClick={logoutAccount}>
				<div className={cn(styles.iconContainerWrapper, styles.itemNoBorder)}>
					<div className={styles.iconContainer}>
						<FontAwesomeIcon className={styles.settingsIcon} icon={['far', 'arrow-right-from-bracket']} />
					</div>
					<span className={styles.text}>{t('en:MORE_LOGOUT')}</span>
				</div>
			</div>
		</div>
	);
};

export default SettingsOptions;
