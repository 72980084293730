import { useContext } from 'react';

import DashboardContext from '../../contexts/DashboardContext';
import RfpGatewayContext from '../../contexts/RfpGatewayContext';
import { MarketItem } from '../../gateways/RfpGateway/rfp.types';
import tradingAccountStore from '../../store/tradingAccountStore';
import { TWatchlistInstrument } from '../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';

import useSaveWatchlistToPreferences from './watchlist/useSaveWatchlistToPreferences';

export const useUpdateSBWatchlistInstruments = () => {
	const dashboardContext = useContext(DashboardContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	return (marketItems: MarketItem[]) => {
		if (
			isSpreadBettingAccount &&
			rfpGatewayContext &&
			marketItems.length > 0 &&
			dashboardContext.watchlist.length > 0
		) {
			let shouldUpdate = false;
			const watchlists = dashboardContext.watchlist.map((watchlist) => {
				const instruments = watchlist.instrument.map((instrument) => {
					if (instrument._code.endsWith('_SB')) {
						return instrument;
					}

					shouldUpdate = true;
					const code = `${instrument._code}_SB`;
					const marketItem = rfpGatewayContext.getMarketItem(code);
					return marketItem ? { ...instrument, _code: marketItem.code } : null;
				});
				const wlInstruments = instruments.filter((instrument) => instrument !== null);
				if (wlInstruments) {
					watchlist.instrument = wlInstruments as TWatchlistInstrument[];
				}
				return watchlist;
			});

			if (shouldUpdate) {
				console.log('Updating watchlist' + watchlists);
				dashboardContext.watchlist = watchlists;
				saveWatchlistToPreferences(dashboardContext.watchlist, isSpreadBettingAccount);
			}
		}
	};
};
