import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';

import cn from 'classnames';

import AppContext from '../../../../../contexts/AppContext';
import Routes from '../../../../../setup/routes';

import authStore from '../../../../../store/authStore';

import DashboardContext from '../../../../../contexts/DashboardContext';
import { AppComponentType } from '../../../../../utils/functions/enums';

import { useCountryPrivacyPolicy } from '../../../../../utils/hooks/useCountryPrivacyPolicy';

import styles from './CommunityList.module.scss';

interface ICommunityList {
	handleToggleOpen: (e?: React.PointerEvent) => void;
}

const CommunityList = ({ handleToggleOpen }: ICommunityList) => {
	const appContext = useContext(AppContext);
	const { t } = useTranslation();
	const history = useHistory();
	const upvoty = useRef<HTMLDivElement>();
	const communityRef = useRef<HTMLInputElement>(null);
	const dashboardContext = useContext(DashboardContext);

	const [upvotyLink, setUpvotyLink] = useState('');
	const userProfile = authStore.use.userProfile();
	
	const isJapanAccount = authStore.use.isJapanAccount();

	const privacyPolicyLink = useCountryPrivacyPolicy();
	const accountAvatar = authStore.use.accountAvatar();
	
	const privateKey = process.env.REACT_APP_UPVOTY_URL_PUBLICKEY ? process.env.REACT_APP_UPVOTY_URL_PUBLICKEY.toString() : '';

	useEffect(() => {
		const payload = {
			id: `${userProfile.id}`,
			name: `${userProfile.fullName}`,
			email: `${userProfile.email}`,
			avatar: `${process.env.REACT_APP_UPVOTY_URL_IMAGE_PATH}${accountAvatar}.svg`
		};
		
		const token = jwt.sign(payload, privateKey, {
			'issuer': `${process.env.REACT_APP_UPVOTY_URL_ISSUER?.toString()}`,
			'audience': `${process.env.REACT_APP_UPVOTY_URL_AUDIENCE?.toString()}`,
			'algorithm': "HS256"
		});
		
		const redirectUri = `${process.env.REACT_APP_UPVOTY_URL_REDIRECT?.toString()}`;
		setUpvotyLink(`${redirectUri}/?custom_sso_token=${token}`);
		
	}, [userProfile, privateKey]);

	const handleClickOutside = (event: any) => {
		if (communityRef.current && !communityRef.current.contains(event.target)) {
			handleToggleOpen();
		}
	};

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			handleToggleOpen();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('click', handleClickOutside);
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	const handleAcademyClick = () => {
		handleToggleOpen();
		if (isJapanAccount) {
			dashboardContext.userGuide = true;
			return;
		}
		dashboardContext.presentComponentType = AppComponentType.Tutorials;
		history.push(Routes.trader.tutorials);
	};

	return (
		<div className={cn(styles.popup, appContext.isArabic && styles.popupIsArabic)} ref={communityRef}>
			<div className={styles.content}>
				<div className={styles.wrapperItems}>
					<div className={styles.itemContent} onClick={handleAcademyClick}>
						<div className={styles.item}>
							<div className={styles.iconWrapper}>
								<FontAwesomeIcon
									icon={['far', isJapanAccount ? 'book-open-cover' : 'clapperboard-play']}
									className={styles.icon}
								/>
							</div>
							<p>{isJapanAccount ? t('en:USER_GUIDE') : 'Think Academy'}</p>
						</div>
					</div>

					{!isJapanAccount && (
						<div
							className={styles.itemContent}
							onClick={() => {
								window.open(upvotyLink, '_blank');
								handleToggleOpen();
							}}
						>
							<div className={styles.item}>
								<div className={styles.iconWrapper}>
									<FontAwesomeIcon icon={['far', 'people-group']} className={styles.icon} />
								</div>
								<p>{t('en:COMMUNITY_TITLE')}</p>
							</div>
						</div>
					)}
					<div
						className={styles.itemContent}
						onClick={() => {
							appContext.feedbackModal = true;
							handleToggleOpen();
						}}
					>
						<div className={styles.item}>
							<div className={styles.iconWrapper}>
								<FontAwesomeIcon icon={['far', 'message-dots']} className={styles.icon} />
							</div>
							<p>{t('wtr:WTR_NAVIGATION_FEEDBACK')}</p>
						</div>
					</div>
					<div
						className={styles.itemContent}
						onClick={() => {
							appContext.contactModal = true;
							handleToggleOpen();
						}}
					>
						<div className={cn(styles.item, styles.itemNoBorder)}>
							<div className={styles.iconWrapper}>
								<FontAwesomeIcon icon={['far', 'phone']} className={styles.icon} />
							</div>
							<p>{t('en:NAVIGATION_CONTACTUS')}</p>
						</div>
					</div>
					<div
						className={styles.itemContent}
						onClick={() => {
							window.open(privacyPolicyLink, '_blank', 'noopener,noreferrer');
						}}
					>
						<div className={cn(styles.item, styles.itemNoBorder)}>
							<div className={styles.iconWrapper}>
								<FontAwesomeIcon icon={['far', 'lock']} className={styles.icon} />
							</div>
							<p>{t('en:PRIVACY_POLICY')}</p>
						</div>
					</div>
				</div>
			</div>

			<div ref={upvoty} />
		</div>
	);
};

export default CommunityList;