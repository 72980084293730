import React, { useContext, useState, useEffect } from 'react';
import styles from '../../PositionGrid/PositionGrid.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnFiltersState, OnChangeFn } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import { TicketLayout } from '../../../../../../utils/functions/enums';
import getTimeFrameTranslation from '../../../../../../utils/functions/getTimeFrameTranslation';
import InstrumentContext from '../../../../../../contexts/InstrumentContext';
import useOrderTicketAccess from '../../../../../../utils/hooks/useOrderTicketAccess';
import tradingViewStore from '../../../../../../store/tradingViewStore';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import Button from '../../../../../../shared/Button';
import { TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import { IconName } from '@fortawesome/free-brands-svg-icons';
import { TradersGymContext, TradersGymContextType } from '../../../../../../pages/TradersGym/TradersGymContext';

const RenderClearFilters = ({
	columnFilters,
	setColumnFilters,
	type,
	periodDropdownRef,
}: {
	columnFilters: ColumnFiltersState;
	setColumnFilters: OnChangeFn<ColumnFiltersState>;
	type: TradingPositionState;
	periodDropdownRef?: any;
}) => {
	const { t } = useTranslation();

	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const instrumentContext = useContext(InstrumentContext);

	const orderTicketAccess = useOrderTicketAccess();
	const setTicketLayout = tradingViewStore.use.setTicketLayout();
	const [iconValue, setIconValue] = React.useState<any>();
	const positionTab = positionsStore.use.positionTab();

	let emptyFilteredStateMessage = t('wtr:NO_OPEN_POSITIONS_FOUND');
	let emptyStateMessage = appContext.isJapanAccount ? t('wtr:JP_NO_OPEN_POSITIONS') : t('en:NO_OPEN_POSITIONS');
	
	const closeTrade = dashboardContext.closeTrade;

	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;

	if (type === TradingPositionState.pending) {
		emptyFilteredStateMessage = t('wtr:NO_NAMED_PENDING_ORDERS');
		emptyStateMessage = t('wtr:NO_PENDING_ORDERS');
	} else if (type === TradingPositionState.closed) {
		emptyFilteredStateMessage = t('wtr:NO_POSITIONS_FOUND');
		emptyStateMessage = t('wtr:WTR_NO_CLOSED_POSITIONS');
	}

	const handleStartTrading = (event: any) => {
		event.stopPropagation();
		if (orderTicketAccess()) {
			setTicketLayout(TicketLayout.Dock);
			dashboardContext.closeAllOtherTabs();
			dashboardContext.showOrderTicket = true;
			instrumentContext.focusInstrumentSearchInput();
			dashboardContext.startTradingNow = true;
		}
		return false;
	};
	
	const openPeriodDropdown = (event: React.MouseEvent<HTMLAnchorElement>) => {
		const opener: any = event.target;
		const periodDropDownComponent = periodDropdownRef.current;

		if (!periodDropDownComponent) {
			return;
		}

		const hasOpenedDropDown = opener.dataset.hasOpenedDropDown === 'true';
		if (!hasOpenedDropDown) {
			periodDropDownComponent.open();
			opener.dataset.hasOpenedDropDown = 'true';
		} else {
			periodDropDownComponent.close();
			opener.dataset.hasOpenedDropDown = 'false';
		}
	};

	useEffect(() => {
		if (positionTab === 0) {
			setIconValue(['fal', 'chart-pie']);
		}

		if (positionTab === 1) {
			setIconValue(['fal', 'tasks']);
		}

		if (positionTab === 2) {
			setIconValue(['fal', 'clock-rotate-left']);
		}
	}, [positionTab, columnFilters.length]);

	return columnFilters.length > 0 ? (
		<div className={styles.emptyGridContainer}>
			<FontAwesomeIcon 
				className={styles.emptyIcon} 
				icon={iconValue !== undefined ? iconValue : (['fal', 'chart-pie'] as unknown as IconName)}
				size="5x"
			/>
			<div className={styles.emptyStateMessage}>
				{emptyStateMessage}
				{positionTab === 2 && !tradersGymContext.isActive ? (
					<a className={styles.emptyStateMessageLink} onClick={openPeriodDropdown}>
						{getTimeFrameTranslation(closeTrade, t)}
					</a>
				) : (
					<></>
				)}
			</div>
			{positionTab !== 2 && (
				<Button
					className={dashboardContext.showOrderTicket ? styles.startTrading_Inactive : styles.startTrading_Active}
					label={t('en:START_TRADING')}
					onClick={handleStartTrading}
					variant="secondary"
					icon={['fal', 'plus-circle']}
					iconOnhover={['far', 'plus-circle']}
				/>
			)}
		</div>
	) : (
		<div className={styles.emptyGridContainer}>
			<FontAwesomeIcon
				className={styles.emptyIcon}
				icon={iconValue !== undefined ? iconValue : (['fal', 'chart-pie'] as unknown as IconName)}
				size="5x"
			/>
			<div className={styles.emptyStateMessage}>
				{emptyStateMessage}
				{positionTab === 2 && !tradersGymContext.isActive ? (
					<a className={styles.emptyStateMessageLink} onClick={openPeriodDropdown}>
						{getTimeFrameTranslation(closeTrade, t)}
					</a>
				) : (
					<></>
				)}
			</div>
			{positionTab !== 2 && (
				<Button
					className={dashboardContext.showOrderTicket ? styles.startTrading_Inactive : styles.startTrading_Active}
					label={t('en:START_TRADING')}
					onClick={handleStartTrading}
					variant="secondary"
					icon={['fal', 'circle-plus']}
					iconOnhover={['far', 'circle-plus']}
				/>
			)}
		</div>
	);
};

export default RenderClearFilters;
