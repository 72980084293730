import { library } from '@fortawesome/fontawesome-svg-core';

import { faTwitter, faTelegram, faLine } from '@fortawesome/free-brands-svg-icons';

import {
	faTimes as fasTimes,
	faXmarkLarge as fasXmarkLarge,
	faPen as fasPen,
	faTimesCircle as fasTimesCircle,
	faSearch as fasSearch,
	faChevronLeft as fasChevronLeft,
	faChevronRight as fasChevronRight,
	faPlusCircle as fasPlusCircle,
	faInfoCircle as fasInfoCircle,
	faExternalLinkAlt as fasExternalLinkAlt,
	faSquareArrowUpRight as fasSquareArrowUpRight,
	faSquareArrowDownLeft as fasSquareArrowDownLeft,
	faCog as fasCog,
	faUser as fasUser,
	faList as fasList,
	faEye,
	faFileAlt,
	faCheckCircle,
	faTasks as fasTasks,
	faHistory,
	faChartPie as fasChartPie,
	faCaretUp,
	faCaretDown,
	faSpinner,
	faExclamationCircle,
	faCaretLeft as fasCaretLeft,
	faCaretRight as fasCaretRight,
	faArrowLeft as fasArrowLeft,
	faMoon,
	faSun,
	faFileArrowUp as fasFileArrowUp,
	faBrightness,
	faCommentAltDots as fasCommentAltDots,
	faDumbbell as fasDumbbell,
	faPlayCircle as fasPlayCircle,
	faPauseCircle as fasPauseCircle,
	faStepBackward,
	faStepForward,
	faChevronUp,
	faChevronDown,
	faSignalSlash as fasSignalSlash,
	faBookReader,
	faGripVertical,
	faGripDotsVertical,
	faBolt,
	faCommentDots as fasCommentDots,
	faTruck,
	faTrashAlt,
	faAngleUp,
	faAngleDown,
	faAngleRight,
	faExclamationTriangle,
	faExchange,
	faInfoCircle,
	faEllipsisV,
	faPlus as fasPlus,
	faPlusLarge as fasPlusLarge,
	faEquals as fasEquals,
	faBookOpenCover,
	faBoltSlash,
	faVolume,
	faVolumeSlash,
	faCircleXmark as fasCircleXmark,
	faBell,
	faInfo as fasInfo,
	faLayerPlus as fasLayerPlus,
	faQuestionCircle,
	faTrash as fasTrash,
	faMessageDots as fasMessageDots,
	faWifiSlash,
	faMoneyBillWave as fasMoneyBillWave,
	faSync,
	faShieldKeyhole as fasShieldKeyhole,
	faShield as fasShield,
	faChartColumn as fasChartColumn,
	faDesktop as fasDesktop,
	faKey as fasKey,
	faGlobe as fasGlobe,
	faChartCandlestick as fasChartCandlestick,
	faSignal as fasSignal,
	faCalendar as fasCalendar,
	faMoneyBill1 as fasMoneyBill1,
	faMoneyBillTrendUp,
	faPeopleGroup as fasPeopleGroup,
	faCircleQuestion as fasCircleQuestion,
	faClapperboardPlay as fasClapperboardPlay,
	faEyeSlash as fasEyeSlash,
	faCircleCheck as fasCircleCheck,
	faCircle as fasCircle,
	faListUl as fasListUl,
	faClockRotateLeft as fasClockRotateLeft,
} from '@fortawesome/pro-solid-svg-icons';

import {
	faTimes as falTimes,
	faXmarkLarge as falXmarkLarge,
	faCircle as falCircle,
	faPlus as falPlus,
	faPlusLarge as falPlusLarge,
	faEquals as falEquals,
	faPlusCircle as falPlusCircle,
	faChevronLeft as falChevronLeft,
	faChevronRight as falChevronRight,
	faExternalLinkAlt as falExternalLinkAlt,
	faSquareArrowUpRight as falSquareArrowUpRight,
	faSquareArrowDownLeft as falSquareArrowDownLeft,
	faSquare,
	faSquareArrowDownLeft,
	faSquareArrowUpRight,
	faCommentDots,
	faLock,
	faPen as falPen,
	faEye as falEye,
	faEyeSlash as falEyeSlash,
	faChartPie as falChartPie,
	faTasks as falTasks,
	faArrowRightArrowLeft,
	faChartCandlestick,
	faGlobe,
	faCircleXmark as falCircleXmark,
	faXmark as falXmark,
	faLayerPlus as falLayerPlus,
	faTrash as falTrash,
	faMoneyBill1,
	faCalendar,
	faInfoCircle as falInfoCircle,
	faNewspaper as falNewspaper,
	faFilter as falFilter,
	faFilterSlash as falFilterSlash,
	faChartTreeMap,
	faMoneyBillWave as falMoneyBillWave,
	faFileArrowUp as falFileArrowUp,
	faShieldKeyhole as falShieldKeyhole,
	faShield as falShield,
	faKey as falKey,
	faUser as falUser,
	faCheckSquare as fasCheckSquare,
	faSignal,
	faBookOpenCover as falBookOpenCover,
	faClone,
	faDumbbell as falDumbbell,
	faBell as falBell,
	faPeopleGroup as falPeopleGroup,
	faCircleQuestion as falCircleQuestion,
	faClapperboardPlay as falClapperboardPlay,
	faSparkles,
	faInfo as falInfo,
	faArrowDownToLine,
	faSignalSlash as falSignalSlash,
	faDesktop as falDesktop,
	faChartColumn as falChartColumn,
	faSearch as falSearch,
	faListUl as falListUl,
	faList as falList,
	faClockRotateLeft as falClockRotateLeft,
} from '@fortawesome/pro-light-svg-icons';

import {
	faPlusCircle as farPlusCircle,
	faXmarkLarge as farXmarkLarge,
	faPauseCircle,
	faPlayCircle,
	faPen,
	faLock as farLock,
	faTimesCircle as farTimesCircle,
	faChartPie as farChartPie,
	faTasks as farTasks,
	faBell as farBell,
	faCreditCard as farCreditCard,
	faExternalLinkAlt as farExternalLinkAlt,
	faSquareArrowUpRight as farSquareArrowUpRight,
	faSquareArrowDownLeft as farSquareArrowDownLeft,
	faCommentAltDots,
	faDumbbell,
	faGamepad,
	faRedo,
	faCheck,
	faFilter,
	faPlus,
	faPlusLarge as farPlusLarge,
	faCircleXmark as farCircleXmark,
	faEquals as farEquals,
	faSignalSlash as farSignalSlash,
	faCirclePlus,
	faArrowLeft,
	faMinus,
	faShieldKeyhole as farShieldKeyhole,
	faShield as farShield,
	faDesktop as farDesktop,
	faKey as farKey,
	faChartColumn as farChartColumn,
	faCircleMinus,
	faFileArrowUp as farFileArrowUp,
	faPenLine,
	faGrid,
	faFunction,
	faGear,
	faNewspaper,
	faInfoCircle as farInfoCircle,
	faArrowRightArrowLeft as farArrowRightArrowLeft,
	faMessageDots,
	faArrowRightFromBracket,
	faPhone,
	faClipboardList,
	faEnvelope,
	faDumbbell as farDumbbell,
	faPeopleGroup,
	faMoneyBillWave as farMoneyBillWave,
	faEyeSlash as farEyeSlash,
	faEye as farEye,
	faCircleQuestion,
	faClapperboardPlay as farClapperboardPlay,
	faLayerPlus as farLayerPlus,
	faTrash as farTrash,
	faExclamationTriangle as farExlamationTriangle,
	faBookOpenCover as farBookOpenCover,
	faPencil,
	faCalendarAlt,
	faGlobe as farGlobe,
	faXmark as farXmark,
	faChevronDown as farChevronDown,
	faInfo as farInfo,
	faChartCandlestick as farChartCandlestick,
	faCircle as farCircle,
	faSearch as farSearch,
	faListUl as farListUl,
	faClockRotateLeft as farClockRotateLeft,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
	faCalendar,
	fasCalendar,
	fasTimes,
	fasPen,
	fasTimesCircle,
	fasSearch,
	falSearch,
	farSearch,
	faPencil,
	faArrowDownToLine,
	fasChevronLeft,
	fasChevronRight,
	falChevronLeft,
	falChevronRight,
	fasPlusCircle,
	fasInfoCircle,
	fasExternalLinkAlt,
	falExternalLinkAlt,
	farExternalLinkAlt,
	fasCog,
	fasUser,
	faGripDotsVertical,
	falTimes,
	fasList,
	falList,
	falNewspaper,
	faGlobe,
	farGlobe,
	fasGlobe,
	farPlusCircle,
	falPlusCircle,
	fasPlusCircle,
	faEye,
	farEye,
	falEye,
	falEyeSlash,
	fasEyeSlash,
	farEyeSlash,
	faFileAlt,
	farCircleXmark,
	fasCircleXmark,
	falCircleXmark,
	falXmark,
	faCheckCircle,
	farTasks,
	falTasks,
	fasTasks,
	faHistory,
	fasChartPie,
	farChartPie,
	falChartPie,
	falPlus,
	faCaretUp,
	faSpinner,
	faCaretDown,
	faClipboardList,
	faExclamationCircle,
	faPauseCircle,
	faPen,
	falPen,
	farTimesCircle,
	fasCaretLeft,
	fasCaretRight,
	faSquare,
	fasCheckSquare,
	faBell,
	farBell,
	falBell,
	faPlayCircle,
	fasArrowLeft,
	faSun,
	faBrightness,
	faMoon,
	farCreditCard,
	faTwitter,
	faTelegram,
	faLine,
	faCommentAltDots,
	fasCommentAltDots,
	faDumbbell,
	faCommentDots,
	fasCommentDots,
	fasDumbbell,
	falDumbbell,
	farDumbbell,
	fasPlayCircle,
	fasPauseCircle,
	faStepBackward,
	faStepForward,
	faGamepad,
	faRedo,
	farMoneyBillWave,
	falMoneyBillWave,
	fasMoneyBillWave,
	faCheck,
	faSignal,
	fasSignal,
	faFilter,
	falFilter,
	faChevronUp,
	faChevronDown,
	farChevronDown,
	farXmark,
	farSignalSlash,
	fasSignalSlash,
	falSignalSlash,
	falFilterSlash,
	farLock,
	faLock,
	farShield,
	falShield,
	faBookReader,
	faGripVertical,
	faBolt,
	faBookOpenCover,
	falBookOpenCover,
	faNewspaper,
	faArrowRightArrowLeft,
	faPlus,
	faCirclePlus,
	faMinus,
	farShield,
	falShield,
	farKey,
	farChartColumn,
	faCircleMinus,
	fasFileArrowUp,
	falFileArrowUp,
	farFileArrowUp,
	fasChartColumn,
	falChartColumn,
	fasShield,
	farDesktop,
	fasDesktop,
	falDesktop,
	fasKey,
	falKey,
	faTruck,
	faTrashAlt,
	faAngleUp,
	faAngleDown,
	faAngleRight,
	faExclamationTriangle,
	faExchange,
	faInfoCircle,
	faChartCandlestick,
	fasChartCandlestick,
	fasPlus,
	faMoneyBill1,
	faSquareArrowDownLeft,
	faSquareArrowUpRight,
	fasMoneyBill1,
	faPenLine,
	faEllipsisV,
	faGrid,
	faFunction,
	faGear,
	falInfoCircle,
	farArrowRightArrowLeft,
	farInfoCircle,
	faBoltSlash,
	faChartTreeMap,
	faMessageDots,
	fasMessageDots,
	faArrowRightFromBracket,
	faPhone,
	falUser,
	faEnvelope,
	faVolume,
	faVolumeSlash,
	fasLayerPlus,
	farLayerPlus,
	falLayerPlus,
	farInfo,
	falInfo,
	fasInfo,
	faQuestionCircle,
	farTrash,
	falTrash,
	fasTrash,
	faWifiSlash,
	faSync,
	faClone,
	faPeopleGroup,
	falPeopleGroup,
	fasPeopleGroup,
	fasCircleQuestion,
	falCircleQuestion,
	faCircleQuestion,
	fasClapperboardPlay,
	falClapperboardPlay,
	farClapperboardPlay,
	faMoneyBillTrendUp,
	farExlamationTriangle,
	farBookOpenCover,
	faSparkles,
	farBookOpenCover,
	faArrowLeft,
	faCalendarAlt,
	farPlusLarge,
	falPlusLarge,
	fasPlusLarge,
	farEquals,
	falEquals,
	fasEquals,
	fasCircleCheck,
	falCircle,
	farXmarkLarge,
	falXmarkLarge,
	fasXmarkLarge,
	farSquareArrowUpRight,
	farSquareArrowDownLeft,
	fasSquareArrowUpRight,
	fasSquareArrowDownLeft,
	falSquareArrowUpRight,
	falSquareArrowDownLeft,
	fasShieldKeyhole,
	farShieldKeyhole,
	falShieldKeyhole,
	farChartCandlestick,
	farCircle,
	falCircle,
	fasCircle,
	fasListUl,
	falListUl,
	farListUl,
	falClockRotateLeft,
	fasClockRotateLeft,
	farClockRotateLeft
);
