import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'react-i18next';

import AccountStatusCTA from '../../../../../shared/AccountStatusCTA';

import styles from './MarketsEmptyState.module.scss';

interface MarketsEmptyStateProps {
	message: any;
	fullHeight?: boolean;
	clearFilters?: boolean;
	handleClearFilters?: () => void;
}

const MarketsEmptyState = ({ message, fullHeight, clearFilters, handleClearFilters }: MarketsEmptyStateProps) => {
	const { t } = useTranslation();
	return (
		<div className={cn(styles.container, fullHeight && styles.fullHeight)}>
			<FontAwesomeIcon className={styles.noResultsIcon} icon={['fal', 'filter-slash']} />
			<span className={styles.message} dangerouslySetInnerHTML={{__html: message}}/>
			{clearFilters && (
				<AccountStatusCTA variant='secondary' label={t('wtr:CLEAR_FILTERS')} onClick={handleClearFilters}/>
			)}
		</div>
	);
};

export default MarketsEmptyState;
