import React, { FC, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import { default as usePromiseFactory } from '../../../../../utils/hooks/usePromiseFactory';
import useSaveWatchlistToPreferences from '../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';
import authStore from '../../../../../store/authStore';

import styles from './AddToWatchlist.module.scss';

interface AddToWatchlistProps {
	watchlistName: string;
	maxReached?: boolean;
	// TODO: - Use the action prop to handle the edit watchlist icon functionality
	action: 'ADD' | 'EDIT';
}

const AddToWatchlist: FC<AddToWatchlistProps> = ({ watchlistName, maxReached }) => {
	const dashboardContext = useContext(DashboardContext);
	const forceRerender = useForceRerender();
	const promiseFactory = usePromiseFactory();

	const selectedInstrument = dashboardContext.selectedInstrument;
	const selectedTradingAccountWatchlist = dashboardContext.watchlist;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const tradingMode = authStore.use.tradingMode();
	const getWatchlists = watchListStore.use.getWatchlists();
	const isInstrumentExistsInWatchlist = watchListStore.use.isInstrumentExistsInWatchlist();
	const addInstruments = watchListStore.use.addInstruments();
	const removeInstruments = watchListStore.use.removeInstruments();
	const allWatchlists = getWatchlists(tradingMode);

	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();

	useObservable(
		dashboardContext.getPropertyChangeStream('selectedInstrument', 'tradingAccount', 'watchlist'),
		(change) => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => forceRerender());
		}
	);

	const handleAddToWatchlist = () => {
		if (maxReached) return;
		if (!selectedInstrument) return;

		const updatePreferenceObject = selectedTradingAccountWatchlist.find(
			(watchlist) => watchlist._name === watchlistName
		);

		if (updatePreferenceObject) {
			updatePreferenceObject.instrument.push({ _code: selectedInstrument.code });
			addInstruments(tradingMode, watchlistName, [selectedInstrument.code]);
		}

		saveWatchlistToPreferences(selectedTradingAccountWatchlist, isSpreadBettingAccount);
	};

	const handleRemoveFromWatchlist = () => {
		if (allWatchlists && selectedInstrument) {
			const updatePreferenceObject = selectedTradingAccountWatchlist.find(
				(watchlist) => watchlist._name === watchlistName
			);

			if (updatePreferenceObject) {
				const removed = updatePreferenceObject.instrument.filter((item) => item._code !== selectedInstrument.code);
				updatePreferenceObject.instrument = removed;
				removeInstruments(tradingMode, watchlistName, [selectedInstrument.code]);
			}
		}

		saveWatchlistToPreferences(selectedTradingAccountWatchlist, isSpreadBettingAccount);
	};

	const exists = () => {
		return selectedInstrument
			? isInstrumentExistsInWatchlist(tradingMode, watchlistName, selectedInstrument.code)
			: false;
	};

	return (
		<span>
			{exists() && (
				<FontAwesomeIcon
					icon={['fas', 'check-circle']}
					className={styles.existsInWatchlistIcon}
					onClick={handleRemoveFromWatchlist}
				/>
			)}
			{!exists() && (
				<FontAwesomeIcon
					icon={['far', 'plus-circle']}
					className={styles.addToWatchlistIcon}
					onClick={handleAddToWatchlist}
				/>
			)}
		</span>
	);
};

export default AddToWatchlist;
