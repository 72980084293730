import React, { useContext, useEffect, useRef, useMemo, useCallback, useState } from 'react';

import { default as cn } from 'classnames';

import { default as ReactMegaMenu } from 'react-mega-menu/lib';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { default as ArrayStore } from 'devextreme/data/array_store';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { ColumnDef, createColumnHelper, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

import { default as Nullable, Optional } from '../../../../utils/functions/Nullable';
import { ArrayElementType } from '../../../../utils/functions/TypeUtils';
import { ObservableObject } from '../../../../utils/functions/Observables';
import { MarketItem } from '../../../../gateways/RfpGateway/rfp.types';
import { default as InstrumentContext } from '../../../../contexts/InstrumentContext';
import { default as SearchableList } from '../../../components/SearchableList/SearchableList';
import { default as Fragment } from '../../../components/Fragment';

import useShortTranslation from '../../../../utils/hooks/useShortTranslation';

import { default as useForceRerender } from '../../../../utils/hooks/useForceRerender';

import { default as useObservable } from '../../../../utils/hooks/useObservable';

import { default as usePromiseFactory } from '../../../../utils/hooks/usePromiseFactory';

import { default as AppContext } from '../../../../contexts/AppContext';

import { default as DashboardContext } from '../../../../contexts/DashboardContext';
import {
	DEFAULT_FEED_ID,
	instrumentExistsInDynamicWatchlists,
	MAX_WATCHLISTS_ALLOWED,
	MAX_INSTRUMENTS_ALLOWED_JP,
	MAX_INSTRUMENTS_ALLOWED,
} from '../../../../utils/functions/WatchlistUtils';
import { TWatchlists } from '../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import usePreferredFeedId from '../../../../utils/hooks/usePreferredFeedId';
import { sortSearchResults } from '../../../../utils/functions/sortSearchResults';
import { TradersGymContext, TradersGymContextType } from '../../../../pages/TradersGym/TradersGymContext';

import GroupBadge from '../../../components/GroupBadge/GroupBadge';
import CreateWatchlistButton from '../Watchlist/CreateWatchlistButton';

import useGetTranslatedWLName from '../../../../utils/hooks/useGetTranslatedWLName';
import {
	getMinReqTierDisplayText,
	getTierDisplayName,
	getTierNameByNumber,
} from '../../../../utils/functions/subscriptionUtils';

import WtrPopup from '../../../components/WtrPopup/WtrPopup';

import useSaveWatchlistToPreferences from '../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import { AccountMarketType } from '../../../../gateways/RfpGateway/rfp.types';
import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';
import tradingAccountStore from '../../../../store/tradingAccountStore';
import watchListStore from '../../../../store/WatchListStore/watchListStore';
import tradingViewStore from '../../../../store/tradingViewStore';
import authStore from '../../../../store/authStore';

import InstrumentIcon from '../../../components/GroupBadge/InstrumentIcon';
import { simulateMouseEvent } from '../../../../utils/functions/simulateClick';

import styles from './InstrumentSearch.module.scss';

interface IMegaMenuController {
	getSections(): ReadonlyArray<Readonly<ArrayElementType<ReactMegaMenu['props']['data']>>>;
	setActiveSection(key: string | number): void;
}

type TMegaMenuProps = ReactMegaMenu['props'] & {
	controllerRef?: React.MutableRefObject<IMegaMenuController>;
};

class MegaMenu extends ReactMegaMenu {
	private m_activeRowKey: Optional<string | number> = null;

	public constructor(props: TMegaMenuProps | Readonly<TMegaMenuProps>) {
		super(props);
		this.mouseLeave = () => {};
		this.activate = (row: number) => {
			if (row < this.props.data.length) {
				this.m_activeRowKey = Nullable.of(this.props.data[row])
					.map((section) => section.key)
					.get();
				super.activate.bind(this)(row);
			}
		};
		Nullable.of(props.controllerRef).run((controllerRef) => {
			controllerRef.current = {
				getSections: () => this.props.data,
				setActiveSection: (key) => this.activateItemByKey(key),
			};
		});
	}

	private activateItemByKey(key: string | number): void {
		Nullable.of(this.props.data.findIndex((value) => value.key === key))
			.filter((index) => index >= 0)
			.run((index) => this.activate(index));
	}

	public render(): JSX.Element {
		try {
			if (this.state.activeRow >= this.props.data.length && this.m_activeRowKey) {
				this.state.activeRow = this.props.data.findIndex((value) => value.key === this.m_activeRowKey);
			}
			return super.render();
		} catch (err) {
			return <></>;
		}
	}
}

export interface IMarketItemRecord extends MarketItem {
	existsInWatchlist: boolean;
	watchlists: string[];
	searchMatch: boolean;
}

interface IInstrumentSearchProps {
	throttle?: Optional<number>;
	maxWatchlistNameLength?: Optional<number>;
	filter?: Optional<(instrument: MarketItem, searchString: string) => boolean>;
	viewInstrument?: Optional<(instrument: MarketItem) => any>;
	onOpen: () => any;
	onClose: () => any;
}

const InstrumentSearch = React.memo<IInstrumentSearchProps>((props) => {
	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
	const instrumentContext = useContext(InstrumentContext);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	const instruments = Array.from(instrumentContext.instruments).filter((value: MarketItem) =>
		isSpreadBettingAccount
			? value.accountMarketType === AccountMarketType.SpreadBetting
			: value.accountMarketType !== AccountMarketType.SpreadBetting
	);

	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const promiseFactory = usePromiseFactory();
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');
	const searchInputElementRef = useRef<any>();
	const forceRerender = useForceRerender();

	const tradingMode = authStore.use.tradingMode();
	const getWatchlists = watchListStore.use.getWatchlists();
	const getSelectedWatchlist = watchListStore.use.getSelectedWatchlist();
	const getWatchlistsContainsInstrument = watchListStore.use.getWatchlistsContainsInstrument();
	const addInstruments = watchListStore.use.addInstruments();
	const removeInstruments = watchListStore.use.removeInstruments();
	const isWatchlistNameExists = watchListStore.use.isWatchlistNameExists();
	const isInstrumentExistsInWatchlist = watchListStore.use.isInstrumentExistsInWatchlist();
	const allWatchlists = getWatchlists(tradingMode);
	const isChildWindow = appContext.isChildWindow || false;
	const getCurrentWatchList = watchListStore.use.getCurrentWatchList();
	const currentWatchList = getCurrentWatchList(isChildWindow);
	const selectedWatchlist = getSelectedWatchlist(tradingMode, isChildWindow);

	const activeTradingAccount = useSelectedTradingAccount();

	const selectedTradingAccountWatchlists = dashboardContext.watchlist;

	const instrumentGroups = Array.from(instrumentContext.groups);

	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();
	const isArabic = appContext.isArabic;
	const maxWatchlistLimit = useRef<boolean>(false);
	const displayStateRef = useRef<'select' | 'create' | ''>('');
	const preferredFeedId = usePreferredFeedId(activeTradingAccount);
	const getTranslatedWLName = useGetTranslatedWLName();
	const isSearchbarOpen = tradingViewStore.use.isSearchOpen();
	const isCompSymbolMode = tradingViewStore.use.isCompSymbolMode();
	const firstPressedButton = tradingViewStore.use.firstPressedButton();
	const setFirstPressedButton = tradingViewStore.use.setFirstPressedButton();
	const setCompSymbol = tradingViewStore.use.setCompSymbol();
	const setIsCompSymbolMode = tradingViewStore.use.setIsCompSymbolMode();

	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const isGymActive = gymContext.tradersGymContext.isActive;

	useObservable(appContext.getPropertyChangeStream('accountStats'), () => forceRerender());
	useObservable(dashboardContext.getPropertyChangeStream('watchlist', 'tradingAccount'), async () => {
		await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
		forceRerender();
	});
	// useObservable(instrumentContext.getPropertyChangeStream('instruments'), (_) => forceRerender());

	useEffect(() => {
		if (searchInputElementRef.current && firstPressedButton) {
			if (isSearchbarOpen) {
				searchInputElementRef.current.focus();
				searchInputElementRef.current.value = firstPressedButton?.toLowerCase();
			}
		}
		return () => {
			setFirstPressedButton('');
		};
	}, [firstPressedButton]);

	useEffect(() => {
		if (searchInputElementRef.current && isSearchbarOpen) {
			searchInputElementRef.current.focus();
		}

		return () => {
			setCompSymbol('');
		};
	}, [isSearchbarOpen]);

	const focusSearch = useCallback((event) => {
		if (
			event.target.tagName === 'INPUT' ||
			event.code !== `Key${event.key.toUpperCase()}` ||
			appContext.contactModal ||
			appContext.feedbackModal ||
			appContext.openSettings ||
			appContext.reconnectModal ||
			appContext.subscriptionModal ||
			appContext.statusModal
		) {
			return;
		}

		props.onOpen();
		searchInputElementRef.current.focus();
	}, []);

	useEffect(() => {
		if (
			searchInputElementRef.current &&
			!appContext.contactModal &&
			!appContext.feedbackModal &&
			!appContext.openSettings &&
			!appContext.reconnectModal &&
			!appContext.subscriptionModal &&
			!appContext.statusModal
		) {
			window.addEventListener('keypress', focusSearch, true);

			return () => {
				window.removeEventListener('keypress', focusSearch, true);
			};
		}
	}, [searchInputElementRef.current, isSearchbarOpen]);

	const groupTranslations: any = {
		Forex: t('wtr:WTR_FOREX'),
		ForexSB: t('wtr:WTR_FOREX'),
		Indices: t('wtr:WTR_INDICES'),
		IndicesSB: t('wtr:WTR_INDICES'),
		Stocks: t('wtr:SHARE_CFDS'),
		Energy: t('wtr:ENERGY'),
		EnergySB: t('wtr:ENERGY'),
		Commodities: t('wtr:WTR_COMMODITIES'),
		Crypto: t('wtr:CRYPTO'),
		CryptoSB: t('wtr:CRYPTO'),
		Metals: t('wtr:METALS'),
		MetalsSB: t('wtr:METALS'),
		Shares: t('wtr:SHARE_CFDS'),
		CFD: t('wtr:ETFS'),
		ETFs: t('wtr:ETFS'),
		All: t('en:All'),
		Tier1: getTierDisplayName('Tier1'),
		Tier2: getTierDisplayName('Tier2'),
		Tier3: getTierDisplayName('Tier3'),
	};

	useEffect(() => {
		promiseFactory.throttle('instrumentContextChanged', 100).then(() => {
			forceRerender();
		});
	}, [instrumentContext.instruments, instrumentContext.watchlists]);

	useEffect(() => {
		if (allWatchlists) {
			maxWatchlistLimit.current = allWatchlists.length >= MAX_WATCHLISTS_ALLOWED;
		}
	}, [allWatchlists]);

	const controller = ObservableObject.for({
		selectedGroup: 'All',
		selectedWatchlistManagementInstrument: null as Optional<MarketItem>,
		maxWatchlistNameLength: Math.max(1, props.maxWatchlistNameLength || 20),
	});

	const searchListFilter = useMemo(() => {
		return props.filter
			? props.filter
			: (instrument: MarketItem, searchString: string): boolean => {
					searchString = (searchString || '').trim().toLowerCase();
					return (
						searchString === '' ||
						instrument.code?.toLowerCase().includes(searchString) ||
						instrument.fullName?.toLowerCase().includes(searchString) ||
						instrument.grp?.toLowerCase().includes(searchString) ||
						(instrument.exchangeTicker ?? '')?.toLowerCase().includes(searchString)
					);
			  };
	}, [props.filter]);

	const viewInstrument = useMemo(() => {
		return props.viewInstrument ? props.viewInstrument : (_: MarketItem) => {};
	}, [props.viewInstrument]);

	instrumentContext.instrumentSearchInputFocusDelegate = () => {
		Nullable.of(searchInputElementRef.current).run((element: HTMLInputElement) => {
			props.onOpen();
			element.focus();
		});
	};

	return (
		<>
			{/*<div style={{color: "red"}} onClick={() => showSkipWaitingPrompt()}>HAPPY X5</div>*/}
			<SearchableList
				onClose={props.onClose}
				data={instruments}
				throttle={props.throttle}
				filter={searchListFilter}
				inputAttributes={{
					onOpen: props.onOpen,
					inputAttributes: {
						placeholder: t('wtr:SEARCH_INSTRUMENT'),
						ref: searchInputElementRef,
						id: 'searchContainer',
					},
					inputContainerAttributes: {
						className: styles.searchContainer,
					},
				}}
				render={(searchMatches, searchString) => {
					return (
						<div>
							<Fragment
								render={() => {
									const megaMenuControllerRef = useRef<IMegaMenuController>();
									const forceRerender = useForceRerender();
									const instrumentSearchController = controller;
									instrumentSearchController.selectedWatchlistManagementInstrument = null;
									const tableContainerRef = React.useRef<HTMLDivElement>(null);

									useObservable(instrumentSearchController.getPropertyChangeStream('selectedGroup'), () => {
										forceRerender();
									});

									//create map of IMarketItemRecord arrays, indexed by group
									const instrumentsByGroup = useMemo(() => Nullable.of(searchMatches)
										// .filter((matches) => matches.length > 0)
										.map((matches) => {
											return matches.map((instruments) => {
												const instrument: MarketItem = {
													...instruments,
													grp: instruments.grp,
													displayName: instruments.displayName,
												};
												let watchlistsNames: string[] = [];
												if (allWatchlists) {
													const watchlists = getWatchlistsContainsInstrument(tradingMode, instrument.code);
													if (watchlists) {
														watchlistsNames = watchlists.map((watchlist) => watchlist.name);
													}
												}
												const record: IMarketItemRecord = {
													...instrument,
													existsInWatchlist: watchlistsNames.length > 0,
													watchlists: watchlistsNames,
													searchMatch: searchMatches.length > 0,
												};
												return record;
											});
										})
										.map((matches) => {
											const groups = new Map<string, IMarketItemRecord[]>(Array.of(['All', matches]));

											instrumentGroups
												.sort((left: any, right: any) => {
													return (groupTranslations[left] || left || '').localeCompare(
														groupTranslations[right] || right || ''
													);
												})
												.forEach((group: any) => {
													groups.set(group as string, []);
												});

											if (isJapanSubscriptionAccount) {
												matches.forEach((instrument) => {
													// obviously this is not great but will do for now
													if (instrument.minTier) {
														for (let i = instrument.minTier; i <= 3; i++) groups.get(i.toString())!.push(instrument);
													}
												});
											} else {
												matches.forEach((instrument) => {
													instrument.grp && groups.get(instrument.grp)!.push(instrument);
												});
											}

											return groups;
										})
										.orElseGet(() => new Map<string, IMarketItemRecord[]>()),[searchMatches]);
									//create instruments panel
									const instrumentsPanel = Nullable.of(instrumentsByGroup.get(instrumentSearchController.selectedGroup))
										.map((instruments) => {
											return (
												<div>
													<Fragment
														render={() => {
															const [lastClickedCell, setLastClickedCell] = useState<{ rowId: string; columnId: string } | null>(null);
															const forceRerender = useForceRerender();
															let newInstruments = instruments;
															const selectedGroup = instrumentSearchController.selectedGroup;

															const recentlyViewedInstruments = instrumentContext.recentlyViewedInstruments;
															const isShowRecentlyViewed =
																(searchString || '').length < 1 &&
																selectedGroup === 'All' &&
																recentlyViewedInstruments.length > 0;

															if (isShowRecentlyViewed) {
																newInstruments = Array.from(recentlyViewedInstruments.values())
																	.map((instrument) => {
																		const valueComparator =
																			instrumentContext.recentlyViewedInstruments.valueComparator ||
																			((val1, val2) => val1.displayName === val2.displayName);
																		return instruments.find((value) => valueComparator(instrument, value));
																	})
																	.filter((value) => value) as IMarketItemRecord[];
															} else {
																newInstruments = instruments
																	.filter((instrument) => instrument.searchMatch)
																	.sort((val1, val2) =>
																		val1.displayName === searchString && val2.displayName !== searchString
																			? -1
																			: val2.displayName === searchString && val1.displayName !== searchString
																			? 1
																			: val1.displayName.localeCompare(val2.displayName)
																	);
															}
															newInstruments =
																searchString && searchString.length > 0
																	? (sortSearchResults(instruments, searchString) as IMarketItemRecord[])
																	: instruments;
															const dataStore = new ArrayStore({ key: 'code', data: instruments });
															const showEmptyState = instruments.length < 1;

															function renderSymbolColumn(target: any): React.ReactNode {
																const record = target.original as IMarketItemRecord;
																const displayName =
																	record.exchangeTicker && record.exchangeTicker !== ''
																		? record.exchangeTicker
																		: record.displayName;
																const upperSearch = searchString?.toUpperCase();
																let match = <span>{displayName}</span>;
																if (upperSearch && displayName.includes(upperSearch)) {
																	const notHighlighted = displayName.split(upperSearch);
																	const highlighted = <span className={cn(styles.matchText)}>{upperSearch}</span>;
																	match = (
																		<span>
																			{notHighlighted[0]}
																			{highlighted}
																			{notHighlighted[1]}
																		</span>
																	);
																}
																return (
																	<div className={styles.instrumentContainer}>
																		{isJapanSubscriptionAccount ? (
																			<OverlayTrigger
																				key="WLTierInfo"
																				delay={{ show: 750, hide: 0 }}
																				placement="bottom"
																				overlay={
																					<Tooltip id="searchInstrumentItem" className="my-tooltip">
																						{record?.minTier && getMinReqTierDisplayText(record.minTier)}
																					</Tooltip>
																				}
																			>
																				<div>
																					<GroupBadge
																						groupName={
																							isJapanSubscriptionAccount ? record.minTier : record.grp
																						}
																					/>
																				</div>
																			</OverlayTrigger>
																		) : (
																			<div className={styles.badge}>
																				<InstrumentIcon marketItem={record} />
																			</div>
																		)}
																		<div className={styles.instrumentText}>
																			<div className={styles.instrumentSymbol}>{match}</div>
																			<div className={styles.instrumentFullName}>{renderNameColumn(target)}</div>
																		</div>
																	</div>
																);
															}
															function renderNameColumn(record: any): React.ReactNode {
																const fullName = record.original.fullName;
																const upperName = fullName?.toUpperCase();
																const upperSearch = searchString?.toUpperCase();
																const showTooltipLength = 40;
																let match = <div className={styles.instrumentFullNameTrim}>{fullName}</div>;
																if (upperSearch && upperSearch !== ' ' && upperName.includes(upperSearch)) {
																	const startIndex = upperName.indexOf(upperSearch);
																	const endIndex = startIndex + upperSearch.length;
																	const highlighted = (
																		<span className={cn(styles.matchText)}>
																			{fullName.substring(startIndex, endIndex)}
																		</span>
																	);
																	match = (
																		<div className={styles.instrumentFullNameTrim}>
																			{fullName.substring(0, startIndex)}
																			{highlighted}
																			{fullName.substring(endIndex, fullName.length)}
																		</div>
																	);
																}
																return fullName.length >= showTooltipLength ? (
																	<OverlayTrigger
																		key={`${fullName}tip`}
																		placement="bottom"
																		overlay={
																			<Tooltip className="my-tooltip" id={`${fullName}tip`}>
																				{fullName}
																			</Tooltip>
																		}
																	>
																		<div>{match}</div>
																	</OverlayTrigger>
																) : (
																	<div>{match}</div>
																);
															}
															function renderWatchlistColumn(target: any, column: any): React.ReactNode {
																const record: IMarketItemRecord = target.original;
																const existsInWatchlist = record.existsInWatchlist;
																const watchlistNames = record.watchlists.map((watchlistName) =>
																	getTranslatedWLName(watchlistName)
																);
																const isSelected = Nullable.of(
																	instrumentSearchController.selectedWatchlistManagementInstrument
																)
																	.map((instrument) => instrument.code === record.code)
																	.orElse(false);
																const handleCellClick = (rowId: string, columnId: string) => setLastClickedCell({rowId, columnId});
																return (
																	<div
																		className={cn(isSelected ? styles.selected : null)}
																		id={record.code}
																		onClick={() => handleCellClick(target.id, column.id)}
																	>
																		{
																			<WtrPopup
																				className={styles.Popup}
																				content={
																					<>
																						{existsInWatchlist ? (
																							<div>
																								{t('wtr:EXISTS_IN')} {'"' + watchlistNames.join(' ", " ') + '"'}
																							</div>
																						) : (
																							<div
																								dangerouslySetInnerHTML={{
																									__html: t('wtr:ADD_TO_WATCHLIST', {
																										instrument:
																											record.exchangeTicker && record.exchangeTicker !== ''
																												? record.exchangeTicker
																												: record.code,
																									}),
																								}}
																							></div>
																						)}
																					</>
																				}
																				on={['hover']}
																				pinned
																				size="tiny"
																				position="left center"
																				trigger={
																					<div className={styles.watchlistAddRemoveIcon}											
																						data-rowid={target.id}
																						data-colid={column.id}>
																						<FontAwesomeIcon
																							icon={
																								existsInWatchlist ? ['fas', 'check-circle'] : ['far', 'plus-circle']
																							}
																							className={
																								existsInWatchlist
																									? styles.existsInWatchlistIcon
																									: styles.addToWatchlistIcon
																							}
																						/>
																					</div>
																				}
																			/>
																		}
																	</div>
																);
															}
															const triggerLastClickedCell = () => {
																if (lastClickedCell) {
																	const cellSelector = `[data-rowid="${lastClickedCell.rowId}"][data-colid="${lastClickedCell.columnId}"]`;
																	const cell = document.querySelector(cellSelector) as HTMLDivElement;
																	if (cell) {
																		cell.click();
																	}
																}
															};

															function onCellClick(target: any, name: string | undefined): void {
																Nullable.of(target)
																	.run((target) => {
																		const record: IMarketItemRecord = target;
																		if (isGymActive) {
																			viewInstrument(record);
																			return;
																		}

																		if (isCompSymbolMode) {
																			setIsCompSymbolMode(false);
																			setCompSymbol(record.code);
																			simulateMouseEvent(document, 'mousedown', 0, 0);
																			return;
																		}

																		if (
																			name === 'watchlists' &&
																			allWatchlists &&
																			selectedWatchlist
																		) {
																			maxWatchlistLimit.current = allWatchlists.length >= MAX_WATCHLISTS_ALLOWED;
																			instrumentSearchController.selectedWatchlistManagementInstrument = record;

																			const maxInstruments = appContext.isJapanAccount
																				? MAX_INSTRUMENTS_ALLOWED_JP
																				: MAX_INSTRUMENTS_ALLOWED;
																			const maxInstrumentsPerWatchlist =
																				selectedWatchlist.instruments.length >= maxInstruments;
																			if (allWatchlists.length === 1 && !maxInstrumentsPerWatchlist) {
																				displayStateRef.current = '';
																				const instrumentExists = selectedWatchlist.instruments.includes(record.code);
																				const preferenceToUpdate = selectedTradingAccountWatchlists.find(
																					(watchlist) => watchlist._name === currentWatchList
																				);
																				if (preferenceToUpdate) {
																					if (!instrumentExists) {
																						preferenceToUpdate.instrument = [
																							...preferenceToUpdate.instrument,
																							{ _code: record.code },
																						];
																					} else {
																						preferenceToUpdate.instrument = preferenceToUpdate.instrument.filter(
																							(instrument) => instrument._code !== record.code
																						);
																					}
																					dashboardContext.watchlist = selectedTradingAccountWatchlists;
																					saveWatchlistToPreferences(
																						dashboardContext.watchlist,
																						isSpreadBettingAccount
																					);
																				}
																			} else {
																				displayStateRef.current = 'select';
																			}
																			forceRerender();
																		} else {
																			instrumentSearchController.selectedWatchlistManagementInstrument = null;
																			Nullable.of(
																				instrumentContext.instruments.find((value) => value.code === record.code)
																			).run((instrument) => {
																				if (!isShowRecentlyViewed) {
																					instrumentContext.recentlyViewedInstruments.delete(instrument);
																					instrumentContext.recentlyViewedInstruments.enqueue(instrument);
																				}
																				viewInstrument(record);
																			});
																		}
																	});
															}

															const gridHeight = useMemo(() => {
																if (isShowRecentlyViewed) {
																	return isArabic ? 446 : 376;
																} else {
																	return isArabic ? 490 : 357;
																}
															}, [isArabic, isShowRecentlyViewed]);
															const columnHelper = createColumnHelper<IMarketItemRecord>();
															const columns: ColumnDef<IMarketItemRecord, any>[] = useMemo(() => [
																	columnHelper.accessor('code', {
																		id: 'code',
																		header: () => <></>,
																		cell: ({ row }) => <div className={styles.instrumentCell} onClick={() => onCellClick(row.original, row.getValue('id'))}>{renderSymbolColumn(row)}</div>,
																		enableHiding: false,
																		minSize: 300,
																	}),
																	columnHelper.accessor((row) => 'watchlists', {
																		id: 'watchlists',
																		header: () => <></>,
																		cell: ({row, column}) => <div onClick={() => onCellClick(row.original, "watchlists")} className={cn(styles.watchlistCell)}>{renderWatchlistColumn(row, column)}</div>,
																		minSize: 50,
																		enableHiding: true
																	}),
																],[Object.keys(newInstruments).length]);
															const columnVisibility = {
																watchlists: !isGymActive,
																code: true,
															};
															const table = useReactTable<IMarketItemRecord>({
																data: newInstruments,
																columns,
																getCoreRowModel: getCoreRowModel(),
																state: {
																  columnVisibility, 
																},
															});
															const rows = useMemo(() => table.getRowModel().rows, [table.getRowModel()]);	
															const rowVirtualizer = useVirtualizer({
																count: rows.length,
																estimateSize: useCallback(() => 40, []), //estimate row height for accurate scrollbar dragging
																getScrollElement: () => tableContainerRef.current,
																overscan: 10,
															});
															const virtualRows = rowVirtualizer.getVirtualItems();
															
															return showEmptyState ? (
																<div className={styles.emptyState}>
																	<FontAwesomeIcon className={styles.searchIcon} icon={['fas', 'search']} size="6x" />
																	<div className={styles.emptyStateText}>
																		{t('wtr:NO_MATCHES')} '
																		<span className={styles.emptyStateSearchString}>{searchString}</span>'
																	</div>
																	<div className={styles.emptyStateText}>{t('wtr:MODIFY_SEARCH')}</div>
																</div>
															) : (
																<>
																	<div ref={tableContainerRef} style={{ overflowY: "auto", height: gridHeight, position: "relative" }}>
																		<table style={{ width: "100%", display: "block" }}>
																			<tbody
																			style={{
																				position: "relative",
																				height: `${rowVirtualizer.getTotalSize()}px`,
																				display: "block",
																				maxHeight: "400px"
																			}}
																			>
																			{virtualRows.map(virtualRow => {
																				const row = rows[virtualRow.index] as Row<IMarketItemRecord>;

																				return row ? (
																				<tr
																					key={row.id}
																					data-index={virtualRow.index}
																					ref={rowVirtualizer.measureElement}
																					className={styles.tableRow}
																					style={{
																						top: `${virtualRow.start}px`,
																						}}
																				>
																					{row.getVisibleCells().map(cell => (
																					<td key={cell.id} style={{width: cell.id.includes('code') ? "calc(100% - 50px)" : "50px"}}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
																					))}
																				</tr>
																				) : null;
																			})}
																			</tbody>
																		</table>
																		</div>
																	<div>
																		<Fragment
																			render={() => {
																				const forceRerender = useForceRerender();
																				const newWatchlistNameRef = useRef('');
																				const watchlistNameElementRef = useRef(null);
																				const maxWatchlistNameLength =
																					instrumentSearchController.maxWatchlistNameLength;
																				const selectedInstrument =
																					instrumentSearchController.selectedWatchlistManagementInstrument;
																				const duplicateWatchlistNameRef = useRef(false);
																				const allWatchlistsLocal = getWatchlists(tradingMode);
																				useObservable(
																					instrumentSearchController.getPropertyChangeStream(
																						'selectedGroup',
																						'selectedWatchlistManagementInstrument',
																						'maxWatchlistNameLength'
																					),
																					() => {
																						displayStateRef.current = '';
																						forceRerender();
																					}
																				);

																				function updateWatchlist(
																					instrument: IMarketItemRecord,
																					watchlist: string,
																					action: 'add' | 'remove',
																					rerender: boolean = true,
																					isWatchlistMaxed: boolean,
																					triggerLastClickedCell: () => void
																				): void {
																					if (allWatchlists) {
																						const watchlistName = watchlist.trim();
																						const updatePreferenceObject = selectedTradingAccountWatchlists.find(
																							(entry) => entry._name === watchlistName
																						);

																						if (!isWatchlistMaxed && action === 'add') {
																							if (updatePreferenceObject) {
																								updatePreferenceObject.instrument.push({ _code: instrument.code });
																								addInstruments(tradingMode, watchlistName, [instrument.code]);
																							}
																						} else if (action === 'remove') {
																							if (updatePreferenceObject) {
																								updatePreferenceObject.instrument =
																									updatePreferenceObject.instrument.filter(
																										(entry) => entry._code !== instrument.code
																									);
																								removeInstruments(tradingMode, watchlistName, [instrument.code]);
																							}
																						}
																						const watchlists = getWatchlistsContainsInstrument(
																							tradingMode,
																							instrument.code
																						);
																						const watchlistsNames = watchlists
																							? watchlists.map((watchlist) => watchlist.name)
																							: [];
																						instrument = {
																							...instrument,
																							watchlists: watchlistsNames,
																							existsInWatchlist: watchlistsNames.length > 0,
																						};
																						dataStore.push([
																							{
																								type: 'update',
																								key: instrument.code,
																								data: instrument,
																							},
																						]);
																						if (rerender) {
																							saveWatchlistToPreferences(
																								selectedTradingAccountWatchlists,
																								isSpreadBettingAccount
																							);
																							forceRerender();
																						}
																						triggerLastClickedCell();
																					}
																				}

																				function onCreateWatchlistCancelClick(): void {
																					displayStateRef.current = 'select';
																					duplicateWatchlistNameRef.current = false;
																					newWatchlistNameRef.current = '';
																					Nullable.of(watchlistNameElementRef.current).run(
																						(element: HTMLInputElement) => (element.value = '')
																					);
																					forceRerender();
																				}

																				function onCreateWatchlistSaveClick(): void {
																					const watchlistName = newWatchlistNameRef.current.trim();
																					if (allWatchlists && isWatchlistNameExists(tradingMode, watchlistName)) {
																						duplicateWatchlistNameRef.current = true;
																						maxWatchlistLimit.current = allWatchlists.length >= MAX_WATCHLISTS_ALLOWED;
																						forceRerender();
																					} else if (allWatchlists) {
																						let instrument = selectedInstrument as IMarketItemRecord;
																						const newWatchlistEntry: TWatchlists = {
																							instrument: [{ _code: instrument.code }],
																							_name: watchlistName,
																							_id: watchlistName,
																							_feedId: preferredFeedId !== '' ? preferredFeedId : DEFAULT_FEED_ID,
																							_sortOrder: 'None',
																							_sortValue: 'Instrument',
																						};
																						dashboardContext.watchlist.push(newWatchlistEntry);
																						saveWatchlistToPreferences(
																							dashboardContext.watchlist,
																							isSpreadBettingAccount
																						);
																						displayStateRef.current = 'select';
																						newWatchlistNameRef.current = '';
																						duplicateWatchlistNameRef.current = false;
																						maxWatchlistLimit.current = allWatchlists.length >= MAX_WATCHLISTS_ALLOWED;
																						const watchlists = getWatchlistsContainsInstrument(
																							tradingMode,
																							instrument.code
																						);
																						const watchlistsNames = watchlists
																							? watchlists.map((watchlist) => watchlist.name)
																							: [];
																						instrument = {
																							...instrument,
																							watchlists: watchlistsNames,
																							existsInWatchlist: watchlistsNames.length > 0,
																						};
																						dataStore.push([
																							{
																								type: 'update',
																								key: instrument.code,
																								data: instrument,
																							},
																						]);
																						Nullable.of(watchlistNameElementRef.current).run(
																							(element: HTMLInputElement) => (element.value = '')
																						);

																						forceRerender();
																						triggerLastClickedCell();
																					}
																				}

																				function onCreateWatchlistClick(): void {
																					if (allWatchlists) {
																						maxWatchlistLimit.current = allWatchlists.length >= MAX_WATCHLISTS_ALLOWED;
																						if (!maxWatchlistLimit.current) {
																							displayStateRef.current = 'create';
																							newWatchlistNameRef.current = '';
																							Nullable.of(watchlistNameElementRef.current).run(
																								(element: HTMLInputElement) => (element.value = '')
																							);
																							forceRerender();
																						}
																					}
																				}

																				function onNewWatchlistNameChange(
																					event: React.ChangeEvent<HTMLInputElement>
																				): void {
																					Nullable.of(event)
																						.map((event) => event.target)
																						.map((target) => target.value)
																						.run((value) => {
																							newWatchlistNameRef.current = value.trim();
																							duplicateWatchlistNameRef.current = false;
																							promiseFactory
																								.throttle('newWatchlistNameChange', 50)
																								.then(() => forceRerender());
																						});
																				}
																				return (
																					<>
																						<div
																							className={cn(
																								styles.watchlistPanel,
																								styles.addToWatchlistPanel,
																								displayStateRef.current === 'select' && selectedInstrument
																									? null
																									: styles.hidden
																							)}
																						>
																							<div className={cn(styles.itemContainer)}>
																								<div
																									className={cn(styles.itemContent, styles.textCenter, styles.textBold)}
																								>
																									<span>{t('wtr:SELECT_WATCHLIST')}</span>
																								</div>
																							</div>
																							<div className={styles.watchlists}>
																								{allWatchlistsLocal &&
																									selectedInstrument &&
																									allWatchlistsLocal.map((watchlist) => {
																										if (
																											instrumentExistsInDynamicWatchlists(
																												watchlist.name,
																												dashboardContext.defaultWatchLists,
																												isSpreadBettingAccount
																											)
																										) {
																											return null;
																										}

																										const existsInWatchlist = isInstrumentExistsInWatchlist(
																											tradingMode,
																											watchlist.name,
																											selectedInstrument.code
																										);
																										const maxInstruments = appContext.isJapanAccount
																											? MAX_INSTRUMENTS_ALLOWED_JP
																											: MAX_INSTRUMENTS_ALLOWED;
																										const isWatchlistMaxed =
																											watchlist.instruments.length >= maxInstruments;
																										const disableIcon =
																											isWatchlistMaxed && selectedInstrument && !existsInWatchlist
																												? styles.disabledIcon
																												: styles.addToWatchlistIcon;

																										return (
																											<div
																												key={watchlist.name}
																												className={
																													selectedInstrument &&
																													isWatchlistMaxed &&
																													!isInstrumentExistsInWatchlist(
																														tradingMode,
																														watchlist.name,
																														selectedInstrument.code
																													)
																														? styles.maxedContainer
																														: styles.itemContainer
																												}
																												onMouseDown={() =>
																													updateWatchlist(
																														selectedInstrument as IMarketItemRecord,
																														watchlist.name,
																														existsInWatchlist ? 'remove' : 'add',
																														true,
																														isWatchlistMaxed,
																														triggerLastClickedCell
																													)
																												}
																											>
																												<div
																													className={
																														isWatchlistMaxed
																															? styles.maxedItemContent
																															: styles.itemContent
																													}
																												>
																													<div className={styles.addToWatchlistContainer}>
																														<span className={styles.selectWatchlistName}>
																															{getTranslatedWLName(watchlist.name)}
																														</span>

																														<div className={styles.watchlistAddRemoveIcon}>
																															<FontAwesomeIcon
																																icon={
																																	existsInWatchlist
																																		? ['fas', 'check-circle']
																																		: ['far', 'plus-circle']
																																}
																																className={
																																	existsInWatchlist
																																		? styles.existsInWatchlistIcon
																																		: disableIcon
																																}
																															/>
																														</div>
																													</div>

																													{isWatchlistMaxed &&
																														selectedInstrument &&
																														!isInstrumentExistsInWatchlist(
																															tradingMode,
																															watchlist.name,
																															selectedInstrument.code
																														) && (
																															<span className={styles.limitMessage}>
																																{t('wtr:MAX_INSTRUMENT_ERROR')}
																															</span>
																														)}
																												</div>
																											</div>
																										);
																									})}
																							</div>
																							<CreateWatchlistButton
																								maxWatchlistsError={maxWatchlistLimit.current}
																								handleCreateWatchlist={onCreateWatchlistClick}
																							/>
																						</div>
																						<div
																							className={cn(
																								styles.createWatchlist,
																								displayStateRef.current === 'create' && selectedInstrument
																									? null
																									: styles.hidden
																							)}
																						>
																							<div className={styles.watchlistPanel}>
																								<div className={cn(styles.itemContainer)}>
																									<div
																										className={cn(
																											styles.watchlistName,
																											styles.textCenter,
																											styles.textBold
																										)}
																									>
																										<span>{tt('CREATE_WATCHLIST')}</span>
																									</div>
																								</div>
																								<div
																									className={cn(styles.itemContainer, styles.watchlistName)}
																									style={{ minHeight: '150px' }}
																								>
																									<div className={cn(styles.watchlistName)}>
																										<label>{t('wtr:WATCHLIST_NAME')}</label>
																										<div
																											className={cn(
																												duplicateWatchlistNameRef.current
																													? styles.errorInput
																													: styles.inputContainer
																											)}
																										>
																											<input
																												type="text"
																												ref={watchlistNameElementRef}
																												maxLength={maxWatchlistNameLength}
																												onChange={(event) => onNewWatchlistNameChange(event)}
																											/>
																										</div>
																										{/* <div className={styles.messageRow}> */}
																										{/* <span className={styles.inputCount}>{ `${newWatchlistNameRef.current.length}/${maxWatchlistNameLength}` }</span>
                                                                                </div> */}
																										{duplicateWatchlistNameRef.current && (
																											<div className={styles.inputExlpanation}>
																												{duplicateWatchlistNameRef.current && (
																													<div className={styles.errorMessage}>
																														{t('wtr:NAME_IN_USE')}
																													</div>
																												)}
																												<label style={{ textAlign: isArabic ? 'left' : 'right' }}>
																													{`${newWatchlistNameRef.current.length}/${maxWatchlistNameLength}`}
																												</label>
																											</div>
																										)}
																										{maxWatchlistLimit.current && (
																											<div>
																												{maxWatchlistLimit.current && (
																													<div className={styles.maxWatchlistError}>
																														{t('wtr:MAX_WATCHLIST_ERROR')}
																													</div>
																												)}
																											</div>
																										)}
																										{!duplicateWatchlistNameRef.current && (
																											<label>
																												{`${newWatchlistNameRef.current.length}/${maxWatchlistNameLength}`}
																											</label>
																										)}
																									</div>
																								</div>
																								<div
																									className={cn(styles.itemContainer)}
																									style={{ minHeight: '50px', borderTop: 'none' }}
																								>
																									<div className={cn(styles.itemContent)}>
																										<div className={cn(styles.actionButtonContainer)}>
																											<button
																												type="button"
																												className={cn(styles.cancel)}
																												onMouseDown={() => onCreateWatchlistCancelClick()}
																											>
																												{tt('CANCEL')}
																											</button>
																											<button
																												type="button"
																												className={
																													maxWatchlistLimit.current ? styles.disabled : styles.submit
																												}
																												onMouseDown={() => onCreateWatchlistSaveClick()}
																												disabled={newWatchlistNameRef.current.length < 1}
																											>
																												{tt('CREATE')}
																											</button>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</>
																				);
																			}}
																		/>
																	</div>
																</>
															);
														}}
													/>
												</div>
											);
										})
										.get();
									//create array of mega menu data items
									const megaMenuItems = Nullable.of(instrumentsByGroup)
										.map((instrumentsByGroup) => {
											return Array.from(instrumentsByGroup)
												.filter(([group, instruments]) => instruments.length !== 0)
												.map(([group, instruments]) => {
													const isSelected = group === instrumentSearchController.selectedGroup;

													let selectedStyling = '';

													if (isSelected) {
														// if (isJapanAccount) {
														if (isJapanSubscriptionAccount) {
															selectedStyling = styles.selectedTier;
														} else {
															selectedStyling = styles.selected;
														}
													}
													// const isTier = group === '1' || group === '2' || group === '3';
													const isTier =
														isJapanSubscriptionAccount && (group === '1' || group === '2' || group === '3');

													return {
														label: (
															<div
																className={cn(styles.itemContent, selectedStyling)}
																onMouseDown={() => {
																	instrumentSearchController.selectedGroup = group;
																	forceRerender();
																}}
															>
																{isTier ? (
																	<OverlayTrigger
																		key="ISTierInfo"
																		delay={{ show: 750, hide: 0 }}
																		placement="bottom"
																		overlay={
																			<Tooltip id="instrumentSearchCategoryTooltip" className="my-tooltip">
																				{getTierNameByNumber(parseInt(group))}
																			</Tooltip>
																		}
																	>
																	<></>
																	</OverlayTrigger>
																) : (
																	<></>
																)}
																{
																	<span className={group === 'All' ? styles.allLabel : ''}>
																		{groupTranslations[isTier ? `Tier${group}` : group]} ({instruments.length})
																	</span>
																}
															</div>
														),
														key: group,
														items: instrumentsPanel,
													};
												});
										})
										.orElseGet(() => []);

									//set active menu section based on selected group
									useEffect(() => {
										if (megaMenuItems.length > 0) {
											Nullable.of(megaMenuControllerRef.current).run((controller) => {
												controller.setActiveSection(instrumentSearchController.selectedGroup || 'All');
											});
										}
									}, [megaMenuItems, instrumentSearchController.selectedGroup]);

									//create mega menu props and render mega menu
									const megaMenuProps = {
										controllerRef: megaMenuControllerRef,
										styleConfig: {
											containerProps: {
												className: cn(styles.menuPanel),
											},
											menuProps: {
												className: cn(styles.groupPanel),
											},
											menuItemProps: {
												//className: cn(styles.groupItem)
												className: cn(styles.itemContainer),
											},
											menuItemSelectedProps: {
												//className: cn(styles.groupItemSelected)
												className: cn(styles.itemContainer),
											},
											contentProps: {
												className: cn(styles.instrumentPanel),
											},
										},
										data: megaMenuItems as unknown as MegaMenu['props']['data'],
									} as unknown as MegaMenu['props'];
									return <>{megaMenuItems.length > 0 && <MegaMenu {...megaMenuProps} />}</>;
								}}
							/>
						</div>
					);
				}}
			/>
		</>
	);
});

export default InstrumentSearch;
