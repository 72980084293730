import { HistoryTick, TimeScale } from '../../gateways/RfpGateway/rfp.types';
import Lazy from '../../utils/functions/Lazy';
import { Optional } from '../../utils/functions/Nullable';
import i18n from '../../setup/i18n';
import { GymSimulation } from '../../pages/TradersGym/Simulations/GymSimulation';

export type TChartTick = {
	DT?: Date;
	Date?: string;
	Open: number;
	High: number;
	Low: number;
	Close: number;
	Volume: number;
};

export type TChartPeriod = {
	timeScale: TimeScale;
	interval: number;
	period: number;
	timeUnit: 'millisecond' | 'second' | 'minute' | 'day' | 'week' | 'month' | 'tick';
	minHistoryTicks: number;
	getSelectionLabel: (translationProvider?: Optional<(str: string) => string>) => string;
	getChartLabel: (translationProvider?: Optional<(str: string) => string>) => string;
	createChartTicks: (historyTicks: HistoryTick[], historicalOnly: boolean) => TChartTick[];
};

enum RFP_TIMING_INTERVAL {
	TICK = 0,
	ONE_MIN = 1,
	FIVE_MIN = 2,
	FIFTEEN_MIN = 3,
	THIRTY_MIN = 4,
	SIXTY_MIN = 5,
	TWO_HOURS = 6,
	THREE_HOURS = 7,
	FOUR_HOURS = 8,
	ONE_DAY = 9,
	ONE_WEEK = 10,
	ONE_MONTH = 11,
	EIGHT_HOURS = 12,
	TWO_MIN = 14,
	THREE_MIN = 15,
	TEN_MIN = 16,
}

class ChartUtils {
	static buildSymbol(symbol: string, priceType: string) {
		return `${symbol}_#_${priceType}`;
	}
	static getPriceTypeLocalStorageKey(chartId: number) {
		return `chart${chartId}_priceType`;
	}

	static extractValues = (input: string) => {
		const regex = /([A-Z\d_.]+)_#_([A-z]+)/;
		const match = input.match(regex);
		if (match) {
			let symbol = match[1];
			if (symbol.includes(':')) {
				symbol = symbol.slice(1);
			}
			const priceType = match[2];
			return { symbol, priceType };
		} else {
			return null;
		}
	};

	/**
	 * Builds a gym symbol based on the given simulation.
	 * The gym symbol is constructed by concatenating the simulation code and ID with "_#_" separator.
	 * @param simulation The gym simulation object.
	 * @returns The gym symbol.
	 */
	static buildGymSymbol(simulation: GymSimulation) {
		return `${simulation.code}_#_${simulation.id}`;
	}

	/**
	 * Splits a string value into symbol and subscription ID components.
	 * @param value - The string value to split.
	 * @returns An object containing the symbol and subscription ID components, or undefined if the value cannot be split.
	 */
	static getGymSymbolComponents(value: string) {
		const arr = value.split('_#_');
		if (arr.length > 1) {
			return { symbol: arr[0], subscriptionId: arr[1] };
		}
		return undefined;
	}

	static fillErrorCenter(
		ctx: CanvasRenderingContext2D,
		error: string,
		color: string,
		width: number,
		height: number,
		textHight: number
	) {
		ctx.textAlign = 'center';
		ctx.globalAlpha = 1;
		ctx.fillStyle = color;

		let y = (height - textHight) / 2;
		let xL = width / 2;
		let yL = y + textHight / 3;

		// Info text
		ChartUtils.fitTextOnCanvas(ctx, error, 50, 'Helvetica, Arial', width * 0.7);
		ctx.textBaseline = 'middle';
		ctx.fillText(error, xL, yL);
	}

	static fillTextCenter(
		ctx: CanvasRenderingContext2D,
		text1: string,
		text2: string,
		color: string,
		width: number,
		height: number,
		textHight: number
	) {
		ctx.textAlign = 'center';
		ctx.globalAlpha = 0.3;
		ctx.fillStyle = color;

		let y = (height - textHight) / 2;
		let xL = width / 2;
		let yL = y + textHight / 3;

		// Info text
		ChartUtils.fitTextOnCanvas(ctx, text1, 50, 'Helvetica, Arial', width * 0.7);
		ctx.textBaseline = 'top';
		ctx.fillText(text1, xL, yL);

		// Details text
		if (height >= 120) {
			yL = y + (textHight / 3) * 2;
			ChartUtils.fitTextOnCanvas(ctx, text2, 20, 'Helvetica, Arial', width * 0.8);
			ctx.textBaseline = 'bottom';
			ctx.fillText(text2, xL, yL);
		}
	}

	static fitTextOnCanvas(
		ctx: CanvasRenderingContext2D,
		text: string,
		fontsize: number,
		fontface: string,
		width: number
	) {
		// lower the font size until the text fits the canvas
		do {
			fontsize--;
			ctx.font = fontsize + 'px ' + fontface;
		} while (ctx.measureText(text).width > width);
	}

	public static fitText(element: any, selectors: string, minFontSizeRange?: { min: number; max: number }): void {
		// max font size in pixels
		const maxFontSize = minFontSizeRange?.max ?? 50;
		const minFontSize = minFontSizeRange?.min ?? 10;
		// get the DOM output element by its selector
		const outputDiv = element.querySelector(selectors);
		if (outputDiv) {
			// get element's width and height
			let width = outputDiv.clientWidth;
			let height = outputDiv.clientHeight;
			// get content's width
			let textWidth = outputDiv.scrollWidth;
			let textHeight = outputDiv.scrollHeight;
			// get fontSize
			let fontSize = parseInt(window.getComputedStyle(outputDiv, null).getPropertyValue('font-size'), 10);
			if (!isNaN(fontSize)) {
				if (textWidth === width && textHeight === height) {
					// Need to increment font size
					// content is smaller than than width or height... let's resize in 1 px until it fits
					let didIncrementFont = false;
					while (textWidth === width && textHeight === height) {
						if (fontSize > maxFontSize) {
							break;
						}
						fontSize = Math.ceil(fontSize) + 1;
						outputDiv.style.fontSize = fontSize + 'px';

						// update width and height
						textWidth = outputDiv.scrollWidth;
						textHeight = outputDiv.scrollHeight;

						didIncrementFont = true;
					}
					if (didIncrementFont) {
						fontSize = Math.ceil(fontSize) - 1;
						outputDiv.style.fontSize = fontSize + 'px';
					}
				} else if (textWidth > width || textHeight > height) {
					// Need to decrement font size
					// content is bigger than width or height... let's resize in 1 px until it fits
					while (textWidth > width || textHeight > height) {
						if (fontSize < minFontSize) {
							break;
						}
						fontSize = Math.ceil(fontSize) - 1;
						outputDiv.style.fontSize = fontSize + 'px';

						// update width and height
						textWidth = outputDiv.scrollWidth;
						textHeight = outputDiv.scrollHeight;
					}
				}
			}
		}
	}

	private static readonly _chartPeriods = new Lazy<TChartPeriod[]>(() => [
		{
			timeScale: 'TS_1MIN',
			interval: 1,
			period: 1,
			timeUnit: 'minute',
			minHistoryTicks: 500,
			getSelectionLabel: () => i18n.t('en:TIMING_INTERVAL_ONE_MIN'),
			getChartLabel: () => i18n.t('en:TIMING_INTERVAL_ONE_MIN'),
			createChartTicks: (historyTicks, historicalOnly) => {
				return historyTicks.map((historyTick) => {
					return {
						DT: new Date(historyTick.openTime),
						Open: historyTick.open,
						High: historyTick.high,
						Low: historyTick.low,
						Close: historyTick.close,
						Volume: historyTick.volume,
					};
				});
			},
		},
		{
			timeScale: 'TS_5MIN',
			interval: 5,
			period: 1,
			timeUnit: 'minute',
			minHistoryTicks: 999,
			getSelectionLabel: () => i18n.t('en:TIMING_INTERVAL_FIVE_MIN'),
			getChartLabel: () => i18n.t('en:TIMING_INTERVAL_FIVE_MIN'),
			createChartTicks: (historyTicks, historicalOnly) => {
				return historyTicks.map((historyTick) => {
					return {
						DT: new Date(historyTick.openTime),
						Open: historyTick.open,
						High: historyTick.high,
						Low: historyTick.low,
						Close: historyTick.close,
						Volume: historyTick.volume,
					};
				});
			},
		},
		{
			timeScale: 'TS_10MIN',
			interval: 10,
			period: 1,
			timeUnit: 'minute',
			minHistoryTicks: 999,
			getSelectionLabel: () => i18n.t('en:TIMING_INTERVAL_TEN_MIN'),
			getChartLabel: () => i18n.t('en:TIMING_INTERVAL_TEN_MIN'),
			createChartTicks: (historyTicks, historicalOnly) => {
				return historyTicks.map((historyTick) => {
					return {
						DT: new Date(historyTick.openTime),
						Open: historyTick.open,
						High: historyTick.high,
						Low: historyTick.low,
						Close: historyTick.close,
						Volume: historyTick.volume,
					};
				});
			},
		},
		{
			timeScale: 'TS_15MIN',
			interval: 15,
			period: 1,
			timeUnit: 'minute',
			minHistoryTicks: 999,
			getSelectionLabel: () => i18n.t('en:TIMING_INTERVAL_FIFTEEN_MIN'),
			getChartLabel: () => i18n.t('en:TIMING_INTERVAL_FIFTEEN_MIN'),
			createChartTicks: (historyTicks, historicalOnly) => {
				return historyTicks.map((historyTick) => {
					return {
						DT: new Date(historyTick.openTime),
						Open: historyTick.open,
						High: historyTick.high,
						Low: historyTick.low,
						Close: historyTick.close,
						Volume: historyTick.volume,
					};
				});
			},
		},
		{
			timeScale: 'TS_30MIN',
			interval: 30,
			period: 1,
			timeUnit: 'minute',
			minHistoryTicks: 999,
			getSelectionLabel: () => i18n.t('en:TIMING_INTERVAL_THIRTY_MIN'),
			getChartLabel: () => i18n.t('en:TIMING_INTERVAL_THIRTY_MIN'),
			createChartTicks: (historyTicks, historicalOnly) => {
				return historyTicks.map((historyTick) => {
					return {
						DT: new Date(historyTick.openTime),
						Open: historyTick.open,
						High: historyTick.high,
						Low: historyTick.low,
						Close: historyTick.close,
						Volume: historyTick.volume,
					};
				});
			},
		},
		{
			timeScale: 'TS_1HOUR',
			interval: 60,
			period: 1,
			timeUnit: 'minute',
			minHistoryTicks: 999,
			getSelectionLabel: () => i18n.t('en:TIMING_INTERVAL_SIXTY_MIN'),
			getChartLabel: () => i18n.t('en:TIMING_INTERVAL_SIXTY_MIN'),
			createChartTicks: (historyTicks, historicalOnly) => {
				return historyTicks.map((historyTick) => {
					return {
						DT: new Date(historyTick.openTime),
						Open: historyTick.open,
						High: historyTick.high,
						Low: historyTick.low,
						Close: historyTick.close,
						Volume: historyTick.volume,
					};
				});
			},
		},
		{
			timeScale: 'TS_4HOUR',
			interval: 240,
			period: 1,
			timeUnit: 'minute',
			minHistoryTicks: 999,
			getSelectionLabel: () => i18n.t('en:TIMING_INTERVAL_FOUR_HOURS'),
			getChartLabel: () => i18n.t('en:TIMING_INTERVAL_FOUR_HOURS'),
			createChartTicks: (historyTicks, historicalOnly) => {
				return historyTicks.map((historyTick) => {
					return {
						DT: new Date(historyTick.openTime),
						Open: historyTick.open,
						High: historyTick.high,
						Low: historyTick.low,
						Close: historyTick.close,
						Volume: historyTick.volume,
					};
				});
			},
		},
		{
			timeScale: 'TS_1DAY',
			interval: 1,
			period: 1,
			timeUnit: 'day',
			minHistoryTicks: 500,
			getSelectionLabel: () => i18n.t('en:TIMING_INTERVAL_ONE_DAY'),
			getChartLabel: () => i18n.t('en:TIMING_INTERVAL_ONE_DAY'),
			createChartTicks: (historyTicks, historicalOnly) => {
				const chartTicks: TChartTick[] = [];
				historyTicks.forEach((historyTick) => {
					const date = new Date(historyTick.closeTime);
					// There is no way to define the open time, so we are using close time minus 1 min
					date.setUTCMinutes(date.getUTCMinutes() - 1);

					const dateString = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
						.getUTCDate()
						.toString()
						.padStart(2, '0')}`;
					//console.log(`One day: dateString=${dateString}, openTime=${openTime}, closeTime=${historyTick.t}`)
					if (!(historicalOnly && chartTicks.some((tick) => tick.Date === dateString))) {
						const chartTick = {
							Date: dateString,
							Open: historyTick.open,
							High: historyTick.high,
							Low: historyTick.low,
							Close: historyTick.close,
							Volume: historyTick.volume,
						};
						chartTicks.push(chartTick);
						//console.debug(`TS_1DAY: dateString: ${dateString}, date: ${date}, utcDate: ${date.toUTCString()}, ts: ${historyTick.t}, dateTS: ${date.getTime()}, tzOffset: ${date.getTimezoneOffset()}`)
					}
				});
				return chartTicks;
			},
		},
		{
			timeScale: 'TS_1WEEK',
			interval: 1,
			period: 1,
			timeUnit: 'week',
			minHistoryTicks: 500,
			getSelectionLabel: () => i18n.t('wtr:1_WEEK_TP'),
			getChartLabel: () => i18n.t('wtr:1_WEEK_TP'),
			createChartTicks: (historyTicks, historicalOnly) => {
				const chartTicks: TChartTick[] = [];
				historyTicks.forEach((historyTick) => {
					const date = new Date(historyTick.openTime);

					const dateString = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
						.getUTCDate()
						.toString()
						.padStart(2, '0')}`;
					if (!(historicalOnly && chartTicks.some((tick) => tick.Date === dateString))) {
						const chartTick = {
							Date: dateString,
							Open: historyTick.open,
							High: historyTick.high,
							Low: historyTick.low,
							Close: historyTick.close,
							Volume: historyTick.volume,
						};
						chartTicks.push(chartTick);
						//console.debug(`TS_1WEEK: dateString: ${dateString}, date: ${date}, utcDate: ${date.toUTCString()}, ts: ${historyTick.t}, dateTS: ${date.getTime()}, tzOffset: ${date.getTimezoneOffset()}`)
					}
				});
				return chartTicks;
			},
		},
		{
			timeScale: 'TS_1MONTH',
			interval: 1,
			period: 1,
			timeUnit: 'month',
			minHistoryTicks: 500,
			getSelectionLabel: () => i18n.t('wtr:1_MONTH_TP'),
			getChartLabel: () => i18n.t('wtr:1_MONTH_TP'),
			createChartTicks: (historyTicks, historicalOnly) => {
				const chartTicks: TChartTick[] = [];
				historyTicks.forEach((historyTick) => {
					const date = new Date(historyTick.openTime);
					const dateString = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
						.getUTCDate()
						.toString()
						.padStart(2, '0')}`;
					if (!(historicalOnly && chartTicks.some((tick) => tick.Date === dateString))) {
						const chartTick = {
							Date: dateString,
							Open: historyTick.open,
							High: historyTick.high,
							Low: historyTick.low,
							Close: historyTick.close,
							Volume: historyTick.volume,
						};
						chartTicks.push(chartTick);
						//console.debug(`TS_1MONTH: dateString: ${dateString}, date: ${date}, utcDate: ${date.toUTCString()}, ts: ${historyTick.t}, dateTS: ${date.getTime()}, tzOffset: ${date.getTimezoneOffset()}`)
					}
				});
				return chartTicks;
			},
		},
	]);

	public static get chartPeriods(): ReadonlyArray<Readonly<TChartPeriod>> {
		return this._chartPeriods.getValue();
	}

	static getSecondsFromTimeScale(timeScale: TimeScale) {
		const rfpTimeInterval = ChartUtils.getRFPTimingInterval(timeScale);
		return ChartUtils.getSecondsFromTimeInterval(rfpTimeInterval);
	}

	public static getChartPeriodAt(timeScale: TimeScale): Readonly<TChartPeriod> | undefined {
		const item = this.chartPeriods.find((value) => {
			return value.timeScale === timeScale;
		});
		return item;
	}

	private constructor() {}

	public static formatChartData(
		values: HistoryTick[],
		chartPeriod: TChartPeriod,
		historicalOnly: boolean = false
	): TChartTick[] {
		return values.length > 0 && chartPeriod !== null ? chartPeriod.createChartTicks(values, historicalOnly) : [];
	}

	public static getChartPeriod(predicate: (chartPeriod: TChartPeriod) => boolean): Optional<TChartPeriod> {
		const chartPeriods = this.chartPeriods.filter(predicate);
		return chartPeriods.length > 0 ? chartPeriods[0] : null;
	}

	static keyShowPositions = 'chart_setting_show_positions';
	static keyShowOrders = 'chart_setting_show_orders';

	public static getOpenTime(historyTick: HistoryTick, interval: RFP_TIMING_INTERVAL) {
		if (interval === RFP_TIMING_INTERVAL.ONE_MONTH) {
			const date = new Date(historyTick.closeTime);
			date.setUTCMinutes(date.getUTCMinutes() - 1);
			date.setUTCDate(date.getUTCDate() - 7);
			date.setHours(23, 59, 59, 999);
			return date.getTime();
		}

		if (historyTick.openTime) {
			return historyTick.openTime;
		}
		const closeTime = historyTick.closeTime;
		const openTime = closeTime - ChartUtils.getSecondsFromTimeInterval(interval) * 1000;
		return openTime;
	}

	public static getIntervalAndPeriodicity(timeScale: TimeScale) {
		switch (timeScale) {
			case 'TS_1MIN':
				return { interval: 'minute', periodicity: 1 };
			case 'TS_2MIN':
				return { interval: 'minute', periodicity: 2 };
			case 'TS_3MIN':
				return { interval: 'minute', periodicity: 3 };
			case 'TS_5MIN':
				return { interval: 'minute', periodicity: 5 };
			case 'TS_10MIN':
				return { interval: 'minute', periodicity: 10 };
			case 'TS_15MIN':
				return { interval: 'minute', periodicity: 15 };
			case 'TS_30MIN':
				return { interval: 'minute', periodicity: 30 };
			case 'TS_1HOUR':
				return { interval: 'minute', periodicity: 60 };
			case 'TS_2HOUR':
				return { interval: 'minute', periodicity: 120 };
			case 'TS_3HOUR':
				return { interval: 'minute', periodicity: 180 };
			case 'TS_4HOUR':
				return { interval: 'minute', periodicity: 240 };
			case 'TS_6HOUR':
				return { interval: 'minute', periodicity: 360 };
			case 'TS_8HOUR':
				return { interval: 'minute', periodicity: 480 };
			case 'TS_12HOUR':
				return { interval: 'minute', periodicity: 720 };
			case 'TS_1DAY':
				return { interval: 'day', periodicity: 1 };
			case 'TS_1WEEK':
				return { interval: 'week', periodicity: 1 };
			case 'TS_1MONTH':
				return { interval: 'month', periodicity: 1 };
		}
	}

	public static getSecondsFromTimeInterval(interval: RFP_TIMING_INTERVAL) {
		switch (interval) {
			case RFP_TIMING_INTERVAL.ONE_MIN:
				return 60;
			case RFP_TIMING_INTERVAL.TWO_MIN:
				return 120;
			case RFP_TIMING_INTERVAL.THREE_MIN:
				return 180;
			case RFP_TIMING_INTERVAL.FIVE_MIN:
				return 300;
			case RFP_TIMING_INTERVAL.TEN_MIN:
				return 600;
			case RFP_TIMING_INTERVAL.FIFTEEN_MIN:
				return 900;
			case RFP_TIMING_INTERVAL.THIRTY_MIN:
				return 1800;
			case RFP_TIMING_INTERVAL.SIXTY_MIN:
				return 3600;
			case RFP_TIMING_INTERVAL.TWO_HOURS:
				return 7200;
			case RFP_TIMING_INTERVAL.THREE_HOURS:
				return 10800;
			case RFP_TIMING_INTERVAL.FOUR_HOURS:
				return 14400;
			case RFP_TIMING_INTERVAL.EIGHT_HOURS:
				return 28800;
			case RFP_TIMING_INTERVAL.ONE_DAY:
				return 86400;
			case RFP_TIMING_INTERVAL.ONE_WEEK:
				return 604800;
			case RFP_TIMING_INTERVAL.ONE_MONTH:
				return 40320 * 60;
		}
		return 0;
	}

	public static getRFPTimingInterval(timeScale: TimeScale) {
		switch (timeScale) {
			case 'TS_1MIN':
				return RFP_TIMING_INTERVAL.ONE_MIN;
			case 'TS_2MIN':
				return RFP_TIMING_INTERVAL.TWO_MIN;
			case 'TS_3MIN':
				return RFP_TIMING_INTERVAL.THREE_MIN;
			case 'TS_5MIN':
				return RFP_TIMING_INTERVAL.FIVE_MIN;
			case 'TS_10MIN':
				return RFP_TIMING_INTERVAL.TEN_MIN;
			case 'TS_15MIN':
				return RFP_TIMING_INTERVAL.FIFTEEN_MIN;
			case 'TS_30MIN':
				return RFP_TIMING_INTERVAL.THIRTY_MIN;
			case 'TS_1HOUR':
				return RFP_TIMING_INTERVAL.SIXTY_MIN;
			case 'TS_2HOUR':
				return RFP_TIMING_INTERVAL.TWO_HOURS;
			case 'TS_3HOUR':
				return RFP_TIMING_INTERVAL.THREE_HOURS;
			case 'TS_4HOUR':
				return RFP_TIMING_INTERVAL.FOUR_HOURS;
			case 'TS_8HOUR':
				return RFP_TIMING_INTERVAL.EIGHT_HOURS;
			case 'TS_1DAY':
				return RFP_TIMING_INTERVAL.ONE_DAY;
			case 'TS_1WEEK':
				return RFP_TIMING_INTERVAL.ONE_WEEK;
			case 'TS_1MONTH':
				return RFP_TIMING_INTERVAL.ONE_MONTH;
		}
		return 0;
	}
}

export default ChartUtils;
