import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import cn from 'classnames';

import { default as RfpGatewayContext } from '../../../contexts/RfpGatewayContext';
import { default as AppContext } from '../../../contexts/AppContext';
import { default as DashboardContext } from '../../../contexts/DashboardContext';

import { RFP } from '../../../gateways/RfpGateway/rfpConstants';
import { MarketItem, PriceQuote } from '../../../gateways/RfpGateway/rfp.types';
import { default as usePromiseFactory } from '../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../utils/hooks/useForceRerender';
import { Optional } from '../../../utils/functions/Nullable';

import Button from '../../../shared/Button/Button';
import WtrPopup from '../WtrPopup/WtrPopup';
import Instrument from '../../features/Dashboard/Watchlist/Instrument/Instrument';
import { formatNumberWithCommas } from '../../features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';

import { instrumentGroupProps } from '../../../utils/functions/constants';

import useShortTranslation from '../../../utils/hooks/useShortTranslation';
import {
	DEFAULT_FEED_ID,
	MAX_WATCHLISTS_ALLOWED,
	createNewWatchlist,
	MAX_INSTRUMENTS_ALLOWED_JP,
	MAX_INSTRUMENTS_ALLOWED,
} from '../../../utils/functions/WatchlistUtils';
import usePreferredFeedId from '../../../utils/hooks/usePreferredFeedId';
import WatchlistsList from '../../features/Dashboard/Watchlist/Lists';
import CreateWatchlistButton from '../../features/Dashboard/Watchlist/CreateWatchlistButton';
import useGetTranslatedWLName from '../../../utils/hooks/useGetTranslatedWLName';
import DailyPercent from '../DailyPercent/DailyPercent';
import { TicketLayout, TradingPositionLimitType, TradingPositionType } from '../../../utils/functions/enums';
import { TradersGymContext, TradersGymContextType } from '../../../pages/TradersGym/TradersGymContext';
import useSaveWatchlistToPreferences from '../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import useSelectedTradingAccount from '../../../utils/hooks/useSelectedTradingAccount';
import tradingAccountStore from '../../../store/tradingAccountStore';
import watchListStore from '../../../store/WatchListStore/watchListStore';
import InstrumentIcon from '../GroupBadge/InstrumentIcon';
import TradingSessionTooltip from '../Shared/TradingSessionTooltip';
import tradingViewStore from '../../../store/tradingViewStore';
import authStore from '../../../store/authStore';
import BidAskSpread from '../BidAskSpread/BidAskSpread';
import StringUtils from '../../../utils/functions/StringUtils';

import styles from './InstrumentHeader.module.scss';

type InstrumentHeaderProps = {
	showButtons?: boolean;
	resizeHeader?: boolean;
	headerActions?: boolean;
	undock?: any;
	showBidAskSpread?: boolean;
	bidAskSpreadData?: BidAskSpreadDataProps;
	onCloseButtonCLick?: () => void;
	redirectFromDashboard: boolean;
};
type BidAskSpreadDataProps = {
	instrument: PriceQuote | undefined;
	marketItem: MarketItem | undefined;
	order: boolean;
	typeOfOrder: TradingPositionType | undefined;
	isBidAskDisabled: boolean;
	ticketLayout: string;
	current: TradingPositionLimitType[] | undefined;
};

const InstrumentHeader = React.memo<InstrumentHeaderProps>(
	({
		showButtons,
		resizeHeader,
		headerActions,
		undock,
		showBidAskSpread,
		bidAskSpreadData,
		onCloseButtonCLick,
		redirectFromDashboard,
	}) => {
		const saveWatchlistToPreferences = useSaveWatchlistToPreferences();

		const appContext = useContext(AppContext);
		const dashboardContext = useContext(DashboardContext);
		const rfpGatewayContext = useContext(RfpGatewayContext);
		const gymContext = useContext(TradersGymContext) as TradersGymContextType;
		const { tradersGymContext } = gymContext;

		const tradingMode = authStore.use.tradingMode();

		const isChildWindow = appContext.isChildWindow || false;
		const getCurrentWatchList = watchListStore.use.getCurrentWatchList();
		const currentWatchList = getCurrentWatchList(isChildWindow);
		const getWatchlists = watchListStore.use.getWatchlists();
		const getSelectedWatchlist = watchListStore.use.getSelectedWatchlist();
		const selectedWatchlist = getSelectedWatchlist(tradingMode, isChildWindow);
		const allWatchlists = getWatchlists(tradingMode);

		const promiseFactory = usePromiseFactory();
		const forceRerender = useForceRerender();
		const { t } = useTranslation();
		const tt = useShortTranslation('en:');

		const [openWatchlist, setOpenWatchlist] = useState<boolean>(false);
		const [create, setCreate] = useState<boolean>(false);
		const [listName, setListName] = useState<string>('');
		const [disabled, setDisabled] = useState<boolean>(false);
		const [currentPriceQuote, setCurrentPriceQuote] = useState<Optional<PriceQuote>>(undefined);

		const subIdRef = useRef<string | undefined>(undefined);

		const [high, setHigh] = useState<string | any>('0');
		const [low, setLow] = useState<string | any>('0');

		const [nameError, setNameError] = useState<string>('');
		const [maxWatchlistsError, setMaxWatchlistsError] = useState(false);
		const getTranslatedWLName = useGetTranslatedWLName();

		const isArabic = appContext.isArabic;
		const languageSettings = appContext.languageSettings;
		const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
		const watchlist = dashboardContext.watchlist;
		const marketItem = dashboardContext.selectedInstrument;
		const marketItems = dashboardContext.marketItems;
		const activeTradingAccount = useSelectedTradingAccount();
		const preferredFeedId = usePreferredFeedId(activeTradingAccount);
		const isTradersGymActive = tradersGymContext.isActive;

		const traderGymPriceQuote = tradersGymContext.priceQuote;
		const closedTicket = dashboardContext.modalToggle.closePosition;
		const openOrderInformation = tradingViewStore.use.openOrderInformation();
		const setOpenOrderInformation = tradingViewStore.use.setOpenOrderInformation();
		const ticketLayout = tradingViewStore.use.ticketLayout();
		const setDockStylesTicket = tradingViewStore.use.setDockStylesTicket();

		useEffect(() => {
			setDockStylesTicket(redirectFromDashboard);
		}, []);

		useObservable(
			appContext.getPropertyChangeStream('appTheme', 'languageSettings', 'accountStats'),
			async (_change) => {
				await promiseFactory.throttle('appContext.propertyChanged', 100);
				forceRerender();
			}
		);

		useObservable(
			dashboardContext.getPropertyChangeStream(
				'watchlist',
				'selectedPosition',
				'selectedInstrument',
				'marketItems',
				'tradingAccount',
				'previousDayClosePrices'
			),
			async (_change) => {
				await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
				forceRerender();
			}
		);

		useEffect(() => {
			listName.trim().length === 0 && setNameError('');
		}, [listName]);

		useEffect(() => {
			if (allWatchlists && allWatchlists.length >= MAX_WATCHLISTS_ALLOWED) {
				return setDisabled(true);
			} else if (listName.trim().length === 0) {
				return setDisabled(true);
			} else {
				return setDisabled(false);
			}
		}, [listName, allWatchlists]);

		useEffect(() => {
			if (allWatchlists && allWatchlists.length >= MAX_WATCHLISTS_ALLOWED) {
				setMaxWatchlistsError(true);
			} else {
				setMaxWatchlistsError(false);
			}
		}, [openWatchlist, allWatchlists]);

		useEffect(() => {
			if (marketItem && rfpGatewayContext) {
				rfpGatewayContext.send(RFP.queuePreviousDayClosePrice, { code: [marketItem.code] });
			}
		}, [marketItem, rfpGatewayContext]);

		/**
		 * Checks if the instrument exists in a watchlist
		 */
		const existsInWatchlist = useCallback(() => {
			if (allWatchlists && marketItem) {
				return allWatchlists.some((watchlist) => watchlist.instruments.includes(marketItem.code));
			}
			return false;
		}, [allWatchlists, marketItem]);

		const DisplayIcon = () => {
			const instrumentExists = existsInWatchlist();

			return (
				<FontAwesomeIcon
					icon={instrumentExists ? ['fas', 'check-circle'] : ['far', 'plus-circle']}
					className={instrumentExists ? styles.existsInWatchlistIcon : styles.addToWatchlistIcon}
					onClick={handleOpenWatchlistMenu}
					id="icon"
				/>
			);
		};

		const handleOpenWatchlistMenu = (_e: any) => {
			//If only 1 watchlist is present, then add or remove the instrument directly
			let preferenceToUpdate = watchlist.find((list) => list._name === currentWatchList);
			if (allWatchlists && allWatchlists.length === 1 && marketItem && selectedWatchlist) {
				const maxInstruments = appContext.isJapanAccount ? MAX_INSTRUMENTS_ALLOWED_JP : MAX_INSTRUMENTS_ALLOWED;
				const isMaxedOut = selectedWatchlist.instruments.length >= maxInstruments;
				if (preferenceToUpdate) {
					const isInstrumentExistsInWatchlist = selectedWatchlist.instruments.includes(marketItem.code);
					if (!isInstrumentExistsInWatchlist && !isMaxedOut) {
						preferenceToUpdate.instrument = [...preferenceToUpdate.instrument, { _code: marketItem.code }];
					} else {
						preferenceToUpdate.instrument = preferenceToUpdate.instrument.filter(
							(symbol) => symbol._code !== marketItem.code
						);
					}
					dashboardContext.watchlist = watchlist;
					saveWatchlistToPreferences(dashboardContext.watchlist, isSpreadBettingAccount);
				}
			} else {
				setOpenWatchlist(!openWatchlist);
				setCreate(false);
			}
		};

		const handleCreateWatchlist = () => {
			if (!maxWatchlistsError) {
				setCreate(!create);
				setOpenWatchlist(!openWatchlist);
			}
		};

		const handleInputChange = (e: any) => {
			setListName(e.target.value);
			nameError && setNameError('');
		};

		const handleCancelWatchlist = () => {
			setCreate(false);
			setNameError('');
			setListName('');
		};

		const verifyWatchlistName = () => {
			if (allWatchlists) {
				const validateName = allWatchlists.some((watchlist) => watchlist.name === listName.trim());
				if (!validateName) {
					setNameError('');
					handleAddNewWatchlist();
				} else {
					setNameError(t('wtr:NAME_IN_USE'));
				}
			}
		};

		const handleAddNewWatchlist = () => {
			if (listName.trim().length > 0 && allWatchlists && marketItem && allWatchlists.length < MAX_WATCHLISTS_ALLOWED) {
				const feedId = StringUtils.isNullOrEmpty(preferredFeedId) ? DEFAULT_FEED_ID : preferredFeedId;
				const newWatchlistEntry = createNewWatchlist(listName.trim(), feedId, [{ _code: marketItem.code }]);
				dashboardContext.watchlist.push(newWatchlistEntry);
				saveWatchlistToPreferences(dashboardContext.watchlist);
				setListName('');
				setCreate(!create);
				setOpenWatchlist(false);
			}
		};

		useEffect(() => {
			if (traderGymPriceQuote) {
				setCurrentPriceQuote(traderGymPriceQuote);
			}
		}, [traderGymPriceQuote]);

		useEffect(() => {
			if (marketItem && rfpGatewayContext) {
				// unsubscribe previous quotes
				if (subIdRef.current) {
					rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
				}

				// subscribe quotes
				subIdRef.current = rfpGatewayContext.subscribePriceQuote(marketItem.feedId, [marketItem.code], (priceQuote) => {
					if (!isTradersGymActive) {
						setCurrentPriceQuote(priceQuote);
					}
				});
			}
			return () => {
				// unsubscribe quotes on unmount
				if (rfpGatewayContext && subIdRef.current) {
					rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
					subIdRef.current = undefined;
				}
			};
		}, [marketItem, rfpGatewayContext]);

		useEffect(() => {
			if (currentPriceQuote && marketItem) {
				setHigh(currentPriceQuote.h.toFixed(marketItem.decPrec));
				setLow(currentPriceQuote.l.toFixed(marketItem.decPrec));
			}
		}, [currentPriceQuote]);

		const tooltip = useMemo(() => {
			let watchlistsNames: string[] = [];
			let inWatchlist: boolean = false;
			if (allWatchlists && marketItem) {
				inWatchlist = existsInWatchlist();
				if (inWatchlist) {
					watchlistsNames = [
						...allWatchlists
							.filter((watchlist) => watchlist.instruments.includes(marketItem.code))
							.map((watchlist) => watchlist.name),
					];
					watchlistsNames.forEach((watchlistName, index, thisArray) => {
						thisArray[index] = getTranslatedWLName(watchlistName);
					});
				}
			}
			return inWatchlist ? (
				<div>
					{t('wtr:EXISTS_IN')} {'"' + watchlistsNames.join(' ", " ') + '"'}
				</div>
			) : (
				<div
					dangerouslySetInnerHTML={{
						__html: t('wtr:ADD_TO_WATCHLIST', {
							instrument: marketItem?.displayName,
						}),
					}}
				></div>
			);
		}, [marketItem, allWatchlists]);

		const handleOpenInfoClick = () => {
			setOpenOrderInformation(!openOrderInformation);
		};

		return (
			<div
				className={cn(
					resizeHeader ? styles.resizedWrapper : styles.wrapper,
					ticketLayout === TicketLayout.Undock && styles.undocked
				)}
			>
				<div className={styles.headerContainer}>
					{ticketLayout === TicketLayout.Undock &&
						redirectFromDashboard &&
						!dashboardContext.newOrderModalToggle.confirmOrder &&
						!dashboardContext.showConfirmTicket &&
						!closedTicket && (
							<div className={styles.infoIcon} onClick={handleOpenInfoClick}>
								<FontAwesomeIcon icon={openOrderInformation ? ['fal', 'chevron-left'] : ['fal', 'info-circle']} />
							</div>
						)}
					<div className={styles.title}>
						{marketItem && (
							<div className={styles.iconContainer}>
								<OverlayTrigger
									key="instrumentCategoryInfo"
									delay={{ show: 750, hide: 0 }}
									placement="bottom"
									overlay={
										<Tooltip id="watchlistIconInstrumentHeader" className="my-tooltip">
											{marketItem.grp && t(instrumentGroupProps[marketItem.grp]?.name)}
										</Tooltip>
									}
								>
									<InstrumentIcon marketItem={marketItem} />
								</OverlayTrigger>
							</div>
						)}
						<div>
							<div className={styles.instrumentStats}>
								<span className={styles.shortInstrumentName}>{marketItem && marketItem.displayName}</span>
								{marketItem && <TradingSessionTooltip marketItem={marketItem} />}

								{!isTradersGymActive && (
									<DailyPercent
										currentPriceQuote={currentPriceQuote}
										decimalPrecision={marketItem ? marketItem.decPrec : 0}
									/>
								)}
							</div>
							{/* Full instrument name */}
							<div className={styles.instrumentFullName}>{marketItem && marketItem.fullName}</div>
						</div>
					</div>
					{headerActions ? (
						!isTradersGymActive && (
							<div className={styles.iconGroup}>
								<WtrPopup
									open={openWatchlist}
									onClose={() => setOpenWatchlist(false)}
									className={styles.popupContainer}
									on="click"
									position="bottom right"
									trigger={
										<OverlayTrigger
											key="watchlistIconInstrumentHeader"
											delay={{ show: 750, hide: 0 }}
											placement={isArabic ? 'right' : 'left'}
											overlay={
												<Tooltip id="watchlistIconInstrumentHeader" className="my-tooltip">
													{tooltip}
												</Tooltip>
											}
										>
											<DisplayIcon />
										</OverlayTrigger>
									}
									content={
										<div className={styles.watchlistsListContainer}>
											<WatchlistsList />
											<CreateWatchlistButton
												maxWatchlistsError={maxWatchlistsError}
												handleCreateWatchlist={handleCreateWatchlist}
											/>
										</div>
									}
								/>
							</div>
						)
					) : (
						<div className={styles.closeTicket}>
							{!openOrderInformation &&
								!dashboardContext.newOrderModalToggle.confirmOrder &&
								!dashboardContext.showConfirmTicket && (
									<div className={styles.iconContainer}>
										<OverlayTrigger
											key="detachOrderTicket"
											delay={{ show: 750, hide: 0 }}
											placement={isArabic ? 'right' : 'left'}
											overlay={
												<Tooltip className="my-tooltip">
													{ticketLayout === TicketLayout.Undock ? t('en:ATTACH') : t('en:DETACH')}
												</Tooltip>
											}
										>
											<div
												className={cn({
													[styles.icon]: true,
													[styles.iconDetached]: ticketLayout === TicketLayout.Undock,
													[styles.iconAtached]: ticketLayout === TicketLayout.Dock,
												})}
											>
												<FontAwesomeIcon
													icon={
														ticketLayout === TicketLayout.Dock
															? ['fal', 'square-arrow-up-right']
															: ['fal', 'square-arrow-down-left']
													}
													onClick={undock}
												/>
											</div>
										</OverlayTrigger>

										{ticketLayout === TicketLayout.Undock && (
											<div className={cn(styles.icon, styles.iconDetached)}>
												<FontAwesomeIcon icon={['fal', 'xmark-large']} onClick={onCloseButtonCLick} />
											</div>
										)}
									</div>
								)}
						</div>
					)}
					{create && (
						<div className={styles.createContainer}>
							<div className={styles.createHeader}>
								<span>{tt('CREATE_WATCHLIST')}</span>
							</div>
							<div className={styles.inputContainer}>
								<span className={styles.inputTitle}>{t('wtr:WATCHLIST_NAME')}</span>
								<input
									className={nameError ? styles.errorInput : styles.input}
									type="text"
									maxLength={20}
									value={listName}
									onChange={handleInputChange}
								/>
								<div className={styles.messageRow}>
									{nameError.length > 0 && <div className={styles.errorMessage}>{nameError}</div>}
									<span className={styles.inputCount}>{listName.trim().length}/20</span>
								</div>
							</div>
							<div className={styles.buttonContainer}>
								<Button variant="secondary" size="sm" label={tt('CANCEL')} onClick={handleCancelWatchlist} />
								<Button
									variant="primary"
									size="sm"
									label={tt('CREATE')}
									disabled={disabled}
									onClick={verifyWatchlistName}
								/>
							</div>
						</div>
					)}
				</div>
				{!openOrderInformation &&
					showBidAskSpread &&
					bidAskSpreadData &&
					bidAskSpreadData.marketItem &&
					!bidAskSpreadData.current && (
						<BidAskSpread
							instrument={bidAskSpreadData.instrument}
							marketItem={bidAskSpreadData.marketItem}
							order={bidAskSpreadData.order}
							typeOfOrder={bidAskSpreadData.typeOfOrder}
							isBidAskDisabled={bidAskSpreadData.isBidAskDisabled}
							ticketLayout={bidAskSpreadData.ticketLayout}
						/>
					)}
				<div className={styles.highLowBox}>
					<div className={styles.textTitleLow}>
						{tt('LOW')}:{' '}
						<span className={styles.lowValue}>
							{isNaN(+low.toString()?.replaceAll(',', ''))
								? t('wtr:NA')
								: formatNumberWithCommas(parseFloat(low), marketItem?.decPrec || 0, languageSettings)}
						</span>
					</div>
					<div className={styles.textTitleHigh}>
						{tt('HIGH')}:{' '}
						<span className={styles.highValue}>
							{isNaN(+high.toString()?.replaceAll(',', ''))
								? t('wtr:NA')
								: formatNumberWithCommas(parseFloat(high), marketItem?.decPrec || 0, languageSettings)}
						</span>
					</div>
				</div>

				{showButtons && marketItems && marketItem && (
					<div className={styles.buttons}>
						<Instrument
							marketItem={marketItem}
							showPriceStats={false}
							spreadPosition={true}
							resizeButton={true}
							showInstrumentName={false}
							showButtonText={true}
							index={1}
							parent="instrumentHeader"
							onChartClick={() => console.log('clicked')}
							buttonsClass={styles.bidAskSpreadButtons}
						/>
					</div>
				)}
			</div>
		);
	},
	(prevProps, nextProps) => {
		const keys: Array<keyof typeof prevProps> = ['showButtons', 'resizeHeader', 'headerActions', 'undock'];
		return keys.every((key) => prevProps[key] === nextProps[key]);
	}
);

export default InstrumentHeader;
