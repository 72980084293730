import { useCallback } from 'react';
import moment from 'moment';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { MarketItem, TradingSession } from '../../gateways/RfpGateway/rfp.types';

const useTimeSessions = () => {
	const { t } = useTranslation();

	const getNextOpenTradingSessions = useCallback((marketItem: MarketItem, day: number) => {
		if (marketItem.tradingSessions) {
			let nextDay = day;
			for (let i = 0; i < 7; i++) {
				nextDay = (nextDay + 1) % 7;
				let tradingSessions = marketItem.tradingSessions.sessions[nextDay];
				if (tradingSessions) {
					for (let j = 0; j < tradingSessions.length; j++) {
						if (tradingSessions[j].isOpen) {
							if (day > nextDay) {
								// If next day is less than current day, it means next day is in the next week
								// So we need to add 7 days to the open and close date
								const newTradingSessions = tradingSessions.map((ts) => {
									const openDate = new Date(ts.openDate);
									openDate.setDate(openDate.getDate() + 7);
									const closeDate = new Date(ts.closeDate);
									closeDate.setDate(closeDate.getDate() + 7);
									return {
										...ts,
										openDate: openDate,
										closeDate: closeDate,
									};
								});
								return newTradingSessions;
							}

							return tradingSessions;
						}
					}
				}
			}
		}
		return undefined;
	}, []);

	const getOpenTime = useCallback((tradingSessions: TradingSession[], time: Date) => {
		for (let i = 0; i < tradingSessions.length; i++) {
			const tradingSession = tradingSessions[i];
			if (tradingSession.isOpen && tradingSession.openDate > time) {
				const duration = intervalToDuration({ start: new Date(), end: tradingSession.openDate });
				const formatted = formatDuration(duration, { format: ['hours', 'minutes'] });

				/*
                If the market opens in more than 2 days - present the exact day when the market will open
                If the market opens in 2 days (24h - 47h 59 m) - present: tomorrow
                If the market opens in a day (24 h) - present: countdown 
                */

				if (duration.days !== undefined) {
					if (duration.days === 0) {
						return t('wtr:TIME_SESSIONS_OPENS_IN', { formatted });
					} else if (duration.days === 1) {
						return t('wtr:TIME_SESSIONS_OPENS_TOMORROW');
					} else {
						const timeFromNow = moment(tradingSession.openDate).fromNow();
						return t('wtr:TIME_SESSIONS_OPENS', { timeFromNow });
					}
				}
			}
		}
		return undefined;
	}, []);

	const findLastTradingSession = useCallback((marketItem: MarketItem, day: number) => {
		if (marketItem.tradingSessions) {
			// Find the last closes time
			const tradingSessions = marketItem.tradingSessions.sessions[day];
			if (tradingSessions.length > 0) {
				const closeDate = tradingSessions[tradingSessions.length - 1].closeDate;
				const nextTradingSession = getNextOpenTradingSessions(marketItem, day);
				if (nextTradingSession && nextTradingSession.length > 0) {
					const nextOpenTime = nextTradingSession[0].openDate;
					if (nextOpenTime !== closeDate) {
						return tradingSessions;
					}
				}
			}
		}
		return undefined;
	}, []);

	const getClosesTime = useCallback((marketItem: MarketItem, time: Date) => {
		if (marketItem.tradingSessions && isWorkingTime(marketItem, time)) {
			const day = time.getDay();
			let tradingSessions = undefined;
			for (let index = day; index < 7; index++) {
				tradingSessions = findLastTradingSession(marketItem, index);
				if (tradingSessions) {
					break;
				}
			}

			if (tradingSessions && tradingSessions.length > 0) {
				for (let i = 0; i < tradingSessions.length; i++) {
					const tradingSession = tradingSessions[i];
					if (tradingSession.isOpen && tradingSession.closeDate > time) {
						const duration = intervalToDuration({ start: new Date(), end: tradingSession.closeDate });
						const format = duration.days && duration.days > 0 ? ['days', 'hours', 'minutes'] : ['hours', 'minutes'];
						const formatted = formatDuration(duration, { format: format });

						/*
						If the market closes in more than 2 days - present the exact day when the market will close
						If the market closes in 2 days (24h - 47h 59 m) - present: tomorrow
						If the market closes in a day (24 h) - present: countdown 
						*/

						if (duration.days !== undefined) {
							if (duration.days === 0) {
								return t('wtr:TIME_SESSIONS_CLOSES_IN', { formatted });
							} else if (duration.days === 1) {
								return t('wtr:TIME_SESSIONS_CLOSES_TOMORROW');
							} else {
								const timeFromNow = moment(tradingSession.closeDate).fromNow();
								return t('wtr:TIME_SESSIONS_CLOSES', { timeFromNow });
							}
						}
					}
				}
			}
		}
		return undefined;
	}, []);

	const getNextOpenDate = useCallback((marketItem: MarketItem, time: Date): string | undefined => {
		if (marketItem.tradingSessions && !isWorkingTime(marketItem, time)) {
			const day = time.getDay();
			const tradingSessions = marketItem.tradingSessions.sessions[day];
			let openTime = getOpenTime(tradingSessions, time);
			if (openTime) {
				return openTime;
			}
			const nextOpenTradingSessions = getNextOpenTradingSessions(marketItem, day);
			if (nextOpenTradingSessions) {
				openTime = getOpenTime(nextOpenTradingSessions, time);
				if (openTime) {
					return openTime;
				}
			}
		}
		return undefined;
	}, []);

	const isWorkingTime = useCallback((marketItem: MarketItem, time: Date) => {
		if (marketItem.tradingSessions) {
			const orgTime = new Date(time);

			const day = time.getDay();
			const tradingSessions = marketItem.tradingSessions.sessions[day];
			for (let i = 0; i < tradingSessions.length; i++) {
				const tradingSession = tradingSessions[i];
				if (tradingSession.isOpen) {
					const currentTime = orgTime.getHours() * 60 + orgTime.getMinutes();
					const openTime = tradingSession.openDate.getHours() * 60 + tradingSession.openDate.getMinutes();
					const closeTime = (
						openTime +
						(tradingSession.closeDate.getTime() - tradingSession.openDate.getTime()) / 1000 / 60
					).toFixed(0);

					if (currentTime >= openTime && currentTime < +closeTime) {
						return true;
					}
				}
			}

			return false;
		}

		return undefined;
	}, []);

	return { getNextOpenDate, isWorkingTime, getClosesTime };
};

export default useTimeSessions;
