import React, { useContext } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../../shared/Modal/Modal';
import Button from '../../../../../../shared/Button/Button';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppContext from '../../../../../../contexts/AppContext';
import { TicketLayout } from '../../../../../../utils/functions/enums';
import tradingViewStore from '../../../../../../store/tradingViewStore';
import DashboardContext from '../../../../../../contexts/DashboardContext';

import styles from './DetachedModal.module.scss';
import orderStore from '../../../../../../store/OrderStore/orderStore';
interface DetachedModalProps {
	title: string | JSX.Element;
	body: string | JSX.Element | undefined;
	hideAction: () => void;
	successTitle: string;
	successAction: any;
	show: boolean;
	dockAction?: any;
	withPadding?: boolean;
	buttonDisabledClass?: string;
	isError?: boolean;
}

const DetachedModal = ({
	title,
	body,
	successTitle,
	successAction,
	hideAction,
	show,
	dockAction,
	withPadding = true,
	buttonDisabledClass,
	isError = false,
}: DetachedModalProps) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const ticketLayout = tradingViewStore.use.ticketLayout();
	const setTicketLayout = tradingViewStore.use.setTicketLayout();
	const current = orderStore((state) => state.current);
	const dashboardContext = useContext(DashboardContext);

	const isArabic = appContext.isArabic;

	const undockTicketLayout = () => {
		setTicketLayout(TicketLayout.Undock);
		dashboardContext.showCloseTicket = false;
		dashboardContext.modalToggle = { closePosition: true, closeOrder: false };
	};

	const dockTicketLayout = () => {
		setTicketLayout(TicketLayout.Dock);
		dashboardContext.showCloseTicket = true;
		dashboardContext.modalToggle = { closePosition: false, closeOrder: false };
	};

	const undock = () => {
		if (ticketLayout === TicketLayout.Undock) {
			dockTicketLayout();
		} else {
			undockTicketLayout();
		}
	};

	const hideClosePosition = () => {
		if (ticketLayout === TicketLayout.Dock) {
			dashboardContext.showCloseTicket = false;
		} else {
			dashboardContext.modalToggle = { closePosition: false, closeOrder: false };
		}
	};

	return (
		<div>
			<Modal dialogClassName={cn(styles.modal, !body ? styles.noBody : '')} centered scrollable show={show}>
				<Modal.Header
					className={cn({
						[styles.modalHeader]: true,
						[styles.noPadding]: !withPadding,
					})}
				>
					<Modal.Title
						className={cn({
							[styles.modalTitle]: true,
							[styles.noPadding]: !withPadding,
						})}
					>
						{title}
					</Modal.Title>
					{dockAction && (
						<div className={styles.dockIconContainer}>
							<div className={styles.iconContainer}>
								<OverlayTrigger
									key="detachOrderTicket"
									delay={{ show: 750, hide: 0 }}
									placement={isArabic ? 'right' : 'left'}
									overlay={
										<Tooltip className="my-tooltip">
											{ticketLayout === TicketLayout.Undock ? t('en:ATTACH') : t('en:DETACH')}
										</Tooltip>
									}
								>
									<div
										className={cn({
											[styles.icon]: true,
											[styles.iconDetached]: ticketLayout === TicketLayout.Undock,
											[styles.iconAtached]: ticketLayout === TicketLayout.Dock,
										})}
									>
										<FontAwesomeIcon
											icon={
												ticketLayout === TicketLayout.Dock
													? ['fal', 'square-arrow-up-right']
													: ['fal', 'square-arrow-down-left']
											}
											onClick={undock}
										/>
									</div>
								</OverlayTrigger>

								{ticketLayout === TicketLayout.Undock && (
									<div className={cn(styles.icon, styles.iconDetached)}>
										<FontAwesomeIcon icon={['fal', 'xmark-large']} onClick={hideClosePosition} />
									</div>
								)}
							</div>
						</div>
					)}
				</Modal.Header>
				{body && <Modal.Body className={styles.modalBody}>{body}</Modal.Body>}
				<Modal.Footer className={styles.modalFooter}>
					<Button variant="secondary" size="fluid" label={t('en:CANCEL')} onClick={hideAction} />
					<Button disabled={isError} size="fluid" label={successTitle} onClick={successAction} />
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default DetachedModal;
