import React, { useState, useEffect, useRef, createRef, Ref, RefObject, useContext } from 'react';

import AppContext from '../../contexts/AppContext';
import useOutsideAlerter from '../../views/components/SearchInput/useOutsideAlerter';

import styles from './InfoModal.module.scss';

type Arrow = 'left' | 'right' | 'top' | 'bottom';

interface InfoModalProps {
	arrow?: Arrow;
	header?: React.ReactNode;
	content?: React.ReactNode;
	footer?: React.ReactNode;
	closeInfoModal: () => void;
	parentRef: RefObject<HTMLDivElement>;
	additionalPositioning?: { x: number; y: number };
}

const InfoModal: React.FC<InfoModalProps> = ({
	arrow,
	header,
	content,
	footer,
	closeInfoModal,
	parentRef,
	additionalPositioning,
}) => {
	const childRef: RefObject<HTMLDivElement> = useRef(null);
	const divRefArrow = useRef<HTMLDivElement>(null);
	const [parentPosition, setParentPosition] = useState({ x: 0, y: 0 });
	const appContext = useContext(AppContext);

	const isArabic = !!appContext.isArabic || !!(localStorage.getItem('language') === 'ar-SA');
	const calculateParentPosition = () => {
		if (parentRef?.current) {
			const { x, y } = parentRef.current.getBoundingClientRect();

			setParentPosition({ x, y });
		}
	};
	useOutsideAlerter(childRef, () => closeInfoModal());

	useEffect(() => {
		calculateParentPosition();
		window.addEventListener('resize', calculateParentPosition);
		return () => {
			window.removeEventListener('resize', calculateParentPosition);
		};
	}, []);

	useEffect(() => {
		if (parentRef.current && childRef.current) {
			const parentCenterY = parentPosition.y + parentRef.current.offsetHeight / 2;
			const childHeight = childRef.current.offsetHeight;
			const childwidth = childRef.current.offsetWidth;

			if (isArabic) {
				childRef.current.style.left = `${parentRef.current.getBoundingClientRect().right - childwidth - 50}px`;
				childRef.current.style.top = `${parentCenterY - childHeight / 2 + 15}px`;
			} else {
				childRef.current.style.left = `${parentRef.current.getBoundingClientRect().right + 15}px`;
				childRef.current.style.top = `${parentCenterY - childHeight / 2}px`;
			}

			if (additionalPositioning) {
				if (additionalPositioning.x !== 0) {
					childRef.current.style.left = `${parseInt(childRef.current.style.left, 10) + additionalPositioning.x}px`;
				}
				if (additionalPositioning.y !== 0) {
					childRef.current.style.top = `${parseInt(childRef.current.style.top, 10) + additionalPositioning.y}px`;
				}
			}
		}
	}, [parentPosition, additionalPositioning, isArabic]);

	return (
		<>
			<div ref={childRef} className={styles.infoModalWrapper}>
				<div className={styles.contentWrapper}>
					<div className={styles.header}>{header}</div>
					{content ? <div className={styles.content}>{content}</div> : null}
				</div>
				<div className={styles.footer}>{footer}</div>
				{arrow && <div ref={divRefArrow} className={styles[arrow]}></div>}
			</div>
		</>
	);
};

export default InfoModal;
